import React, { Component } from 'react';
import {
    Dialog, DialogActions, DialogContent, TextField, MenuItem,
    ListItemText, Checkbox, InputLabel, Select, OutlinedInput,
    withStyles, IconButton
} from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal'
import { BtnForm } from '../../general/BotonesAccion'
import {
    FormControl,
    Grid,
    Container
} from '@material-ui/core';
import { MenuProps, obtenerValores, coloresEmma, obtenerValoresFiltros,consulta } from '../../../global/js/funciones'
import {
    AREAS_INTERES, CODIGO_PRESTADOR, OCUPACIONES, PROGRAMAS
} from '../helper'

import moment from 'moment'
import BuscarValorGenerica from '../../general/BuscarValorGenerica';
import ConfirmarAccion from '../../general/ConfirmarAccion';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const useStyles = () => ({
    colorEmma: {
        color: coloresEmma.secondarycolor,
        transition: 'all 0.3s',
        '&:hover': {
            color: "#fff",
            backgroundColor: coloresEmma.secondarycolor,
            transform: "scale(1.3)",
        },
    },
    colorDelete: {
        color: "#d11a2a",
        transition: "all 0.3s",
        '&:hover': {
            color: "#fff",
            backgroundColor: "#d11a2a",
            transform: "scale(1.3)",
        },
    }
});

class InfoOferta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opc_titulo_practicas: [],
            ocupacion_seleccionada: { id: 0, nombre: '' },
            modal_buscar_ocupacion: false,
            modal_quitar_ocupacion: false,
            cargar_datos: true
        }
    }

    componentDidMount() {
        this.cargarOpciones();
    }

    cargarOpciones = async () => {
        let { data: { ocupacion_relacionada } } = this.props;
        let ocupacion_seleccionada = { id: 0, nombre: '' };
        if (ocupacion_relacionada.valor !== "") {
            ocupacion_seleccionada.id = ocupacion_relacionada.valor;
            ocupacion_seleccionada.nombre = ocupacion_relacionada.nombre;
        }
        const opc_titulo_practicas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': PROGRAMAS }, { 'llave': 'valorc', 'valor': 'pregrado' }, { 'llave': 'estado', 'valor': 1 }]);
        this.setState({
            opc_titulo_practicas,
            ocupacion_seleccionada
        });
    }

    onChangeCombined = (event) => {
        let {onChangeState,obtEmpNit} = this.props
        onChangeState(event); 
        obtEmpNit(event);
    }


    render() {
        let { data, onChangeState, conError, datos, pintarTiposRoles,
            pintarNombresRoles, setRol, tipoVacante, classes } = this.props
        let { opc_titulo_practicas, ocupacion_seleccionada, modal_buscar_ocupacion, modal_quitar_ocupacion, cargar_datos } = this.state;
        const DESCRIPCION_LIMIT = 4000;
        const AREA_LIMIT = 300;
        return (
            <React.Fragment>
                <FormControl className='form-control'>
                    <Container maxWidth="sm">
                        <Grid
                            container
                            spacing={3}
                            direction='column'
                            justify='center'
                        >


                            {/* campos nuevos */}
                            {
                                tipoVacante === 'Vac_Lab' ?
                                    <Grid item xs={12}>
                                        <TextField
                                            value={data.titulo.valor || datos.titulo}
                                            id="titulo"
                                            label="Título de la Vacante"
                                            name="titulo"
                                            type="text"
                                            fullWidth
                                            onChange={onChangeState}
                                            // error={conError(data.titulo.error)}
                                            // helperText={data.titulo.error}
                                        />
                                    </Grid>
                                    : <Grid item xs={12}>
                                        <FormControl style={{ width: '-webkit-fill-available' }} fullWidth={true} >
                                            <InputLabel>Título de la Práctica Laboral</InputLabel>
                                            <Select
                                                value={data.titulo_practica.valor || datos.titulo_practica}
                                                onChange={(e) => onChangeState({
                                                    target: {
                                                        value: e.target.value,
                                                        name: "titulo_practica"
                                                    }
                                                })}>
                                                {
                                                    opc_titulo_practicas.map(({ nombre }, i) => <MenuItem key={i} value={`PL - ${nombre}`}>{`PL - ${nombre}`}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                            }

                            {
                                tipoVacante === 'Vac_Lab' &&
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="descripcion"
                                            label="Descripción de la Vacante"
                                            name="descripcion"
                                            type="text"
                                            fullWidth
                                            value={data.descripcion.valor || datos.descripcion}
                                            onChange={onChangeState}
                                            // error={conError(data.descripcion.error)}
                                            // helperText={data.descripcion.error}
                                        />
                                    </Grid>

                                    <Grid item container xs={12} direction="row" justify="space-between">
                                        <Grid item xs={5}>
                                            <TextField
                                                value={data.anos.valor || datos.anos}
                                                id="anos"
                                                label="Experiencia Relacionada(Años)"
                                                name="anos"
                                                type="number"
                                                fullWidth
                                                onChange={onChangeState}
                                                // error={conError(data.nombre_cargo.error)}
                                                // helperText={data.nombre_cargo.error}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField
                                                value={data.meses.valor || datos.meses}
                                                id="meses"
                                                label="Meses"
                                                name="meses"
                                                type="number"
                                                fullWidth
                                                onChange={onChangeState}
                                                // error={conError(data.nombre_cargo.error)}
                                                // helperText={data.nombre_cargo.error}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            id="n_vacantes"
                                            label="Número de Vacantes"
                                            type="number"
                                            name="n_vacantes"
                                            value={data.n_vacantes.valor || datos.n_vacantes}
                                            onChange={onChangeState}
                                            fullWidth
                                            // error={conError(data.n_vacantes.error)}
                                            // helperText={data.n_vacantes.error}
                                        />
                                    </Grid>
                                </>
                            }

                            <Grid item xs={12}>
                                <TextField
                                    value={data.nombre_cargo.valor || datos.nombre_cargo}
                                    id="nombre_cargo"
                                    label="Cargo"
                                    name="nombre_cargo"
                                    type="text"
                                    fullWidth
                                    onChange={onChangeState}
                                    // error={conError(data.nombre_cargo.error)}
                                    // helperText={data.nombre_cargo.error}
                                />
                            </Grid>

                            {/* <Grid item xs={12}>
                                <FormControl style={{ width: '-webkit-fill-available' }} fullWidth={true} >
                                    <InputLabel>Tipo de Documento del Empleador</InputLabel>
                                    <Select
                                        value={data.tipo_documento.valor}
                                        onChange={(e) => onChangeState({
                                            target: {
                                                value: e.target.value,
                                                name: "tipo_documento"
                                            }
                                        })}>
                                        <MenuItem value={"Nit"}>Nit</MenuItem>
                                        <MenuItem value={"Cédula de Ciudadanía"}>Cédula de Ciudadanía</MenuItem>
                                        <MenuItem value={"Cédula de Extranjería"}>Cédula de Extranjería</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid> */}

                            {/* <Grid item xs={12}>
                                <TextField
                                    id="identificacion"
                                    label="Número de Identificación del Empleador"
                                    type="number"
                                    name="identificacion"
                                    value={data.identificacion.valor || datos.identificacion}
                                    onChange={this.onChangeCombined}
                                    fullWidth
                                    // error={conError(data.n_vacantes.error)}
                                    // helperText={data.n_vacantes.error}
                                />
                            </Grid> */}

                            {/* <Grid item xs={12}>
                                <TextField
                                    value={data.razon_social.valor || datos.razon_social}
                                    id="razon_social"
                                    label="Razón Social"
                                    name="razon_social"
                                    type="text"
                                    fullWidth
                                    onChange={onChangeState}
                                    // error={conError(data.nombre_cargo.error)}
                                    // helperText={data.nombre_cargo.error}
                                />
                            </Grid> */}

                            <Grid container item xs={12} direction="row" justify="space-between">
                                <Grid item xs = { 5 }>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            id = 'fecha_publicacion'
                                            disabled
                                            label = "Fecha de Publicacion"
                                            name = 'fecha_publicacion'
                                            format = 'dd/MM/yyyy'
                                            value = {data.fecha_publicacion.valor || datos.fecha_publicacion}
                                            onChange = { (e) => onChangeState({
                                                target: {
                                                    value: e,
                                                    name: "fecha_publicacion"
                                                }
                                            }) }
                                            fullWidth
                                            KeyboardButtonProps={{ 'aria-label': 'cambiar fecha' }}
                                            minDate = { data.fecha_publicacion.valor }
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs = { 5 }>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            id = 'fecha_vencimiento'
                                            required
                                            label = "Fecha de Vencimiento"
                                            name = 'fecha_vencimiento'
                                            format = 'dd/MM/yyyy'
                                            value = {data.fecha_vencimiento.valor}
                                            onChange = { (e) => onChangeState({
                                                target: {
                                                    value: e,
                                                    name: "fecha_vencimiento"
                                                }
                                            }) }
                                            fullWidth
                                            KeyboardButtonProps={{ 'aria-label': 'cambiar fecha' }}
                                            minDate = { data.fecha_publicacion.valor }
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>

                            {
                                tipoVacante === 'Vac_Lab' &&
                                <>
                                    <Grid item xs={12}>
                                        <FormControl style={{ width: '-webkit-fill-available' }} fullWidth={true} >
                                            <InputLabel>Nivel de Estudios Requeridos</InputLabel>
                                            <Select
                                                value={data.nivel_estudio.valor}
                                                onChange={(e) => onChangeState({
                                                    target: {
                                                        value: e.target.value,
                                                        name: "nivel_estudio"
                                                    }
                                                })}>
                                                <MenuItem value={"Primaria"}>Primaria</MenuItem>
                                                <MenuItem value={"Básica secundaria"}>Básica secundaria</MenuItem>
                                                <MenuItem value={"Media"}>Media</MenuItem>
                                                <MenuItem value={"Técnico"}>Técnico</MenuItem>
                                                <MenuItem value={"Tecnólogo"}>Tecnólogo</MenuItem>
                                                <MenuItem value={"Universitario"}>Universitario</MenuItem>
                                                <MenuItem value={"Especialización"}>Especialización</MenuItem>
                                                <MenuItem value={"Maestría"}>Maestría</MenuItem>
                                                <MenuItem value={"Doctorado"}>Doctorado</MenuItem>
                                                <MenuItem value={"No requiere"}>No requiere</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControl style={{ width: '-webkit-fill-available' }} fullWidth={true} >
                                            <InputLabel>Tipo de Contrato</InputLabel>
                                            <Select
                                                value={data.tipo_de_contrato.valor || datos.tipo_de_contrato}
                                                onChange={(e) => onChangeState({
                                                    target: {
                                                        value: e.target.value,
                                                        name: "tipo_de_contrato"
                                                    }
                                                })}>
                                                <MenuItem value={"Contrato indefinido"}>Contrato indefinido</MenuItem>
                                                <MenuItem value={"Contrato fijo"}>Contrato fijo</MenuItem>
                                                <MenuItem value={"Contrato prestación de servicios"}>Contrato prestación de servicios</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControl style={{ width: '-webkit-fill-available' }} fullWidth={true} >
                                            <InputLabel>Solicitud de Vacante</InputLabel>
                                            <Select
                                                value={data.solicitud_de_vacante.valor || datos.solicitud_de_vacante}
                                                onChange={(e) => onChangeState({
                                                    target: {
                                                        value: e.target.value,
                                                        name: "solicitud_de_vacante"
                                                    }
                                                })}>
                                                <MenuItem value={"Vacante publica"}>Vacante publica</MenuItem>
                                                <MenuItem value={"Vacante Confidencial"}>Vacante Confidencial</MenuItem>
                                                <MenuItem value={"Vacante exceptuada"}>Vacante exceptuada</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            value={data.profesion.valor || datos.profesion}
                                            id="profesion"
                                            label="Profesión"
                                            name="profesion"
                                            type="text"
                                            fullWidth
                                            onChange={onChangeState}
                                            // error={conError(data.nombre_cargo.error)}
                                            // helperText={data.nombre_cargo.error}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            id="salario"
                                            label="Salario/Ingreso"
                                            type="number"
                                            fullWidth
                                            name="salario"
                                            value={data.salario.valor || datos.salario}
                                            onChange={onChangeState}
                                            // error={data.salario.error}
                                            // helperText={data.salario.error}
                                        />
                                    </Grid>

                                    <FormControl variant="outlined" className='form-control' style={{ marginTop: "7px" }}>
                                        <InputLabel htmlFor="select-multiple-checkbox">Areas de Interes</InputLabel>
                                        <Select
                                            multiple
                                            value={data.areas}
                                            input={<OutlinedInput labelWidth={70} name="select-multiple-checkbox" id="select-multiple-checkbox" />}
                                            onChange={setRol}
                                            renderValue={selected => {
                                                return pintarNombresRoles(selected)
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            {pintarTiposRoles()}
                                        </Select>
                                    </FormControl>

                                    <Grid item xs={12}>
                                        <TextField
                                            id="salario"
                                            label="Departamento"
                                            type="text"
                                            fullWidth
                                            name="departamento"
                                            value={data.departamento.valor || datos.departamento}
                                            onChange={onChangeState}
                                            // error={data.salario.error}
                                            // helperText={data.salario.error}
                                        />
                                    </Grid>
                                </>

                            }

                            <Grid container item xs={12} direction="row" justify="space-between">
                                <Grid item xs={5}>
                                    <TextField
                                        value={data.municipio.valor || datos.municipio}
                                        id="municipio"
                                        label={tipoVacante === 'Vac_Lab' ? 'Municipio' : 'Código de Municipio'}
                                        name="municipio"
                                        type="text"
                                        fullWidth
                                        onChange={onChangeState}
                                        // error={conError(data.municipio.error)}
                                        // helperText={data.municipio.error}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        value={data.sector_economico.valor || datos.sector_economico}
                                        id="sector_economico"
                                        label="Sector Económico"
                                        name="sector_economico"
                                        type="text"
                                        fullWidth
                                        onChange={onChangeState}
                                        // error={conError(data.nombre_cargo.error)}
                                        // helperText={data.nombre_cargo.error}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} direction="row" justify="space-between">
                                <Grid item xs={5}>
                                    <TextField
                                        value={data.telefono.valor || datos.telefono}
                                        id="telefono"
                                        label="Telefono Empresa"
                                        name="telefono"
                                        type="number"
                                        fullWidth
                                        onChange={onChangeState}
                                        // error={conError(data.titulo.error)}
                                        // helperText={data.titulo.error}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        value={data.correo.valor || datos.correo}
                                        id="correo"
                                        label="Correo Empresa"
                                        name="correo"
                                        type="email"
                                        fullWidth
                                        onChange={onChangeState}
                                        /* error={conError(data.titulo.error)} */
                                        // helperText={data.titulo.error}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} direction="row" justify="space-between">
                                <Grid item xs={12}>
                                        <TextField
                                            value={data.nombre_responsable.valor || datos.nombre_responsable}
                                            id="nombre_responsable"
                                            label="Nombre Completo Responsable"
                                            name="nombre_responsable"
                                            type="text"
                                            fullWidth
                                            onChange={onChangeState}
                                            /* error={conError(data.titulo.error)} */
                                            // helperText={data.titulo.error}
                                        />
                                </Grid>
                            </Grid>
                            {
                                tipoVacante === 'Vac_Pra' &&
                                <>
                                    <Grid item xs={12}>
                                        <FormControl style={{ width: '-webkit-fill-available' }} fullWidth={true} >
                                            <InputLabel>Tipo de contrato</InputLabel>
                                            <Select
                                                value={data.tipo_de_contrato_practica.valor || datos.tipo_de_contrato_practica}
                                                onChange={(e) => onChangeState({
                                                    target: {
                                                        value: e.target.value,
                                                        name: "tipo_de_contrato_practica"
                                                    }
                                                })}>
                                                <MenuItem value={"Contrato aprendizaje"}>Contrato aprendizaje</MenuItem>
                                                <MenuItem value={"Contrato por Pasantía"}>Contrato por Pasantía</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            value={data.descripcion_actividades.valor || datos.descripcion_actividades}
                                            id="descripcion_actividades"
                                            label="Descripción de las Actividades Formativas"
                                            name="descripcion_actividades"
                                            type="text"
                                            multiline
                                            rows={4}
                                            fullWidth
                                            onChange={onChangeState}
                                            // error={conError(data.descripcion_actividades.error)}
                                            // helperText={conError(data.descripcion_actividades.error) ? data.descripcion_actividades.error : `${data.descripcion_actividades.valor.length}/${DESCRIPCION_LIMIT}`}
                                            inputProps={{ maxLength: DESCRIPCION_LIMIT }}
                                        />
                                    </Grid>

                                    <Grid container item xs={12} direction="row" justify="space-between">
                                        <Grid item xs={5}>
                                            <FormControl style={{ width: '-webkit-fill-available' }} fullWidth={true} >
                                                <InputLabel>Tiempo de Experiencia Relacionada</InputLabel>
                                                <Select
                                                    value={data.tiempo_experiencia.valor || datos.tiempo_experiencia}
                                                    defaultValue={0}
                                                    onChange={(e) => onChangeState({
                                                        target: {
                                                            value: e.target.value,
                                                            name: "tiempo_experiencia"
                                                        }
                                                    })}
                                                >
                                                    <MenuItem value={1}>1 Mes</MenuItem>
                                                    <MenuItem value={2}>2 Meses</MenuItem>
                                                    <MenuItem value={3}>3 Meses</MenuItem>
                                                    <MenuItem value={4}>4 Meses</MenuItem>
                                                    <MenuItem value={5}>5 Meses</MenuItem>
                                                    <MenuItem value={6}>6 Meses</MenuItem>
                                                    <MenuItem value={7}>7 Meses</MenuItem>
                                                    <MenuItem value={8}>8 Meses</MenuItem>
                                                    <MenuItem value={9}>9 Meses</MenuItem>
                                                    <MenuItem value={10}>10 Meses</MenuItem>
                                                    <MenuItem value={11}>11 Meses</MenuItem>
                                                    <MenuItem value={12}>12 Meses</MenuItem>
                                                    <MenuItem value={0}>0 Meses</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField
                                                value={data.modalidad_formacion.valor || datos.modalidad_formacion}
                                                id="modalidad_formacion"
                                                defaultValue={6}
                                                disabled
                                                label="Modalidad de Formación Requerida"
                                                name="modalidad_formacion"
                                                type="number"
                                                fullWidth
                                                onChange={onChangeState}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            value={data.area_programa_req.valor || datos.area_programa_req}
                                            id="area_programa_req"
                                            label="Área de Conocimiento o Programa Académico Requerido"
                                            name="area_programa_req"
                                            type="text"
                                            multiline
                                            rows={4}
                                            fullWidth
                                            onChange={onChangeState}
                                            // error={conError(data.area_programa_req.error)}
                                            // helperText={conError(data.area_programa_req.error) ? data.area_programa_req.error : `${data.area_programa_req.valor.length}/${AREA_LIMIT}`}
                                            inputProps={{ maxLength: AREA_LIMIT }}
                                        />
                                    </Grid>

                                    <Grid container item xs={12} direction="row" justify="space-between">
                                        <Grid item xs={5}>
                                            <TextField
                                                value={data.valor_auxilio.valor || datos.valor_auxilio}
                                                id="valor_auxilio"
                                                defaultValue={0}
                                                label="Valor del Auxilio de Práctica"
                                                name="valor_auxilio"
                                                type="number"
                                                fullWidth
                                                onChange={onChangeState}
                                                // error={conError(data.valor_auxilio.error)}
                                                // helperText={data.valor_auxilio.error}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    value={data.numero_plazas.valor || datos.numero_plazas}
                                                    id="numero_plazas"
                                                    label="Número de Plazas de Práctica Laboral"
                                                    name="numero_plazas"
                                                    type="number"
                                                    fullWidth
                                                    onChange={onChangeState}
                                                    // error={conError(data.numero_plazas.error)}
                                                    // helperText={data.numero_plazas.error}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} direction="row" justify="space-between">
                                        <Grid item xs={8}>
                                            <TextField
                                                value={ocupacion_seleccionada.nombre}
                                                id="ocupacion_relacionada"
                                                label="Ocupación Relacionada con las Actividades Formativas"
                                                name="ocupacion_relacionada"
                                                type="text"
                                                fullWidth
                                                // error={conError(data.ocupacion_relacionada.error)}
                                                // helperText={data.ocupacion_relacionada.error}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <BuscarValorGenerica
                                                generica={OCUPACIONES}
                                                nombre_generica='Ocupaciones'
                                                seleccionar={ocupacion => {
                                                    onChangeState({ target: { name: 'ocupacion_relacionada', value: ocupacion.id } });
                                                    this.setState({ ocupacion_seleccionada: { id: ocupacion.id, nombre: ocupacion.nombre } });
                                                }}
                                                quitar={() => {
                                                    onChangeState({ target: { name: 'ocupacion_relacionada', value: '' } });
                                                    this.setState({ ocupacion_seleccionada: { id: 0, nombre: '' } });
                                                }}
                                                seleccionadas={[ocupacion_seleccionada]}
                                                modal={modal_buscar_ocupacion}
                                                mostrarModal={modal_buscar_ocupacion => this.setState({ modal_buscar_ocupacion })}
                                                sw_cargar={cargar_datos}
                                                cambiar_sw={valor => this.setState({ cargar_datos: valor })}
                                            />
                                            <IconButton aria-label="add" className={classes.colorEmma} onClick={() => this.setState({ modal_buscar_ocupacion: true })}>
                                                <AddIcon />
                                            </IconButton>
                                            <IconButton aria-label="clear" className={classes.colorDelete} onClick={() => ocupacion_seleccionada.id !== 0 && this.setState({ modal_quitar_ocupacion: true })}>
                                                <ClearIcon />
                                            </IconButton>
                                            <ConfirmarAccion
                                                mensaje1={ocupacion_seleccionada ? `Se quitará ${ocupacion_seleccionada.nombre}, ` : ''}
                                                ejecutarAccion={() => {
                                                    onChangeState({ target: { name: 'ocupacion_relacionada', value: '' } });
                                                    this.setState({ ocupacion_seleccionada: { id: 0, nombre: '' }, modal_quitar_ocupacion: false })
                                                }}
                                                mostrarModalConfAccion={modal_quitar_ocupacion => this.setState({ modal_quitar_ocupacion })}
                                                modalConAccion={modal_quitar_ocupacion}
                                                dato={ocupacion_seleccionada ? ocupacion_seleccionada : { id: 0 }}
                                                titulo={'¿ Quitar Ocupación ?'}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            value={data.solicitud_excepcion.valor || datos.solicitud_excepcion}
                                            id="solicitud_excepcion"
                                            defaultValue={'N'}
                                            disabled
                                            label="Solicitud de Excepción de Publicación por Parte de la Entidad Pública o Privada"
                                            name="solicitud_excepcion"
                                            type="text"
                                            fullWidth
                                            onChange={onChangeState}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControl style={{ width: '-webkit-fill-available' }} fullWidth={true} >
                                            <InputLabel>Discapacidad</InputLabel>
                                            <Select
                                                value={data.discapacidad.valor || datos.discapacidad}
                                                onChange={(e) => onChangeState({
                                                    target: {
                                                        value: e.target.value,
                                                        name: "discapacidad"
                                                    }
                                                })}>
                                                <MenuItem value={"1"}>Sí</MenuItem>
                                                <MenuItem value={"0"}>No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid container item xs={12} direction="row" justify="space-between">
                                        <Grid item xs={12}>
                                            <TextField
                                                value={data.detalle_practica.valor || datos.detalle_practica}
                                                id="detalle_practica"
                                                label="URL al Detalle de la Práctica Laboral"
                                                name="detalle_practica"
                                                type="text"
                                                fullWidth
                                                onChange={onChangeState}
                                                // error={conError(data.detalle_practica.error)}
                                                // helperText={data.detalle_practica.error}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Container>
                </FormControl>
            </React.Fragment>
        )
    }
}

class CrearOferta extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cargando: true,
            titulo: { valor: '' },
            anos: { valor: '' },
            meses: { valor: '' },
            tipo_documento: { valor: '' },
            identificacion: { valor: '' },
            razon_social: { valor: '' },
            fecha_publicacion: { valor: moment().format("YYYY-MM-DD") },
            fecha_vencimiento: { valor: null },
            nivel_estudio: { valor: '' },
            tipo_de_contrato: { valor: '' },
            solicitud_de_vacante: { valor: '' },
            profesion: { valor: '' },
            municipio: { valor: '' },
            sector_economico: { valor: '' },
            nombre_cargo: { valor: '' },
            n_vacantes: { valor: '' },
            descripcion: { valor: '' },
            areas: [],
            salario: { valor: '' },
            departamento: { valor: '' },
            _areas: [],

            // tipo_cargo: { valor: '' },
            // area: { valor: '' },
            // tipo_contrato: { valor: '' },
            // duracion_contrato: { valor: '' },
            // jornada: { valor: '' },

            codigo_prestador: { valor: CODIGO_PRESTADOR },
            titulo_practica: { valor: '' },
            descripcion_actividades: { valor: '' },
            tiempo_experiencia: { valor: 0 },
            tipo_de_contrato_practica:{valor: 0},
            modalidad_formacion: { valor: 6 },
            area_programa_req: { valor: '' },
            valor_auxilio: { valor: 0 },
            numero_plazas: { valor: 0 },
            ocupacion_relacionada: { valor: '', nombre: '' },
            solicitud_excepcion: { valor: 'N' },
            tipo_contrato: { valor: 6 },
            teletrabajo: { valor: 0 },
            discapacidad: { valor: 0 },
            detalle_practica: { valor: '' },
            telefono: { valor: '' },
            correo: { valor: '' },
            nombre_responsable: { valor: '' },
            empresaNit:[]
        }
    }

    async componentDidUpdate(preProps) {
        const { actualizar, data } = this.props;
        const { titulo: { valor }, descripcion: { valor: valor2 },empresaNit} = this.state;
        console.log(empresaNit)
        if (actualizar && data && (valor != data.titulo || valor2 != data.descripcion)) {
            this.setState({
                titulo: { valor: data.titulo },
                anos: { valor: data.anos },
                meses: { valor: data.meses },
                tipo_documento: { valor: data.tipo_documento },
                identificacion: { valor: data.identificacion },
                razon_social: { valor: data.razon_social },
                fecha_publicacion: { valor: moment(data.fecha_publicacion, 'YYYY-MM-DD') },
                fecha_vencimiento: { valor: moment(data.fecha_vencimiento, 'YYYY-MM-DD') },
                nivel_estudio: { valor: data.nivel_estudio },
                tipo_de_contrato: { valor: data.tipo_de_contrato },
                solicitud_de_vacante: { valor: data.solicitud_de_vacante},
                profesion: { valor: data.profesion },
                municipio: { valor: data.municipio },
                sector_economico: { valor: data.sector_economico },
                nombre_cargo: { valor: data.nombre_cargo },
                n_vacantes: { valor: data.n_vacantes },
                descripcion: { valor: data.descripcion },
                areas: data.area_interes ? data.area_interes.map(({ area_interes: { id } }) => id) : [],
                salario: { valor: data.salario },
                departamento: { valor: data.departamento },

                codigo_prestador: { valor: data.codigo_prestador },
                titulo_practica: { valor: data.titulo_practica },
                descripcion_actividades: { valor: data.descripcion_actividades },
                tiempo_experiencia: { valor: data.tiempo_experiencia },
                tipo_de_contrato_practica:{valor: data.tipo_de_contrato_practica},
                modalidad_formacion: { valor: data.modalidad_formacion },
                area_programa_req: { valor: data.area_programa_req },
                valor_auxilio: { valor: data.valor_auxilio },
                numero_plazas: { valor: data.numero_plazas },
                // ocupacion_relacionada: { valor: data.ocupacion_relacionada && data.ocupacion_relacionada.id },
                solicitud_excepcion: { valor: data.solicitud_excepcion },
                tipo_contrato: { valor: data.tipo_contrato },
                teletrabajo: { valor: data.teletrabajo },
                discapacidad: { valor: data.discapacidad },
                detalle_practica: { valor: data.detalle_practica },
                telefono:{ valor: data.telefono },
                correo:{ valor: data.correo },
                nombre_responsable:{ valor: data.nombre_responsable },
            })
            if (data.ocupacion_relacionada) this.setState({ ocupacion_relacionada: { valor: data.ocupacion_relacionada.id, nombre: data.ocupacion_relacionada.nombre } });
        }
        if (!actualizar && actualizar != preProps.actualizar) {
            this.cargarAreas()
            this.setState({
                cargando: true,
                titulo: { valor: '' },
                anos: { valor: '' },
                meses: { valor: '' },
                tipo_documento: { valor: '' },
                identificacion: { valor: '' },
                razon_social: { valor: '' },
                fecha_publicacion: { valor: moment().format("YYYY-MM-DD") },
                fecha_vencimiento: { valor: null },
                nivel_estudio: { valor: '' },
                tipo_de_contrato: { valor: '' },
                solicitud_de_vacante: { valor: '' },
                profesion: { valor: '' },
                municipio: { valor: '' },
                sector_economico: { valor: '' },
                nombre_cargo: { valor: '' },
                n_vacantes: { valor: '' },
                descripcion: { valor: '' },
                areas: [],
                salario: { valor: '' },
                departamento: { valor: '' },
                _areas: [],

                codigo_prestador: { valor: CODIGO_PRESTADOR },
                titulo_practica: { valor: '' },
                descripcion_actividades: { valor: '' },
                tiempo_experiencia: { valor: 0 },
                tipo_de_contrato_practica:{valor: 0 },
                modalidad_formacion: { valor: 6 },
                area_programa_req: { valor: '' },
                valor_auxilio: { valor: 0 },
                numero_plazas: { valor: 0 },
                ocupacion_relacionada: { valor: '', nombre: '' },
                solicitud_excepcion: { valor: 'N' },
                tipo_contrato: { valor: 6 },
                teletrabajo: { valor: 0 },
                discapacidad: { valor: 0 },
                detalle_practica: { valor: '' },
                telefono:{ valor: '' },
                correo:{ valor: '' },
                nombre_responsable:{ valor: '' },
            })
        }
    
    }

    componentDidMount() {
        this.cargarAreas()
    }

    
    async obtenerEmpresaNit(nit_parametro = 0) {
        return new Promise(resolve => {
            consulta(`api/v1.0/portal/${nit_parametro}/empresa_listar_nit`, null, null, (error, estado, resp) => {
                resolve(estado === 200 && !error ? resp : null);
            })
        })
    }

    cargarAreas = async () => {
        const _areas = await obtenerValores(AREAS_INTERES);
        // const codigo_prestador = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Cod_Pres' }]);
        this.setState({
            _areas,
            // codigo_prestador: { valor: parseInt(codigo_prestador[0].nombre) }
        })
    }

    conError = (valor) => {
        return valor ? true : false;
    }

    onChangeState = ({ target }) => {
        let { name, value } = target
        this.setState({
            [name]: { valor: value }
        }
        )
    }

    obtEmpNit = async ({ target }) => {
        let { name, value } = target;
    
        this.setState({ cargando: true });
    
        let data = await this.obtenerEmpresaNit(value);
    
        if (data) {
            this.setState({ 
                razon_social: { valor: data[0]?.razon_social || '' },
            });
        }
    
        this.setState({ cargando: false }); 
    }
    



    onSubmit = e => {
        let { tipoVacante, actualizarMensaje } = this.props;
        let {
            titulo, anos, meses, tipo_documento, identificacion, razon_social,
            fecha_publicacion, fecha_vencimiento, nivel_estudio, tipo_de_contrato,solicitud_de_vacante, profesion,
            municipio, sector_economico, nombre_cargo, n_vacantes, descripcion,
            areas, salario, detalle_practica, discapacidad,
            titulo_practica, descripcion_actividades, tiempo_experiencia, area_programa_req, ocupacion_relacionada,
            valor_auxilio, numero_plazas, departamento,telefono,correo,nombre_responsable
        } = this.state
        let msg = ''
        
        if(tipoVacante.codigo === 'Vac_Lab'){
            if (!titulo.valor) msg = 'El campo Titulo de Vacante es obligatorio.'
            else if (!descripcion.valor) msg = 'El campo Descripcion Vacante es obligatorio.'
            else if (!anos.valor) msg = 'El campo Experiencia en Años es obligatorio.'
            else if (!meses.valor) msg = 'El campo Meses es obligatorio.'
            else if (!(n_vacantes.valor)) msg = 'El campo Numero de Vacantes es obligatorio.'
            else if (!Number(n_vacantes.valor)) msg = 'El campo Numero de Vacantes debe ser un numero.'
            else if (!nombre_cargo.valor) msg = 'El campo Cargo es obligatorio.'
            /* else if (!tipo_documento.valor) msg = 'El campo Tipo Documento del Empleador es obligatorio.'
            else if (!identificacion.valor) msg = 'El campo Identificacion Empleador es obligatorio.'
            else if (!razon_social.valor) msg = 'El campo Razon Social es obligatorio.' */
            else if (!fecha_publicacion.valor) msg = 'El campo Fecha de Publicación es obligatorio.'
            else if (!fecha_vencimiento.valor) msg = 'El campo Fecha de Vencimiento es obligatorio.'
            else if (!nivel_estudio.valor) msg = 'El campo Nivel de estudios es obligatorio.'
            else if (!tipo_de_contrato.valor) msg = 'El campo Tipo de Contrato es obligatorio.'
            else if (!solicitud_de_vacante.valor) msg = 'El campo Solicitud de Vacante Economico es obligatorio.'
            else if (!profesion.valor) msg = 'El campo Profesión es obligatorio.'
            else if (!salario.valor) msg = 'El campo Salario/Ingreso es obligatorio.'
            else if (!Number(salario.valor)) msg = 'El campo Salario/Ingreso debe ser un numero.'
            else if (!(areas.length)) msg = 'El campo Areas de Interes es obligatorio.'
            else if (!departamento.valor) msg = 'El campo Departamento es obligatorio'
            else if (!municipio.valor) msg = 'El campo Municipio es obligatorio.'
            else if (!sector_economico.valor) msg = 'El campo Sector Economico es obligatorio.'
        }else if(tipoVacante.codigo === 'Vac_Pra'){
            if (!titulo_practica.valor) msg = 'El campo Titulo de Practica Laboral es obligatorio.'
            else if (!nombre_cargo.valor) msg = 'El campo Cargo es obligatorio.'
            /* else if (!tipo_documento.valor) msg = 'El campo Tipo Documento del Empleador es obligatorio.'
            else if (!identificacion.valor) msg = 'El campo Identificacion Empleador es obligatorio.'
            else if (!razon_social.valor) msg = 'El campo Razon Social es obligatorio.' */
            else if (!fecha_publicacion.valor) msg = 'El campo Fecha de Publicación es obligatorio.'
            else if (!fecha_vencimiento.valor) msg = 'El campo Fecha de Vencimiento es obligatorio.'
            else if (!municipio.valor) msg = 'El campo Codigo Municipio es obligatorio.'
            else if (!sector_economico.valor) msg = 'El campo Sector Economico es obligatorio.'
            else if (!descripcion_actividades.valor) msg = 'El campo Descripcion de Actividades es obligatorio.'
            else if (!tiempo_experiencia.valor) msg = 'El campo Tiempo de Experiencia es obligatorio.'
            else if (!area_programa_req.valor) msg = 'El campo Area de Conocimiento es obligatorio.'
            else if (!valor_auxilio.valor) msg = 'El campo Valor Auxilio es obligatorio.'
            else if (!numero_plazas.valor) msg = 'El campo Numero de Plazas es obligatorio.'
            else if (!ocupacion_relacionada.valor) msg = 'El campo Ocupacion es obligatorio.'
            else if (!discapacidad.valor) msg = 'El campo Discapacidad es obligatorio.'
            else if (!detalle_practica.valor) msg = 'El campo Url detalle de practica es obligatorio.'
        }
        if (!telefono.valor) msg = 'El campo Telefono es obligatorio.'
        else if (!correo.valor) msg = 'El campo Correo es obligatorio.'
        else if (!nombre_responsable.valor) msg = 'El campo Nombre Responsable es obligatorio.'

        if(msg){
            actualizarMensaje({ titulo: msg, tipo: 'info', mostrar: true, tiempo: 3000 });
            return false
        }else{
            this.enviarDatos()
        }
    }

    enviarDatos = () => {
        const { actualizar, data: datos, tipoVacante } = this.props;
        let { titulo, anos, meses, tipo_documento, identificacion,
            razon_social, fecha_publicacion, fecha_vencimiento, nivel_estudio,tipo_de_contrato,solicitud_de_vacante, profesion,
            municipio, sector_economico, nombre_cargo, n_vacantes, descripcion,
            areas, salario, departamento,
            // _areas, 
            codigo_prestador, detalle_practica, titulo_practica, descripcion_actividades, tiempo_experiencia,tipo_de_contrato_practica,
            modalidad_formacion, area_programa_req, valor_auxilio, numero_plazas, ocupacion_relacionada,
            solicitud_excepcion, tipo_contrato, teletrabajo, discapacidad, telefono, correo, nombre_responsable } = this.state
        let data = {
            tipo_oferta: tipoVacante.id,
            titulo: titulo.valor,
            anos: anos.valor,
            meses: meses.valor,
            /* tipo_documento: tipo_documento.valor,
            identificacion: identificacion.valor,
            razon_social: razon_social.valor, */
            fecha_publicacion: fecha_publicacion.valor,
            fecha_vencimiento: fecha_vencimiento.valor && moment(fecha_vencimiento.valor).format('YYYY-MM-DD'),
            nivel_estudio: nivel_estudio.valor,
            tipo_de_contrato: tipo_de_contrato.valor,
            solicitud_de_vacante: solicitud_de_vacante.valor,
            profesion: profesion.valor,
            municipio: municipio.valor,
            sector_economico: sector_economico.valor,
            nombre_cargo: nombre_cargo.valor,
            n_vacantes: n_vacantes.valor,
            descripcion: descripcion.valor,
            areas,
            salario: salario.valor,
            departamento: departamento.valor,
            // _areas
            codigo_prestador: codigo_prestador.valor,
            titulo_practica: titulo_practica.valor,
            descripcion_actividades: descripcion_actividades.valor,
            tiempo_experiencia: tiempo_experiencia.valor,
            tipo_de_contrato_practica:tipo_de_contrato_practica.valor,
            modalidad_formacion: modalidad_formacion.valor,
            area_programa_req: area_programa_req.valor,
            valor_auxilio: valor_auxilio.valor,
            numero_plazas: numero_plazas.valor,
            ocupacion_relacionada: ocupacion_relacionada.valor,
            solicitud_excepcion: solicitud_excepcion.valor,
            tipo_contrato: tipo_contrato.valor,
            teletrabajo: teletrabajo.valor,
            discapacidad: discapacidad.valor,
            detalle_practica: detalle_practica.valor,
            telefono: telefono.valor,
            correo: correo.valor,
            nombre_responsable : nombre_responsable.valor
        }
        if (actualizar) {
            data = {
                ...datos,
                ...data,
                estado_solicitud: datos.estado_solicitud.id
            }
            this.props.actualizarVacante(data);
        } else {
            this.props.crearVacante(data)

        }
    }

    setRol = ({ target }) => {
        this.setState({
            areas: target.value
        })
    }

    pintarNombresRoles = (selected) => {
        let resp = [];
        let { _areas } = this.state;
        selected.map((element) => {
            _areas.map((e) => {
                if (element === e.id) return resp.push(e.nombre)
                return null;
            })
            return null;
        });
        return resp.join(", ")
    }

    pintarTiposRoles = () => {
        let { areas, _areas } = this.state;
        if (Array.isArray(_areas)) {
            return _areas.map(({ nombre, id }) => (
                <MenuItem key={id} value={id}>
                    <Checkbox checked={areas.indexOf(id) > -1} />
                    <ListItemText primary={nombre} />
                </MenuItem>
            ))
        }
        return [];
    }

    render() {
        let { modalCrearOferta, mostrarModalCrearOferta, tipoVacante, classes } = this.props
        return (
            <div>
                <Dialog open={modalCrearOferta} onClose={() => mostrarModalCrearOferta(false)} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
                    <AppBarModal titulo={"Crear Oferta"} mostrarModal={() => mostrarModalCrearOferta(false)} titulo_accion="" />
                    <DialogContent style={{ padding: '10px 0 0 0', overflowX: 'hidden' }} className="scroll">
                        <div>
                            <InfoOferta
                                datos={{}}
                                data={this.state}
                                onChangeState={this.onChangeState}
                                conError={this.conError}
                                pintarNombresRoles={this.pintarNombresRoles}
                                pintarTiposRoles={this.pintarTiposRoles}
                                setRol={this.setRol}
                                tipoVacante={tipoVacante ? tipoVacante.codigo : ''}
                                classes={classes}
                                obtEmpNit={this.obtEmpNit}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <BtnForm texto="CERRAR" callback={() => mostrarModalCrearOferta(false)} />
                        <BtnForm texto="GUARDAR" callback={(e) => this.onSubmit(e)} />
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(useStyles)(CrearOferta);