//Importaciones necesaria para poder utilizar react
import React, { useState }  from 'react'
import ReactDOM from 'react-dom'; 
import * as Funciones from '../../../global/js/funciones';
//Importaciones necesarias para que el modal del formulario de creacion de semilleros se pueda abrir, este es para que se habra el modal.
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button'
//Importe de los text faild para los formularios de los campos.
import TextField from '@material-ui/core/TextField';
//Importe de los grid para los textfield de los formularios entre otros aspecto.
import Grid from '@material-ui/core/Grid';
import InputBuscar from '../../general/InputBuscar';
import { Fragment } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { render } from 'react-dom';
import { Link, Typography, Card, CardHeader, CardContent, Avatar, Grow, Slide, createMuiTheme, TableRow, TableCell, TableBody, Table } from '@material-ui/core'
import AppBarModal from '../../general/AppBarModal'
import BuscarPersona from '../../general/BuscarPersona';
import SemillerosAddRol from './SemillerosAddRol'
import { DetalleSolicitud, DetalleSolicitud2Parte, DetalleSolicitud3Parte, DetalleEventos, DetallesProyectos, DetallesProduBiblio, DetallesTecYTecno, DetalleAutores, DetalleAutoresTecTecno } from './SemilleroTables';
import { SemilleroMenuProfesor } from './MenuSemillero'
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';
import { BtnForm, BtnDetalle, BtnCancelar } from '../../general/BotonesAccion';
import ListarDatos from '../../general/ListarDatos';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch } from 'react-redux';
import { actualizarMensaje } from '../../../redux/actions/actGlobal';
import SemilleroMessage from './SemilleroMessage'
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import * as Helper from './Helper';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import revisar from '../../../global/imagenes/revisar.png';
import bookInvestigacion from '../../../global/imagenes/bookInvestigacion.png';
import projectInvestigacion from '../../../global/imagenes/projectInvestigacion.png';
import clipboardInvestigacion from '../../../global/imagenes/clipboardInvestigacion.png';
import eventInvestigacion from '../../../global/imagenes/eventInvestigacion.png';
import articleInvestigacion from '../../../global/imagenes/articleInvestigacion.png';
import chapterInvestigacion from '../../../global/imagenes/chapterInvestigacion.png';
import patentInvestigacion from '../../../global/imagenes/patentInvestigacion.png';
import { Paper, Tooltip } from '@material-ui/core'
import AccionesRapidas from '../../general/AccionesRapidas'
import LinearProgress from '@material-ui/core/LinearProgress';
import assigment from '../../../global/imagenes/assigment.png';
import moment from 'moment'
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import PeopleIcon from '@material-ui/icons/People';
import SemillerosGestionEdit from './SemillerosGestionEdit'
import SemillerosGestionEditTecTecno from './SemillerosGestionEditTecTecno'
import GetAppIcon from '@material-ui/icons/GetApp';
import ReactExport from "react-export-excel";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, DatePicker } from "@material-ui/pickers";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { coloresEmma } from '../../../global/js/funciones';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  colorWhite : {
      color: '#fff'
  },
  flexGrow: {
      flexGrow: 1,
  },
  flexCenter:{
      textAlign: "center",
      marginTop: "5px"
  },
  formControl: {
    margin: "0",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  colorEmmma: {
    color: coloresEmma.secondarycolor,
    transition: 'all 0.3s',
    '&:hover': {
      color: "#fff",
      backgroundColor: coloresEmma.secondarycolor,
      transform: "scale(1.3)",
    },
  },
  colorUsers: {
    color: "#20da87",
    transition: 'all 0.3s',
    '&:hover': {
      color: "#fff",
      backgroundColor: "#20da87",
      transform: "scale(1.3)",
    },
  },
  colorDelete: {
    color: "#d11a2a",
    transition: "all 0.3s",
    '&:hover': {
      color: "#fff",
      backgroundColor: "#d11a2a",
      transform: "scale(1.3)",
    },
  },
  colorEdit: {
    color: "#0074C5",
    transition: "all 0.3s",
    '&:hover': {
      color: "#fff",
      backgroundColor: "#0074C5",
      transform: "scale(1.3)",
    },
  },
  colorCheck: {
    color: "#2ECC71",
    transition: "all 0.3s",
    '&:hover': {
      color: "#fff",
      backgroundColor: "#2ECC71",
      transform: "scale(1.2)",
    },
  },
  escalar: {
    transition: "all 0.3s",
    border: '1px solid #e0e0e0',
    borderRadius: '5px',
    [theme.breakpoints.up('sm')]: {
        '&:hover': {
            transform: "scale(1.05)",
            border: `1px solid ${coloresEmma.secondarycolor}`,
            borderRadius: '0',
        },
        
    },
  },
  color:{
    transition: 'all 0.3s',
    borderRadius: "20px",
    '&:hover': {
        transform: "scale(1.01)",
        borderRadius: "0px",
        
    },
  },
  center:{
    textAlign: 'center'
},
}));

export default function SemillerosListSemi(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    //Cargando
    const [cargando, setCargando] = React.useState(true)
    const isInitialMount = React.useRef(true);
    const [infoSemi, setInfoSemi] = React.useState([]);
    const [infoSoli, setInfoSoli] = React.useState([]);
    const [adj_seminario, setAdj_seminario] = React.useState('');
    const [nombrecompleto_tutorlegal, setnombrecompleto_tutorlegal] = React.useState('');
    const [numeroidentificacion_tutorlegal,setnumeroidentificacion_tutorlegal] = React.useState('');
    const [PermisoTutor,setPermisoTutor] = React.useState('');
    const [adj_metodologia, setAdj_metodologia] = React.useState('');
    const [tipoSolicitud, setTipoSolicitud] = React.useState([]);
    const [seleccion, setSeleccion] = React.useState('tipo')
    const [tipoSolicitudActual, setTipoSolicitudActual] = React.useState([])

    const [tipoProduccionTecnica, setTipoProduccionTecnica] = React.useState([])
    const [tipoDeProducto, setTipoDeProducto] = React.useState([])
    const [tipoDeProductoBibliografica, setTipoDeProductoBibliografica] = React.useState([])
    const [certificados, setCertificados] = useState([]);
    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            const obtenerProcesos = async () => {

                let data = await obtenerSemillerosSolicitudes();
                let dataSolicitudes
                if(props.profesor == true){
                  dataSolicitudes = await obtenerParticipantesSolicitudes();
                }else{
                  dataSolicitudes = null
                }
                await obtenerTiposDeSolicitudes()


                setInfoSemi(data)
                setInfoSoli(dataSolicitudes)
                setCargando(false)
            }
            obtenerProcesos()

        }
    })

    const obtenerCertificados = () => {
      const certificadosParticipantes = props.infoSoli.map(participante => ({
        adj_seminario: participante.adj_seminario,
        adj_metodologia: participante.adj_metodologia,
        PermisoTutor:participante.PermisoTutor,
        nombrecompleto_tutorlegal:participante.nombrecompleto_tutorlegal,
        numeroidentificacion_tutorlegal:participante.numeroidentificacion_tutorlegal
        // Agregar otros campos de certificados necesarios
      }));
      setCertificados(certificadosParticipantes);
    };
  

    //Obtener semilleros solicitudes
    const obtenerSemillerosSolicitudes = async () => {
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/listaSemilleros/${props.semiActual.id}`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
            })
        })
    }

    const obtenerParticipantesSolicitudes = async () => {
      return new Promise(resolve => {
          Funciones.consulta(`api/v1.0/investigacion/listarParticipante2?estado_actual__codigo=Semi_par_acep&semillero=${props.semiActual.id}`, null, null, (error, estado, resp) => {
            resolve(estado === 200 && !error ? resp : null);
          })
      })
    }

    const obtenerTiposDeSolicitudes = async () => {
      try{
        const data = await Funciones.obtenerValores(Helper.GESTION_TIPOS_INVESTIGACION)

        setTipoSolicitud(data)
        
      }catch(err){
        console.log(err)
      }
  }

    const pintarInfo = () => {

        return <DetalleSolicitud datos={infoSemi}/>
    
    }
    
    const pintarInfo2 = () => {
    
      return <DetalleSolicitud2Parte datos={infoSemi}/>
    
    }
    
    const pintarInfo3 = () => {
    
      return <DetalleSolicitud3Parte datos={infoSemi} setOpenModalInfo={props.setOpenModalInfo}  setAccion={props.setAccion} gestor={props.gestor} />
    
    }

    const PintarInfo4 = () =>{
      
      const [openFiltrar, setOpenFiltrar] = React.useState(false);
      const [msg, setMsg] = React.useState("");
      const [openModalMessage, setOpenModalMessage] = React.useState(false);
      const [openModacertificados, setOpenModacertificados] = React.useState(false);
      const [dataRow, setDataRow] = React.useState("");
      const [dataRow2, setDataRow2] = React.useState("");
      const [adj_seminario, setAdj_seminario] = React.useState('');
      const dispatch = useDispatch();
      let habilitar = (props.gestor||props.profesor == true && props.vistaEstudiante != true);
      
      const down = (data) =>{
        setDataRow(data)
        setOpenModalMessage(true)
      }
      
      const closeDownModal = () =>{
        setMsg("")
        setOpenModalMessage(false)
      }
      const Certificado = (data) =>{
        let certificadosFiltrados = infoSoli.filter(e => e.participante.id == data.participante.id)
        setDataRow2(certificadosFiltrados)
        setOpenModacertificados(true)
      }
      const Certificadocolse = () =>{
        setOpenModacertificados(false)
      }
      const downWithMessageSenToApi = async () =>{




        let data = {
          id: dataRow.id,
          msg: msg
        }

        setCargando(true)
        closeDownModal()
        let url = "api/v1.0/investigacion/baja"
        const callback = async (error, estado, resp) => {
            let titulo = "Ha ocurrido un error, contacte con el administrador."
            let tipo = "error"

            if(!error){
                if(estado === 201){
                    tipo = "success"
                    titulo = resp.titulo
                }
            }

            dispatch(
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
            );
            
            let dataSolicitudes = await obtenerParticipantesSolicitudes();
            setInfoSoli(dataSolicitudes)
            setCargando(false)
        }
        const msg2 = await Funciones.consulta(url, data, 'POST', callback)
      }

      
      const acciones = (data) => {
          
        if(data.estado_actual.codigo == "Semi_par_acep"&& props.profesor==true&&props.gestor==false){
          return (
            <div>
                  {/* <IconButton aria-label="done" className={classes.colorDelete} onClick={() => {down(data)}}>
                      <ArrowDownwardIcon/>
                  </IconButton> */}
                  <Tooltip title='Ver Certificado' aria-label="add" onClick={() => {Certificado(data)}} >
                    <IconButton style={{ color: '#01579b' }} aria-label="delete">
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
              </div>
          )
        }else{
          return(
            <div></div>
          )
        }
      };

      return (
        <div>
            <ListarDatos
            id="tbl_listar_semilleros_participantes"
            //avatar={({ nombre }) => nombre.charAt(0)}
            datos={infoSoli}
            opciones={true}
            buscar={true}
            descargar='PARTICIPANTES DEL SEMILLERO'
            filtrar={habilitar}
            actfiltrar={() => setOpenFiltrar(true)}
            acciones={habilitar ? (row) => acciones(row) : ''}
            titulo={"Lista de participantes del Semilleros de Investigación"}
            fila_principal={({ participante, id }) => `${participante.primer_nombre} ${participante.segundo_nombre} ${participante.primer_apellido} ${participante.segundo_apellido}`}
            filas={[
              {
                'mostrar': ({ semillero }) => `${semillero.nombre}`,
                'id': 'Semillero', 'enLista': false
              },
              {
                'mostrar': ({ participante }) => `${participante.primer_nombre} ${participante.segundo_nombre} ${participante.primer_apellido} ${participante.segundo_apellido}`,
                'id': 'Participantes', 'enLista': false
              },
              {
                'mostrar': ({participante}) => `${participante.correo}`,
                'id': 'Correo', 'enLista': true
              },
              {
                'mostrar': ({participante}) => `${participante.identificacion}`,
                'id': 'Identificacion', 'enLista': true
              },
              {
                'mostrar': ({programa}) => `${programa.nombre}`,
                'id': 'Programa', 'enLista': true
              },
            ]}
          />
          {openFiltrar == true ? <ModalFiltrar 
            openFiltrar = {openFiltrar}
            setOpenFiltrar = {setOpenFiltrar}

            semiActual = {props.semiActual}

            infoSoli = {infoSoli}
            setInfoSoli = {setInfoSoli}

            cargando = {cargando}
            setCargando = {setCargando}
          /> : null}

          {openModalMessage == true ? 
          <MessageToDown 

            openModal = {openModalMessage}
            setOpenModal = {setOpenModalMessage}

            dataRow = {dataRow}

            downWithMessageSenToApi = {downWithMessageSenToApi}

            setMsg = {setMsg}
            msg = {msg}

            closeDownModal={closeDownModal}
          /> : null}
          {openModacertificados == true ? 
          <ModalExperiencias 
            
            infoSoli = {infoSoli}
            openModacertificados = {openModacertificados}
            setOpenModacertificados={setOpenModacertificados}
            Certificadocolse={Certificadocolse}
            dataRow2={dataRow2}
          /> : null}
        </div>
      )
            
    }//Fin pintarInfo 4

    const ModalFiltrar = (props) =>{

      const [lineaSeleccion, setLineaSeleccion] = React.useState('');
      const [lineaSeleccionCat, setLineaSeleccionCat] = React.useState('');
      const [estadosPar, setEstadosPar] =  React.useState([]);
      const [categoriaPar, setCategoriaPar] = React.useState([]);
      const isInitialMount = React.useRef(true);
      const [cargandoPar, setCargadoPar] = React.useState(true);
      React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            obtenerEstados()
            obtenerCategorias()
        }
      })
      //Obtengo los estados
      const obtenerEstados = async () => {
        try{
          const data = await Funciones.obtenerValores(Helper.SOLICITUDES_PAR_INVES)

          setEstadosPar(data)
        }catch(err){
          console.log(err)
        } 
      }

      //Obtengo las categorias
      const obtenerCategorias = async () => {
        try{
          const data = await Funciones.obtenerValores(Helper.CATEGORIA_INVESTIGACION)

          setCategoriaPar(data)
          setCargadoPar(false)
        }catch(err){
          console.log(err)
        } 
      }

      const filtrarParticipanteSolicitudes = async () => {
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/listarParticipante2?estado_actual__codigo=${lineaSeleccion.codigo}&semillero=${props.semiActual.id}`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
            })
        })
      }

      const filtrarParticipanteCategoria = async () => {
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/listarParticipante2?estado_actual__codigo=Semi_par_acep&participante__categoria_investigacion=${lineaSeleccionCat.id}&semillero=${props.semiActual.id}`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
            })
        })
      }

      const filtrarParticipanteCategoriaAndSolicitudes = async () => {
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/listarParticipante2?estado_actual__codigo=${lineaSeleccion.codigo}&participante__categoria_investigacion=${lineaSeleccionCat.id}&semillero=${props.semiActual.id}`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
            })
        })
      }

      //Funcipan para cambiar el seleccionado
      const handleChangeLinea = (event) => {
        setLineaSeleccion(event.target.value);
      };

      const handleChangeCategoria = (e) =>{
        setLineaSeleccionCat(e.target.value)
      }

      const filtrar = async() =>{
        if(lineaSeleccion == "" && lineaSeleccionCat == ""){
          alert("elija una opcion")
        }else{
          props.setCargando(true)
          let data
          if(lineaSeleccionCat == "" && lineaSeleccion != ""){
            data = await filtrarParticipanteSolicitudes()
          }else if(lineaSeleccionCat != "" && lineaSeleccion == ""){
            data = await filtrarParticipanteCategoria()
          }else if(lineaSeleccionCat != "" && lineaSeleccion != ""){
            data = await filtrarParticipanteCategoriaAndSolicitudes()
          }

          props.setInfoSoli(data)
          props.setCargando(false)
        }
      }

      return(
        <div>
          <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openFiltrar} onClose={() => props.setOpenFiltrar(false)}>
            <AppBarModal titulo={'¡ Más Información !'} mostrarModal={() => props.setOpenFiltrar(false)} titulo_accion="" accion="" />
            <DialogContent>
              {cargandoPar == true ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" /> : <Grid container spacing={3}>
                <Grid item xs={12}>
                  <InputLabel htmlFor="age-native-simple">Estados</InputLabel>
                  <FormControl className={classes.formControl} fullWidth /*error = { props.getError('lineaSubInves', props.errores).length > 0}*/>
                    <Select
                      value={lineaSeleccion}
                      displayEmpty
                      fullWidth
                      //className={classes.selectEmpty}
                      inputProps={{ 'aria-label': 'Without label' }}
                      onChange={handleChangeLinea}
                    >
                      <MenuItem value="">
                          <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${estadosPar.length} Estados Disponibles` }</em>
                      </MenuItem>
                      {estadosPar.map((item, index) => (
                        <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                      ))}
                      </Select>
                  </FormControl>
                  {/*<FormHelperText>{ props.getError('lineaSubInves', props.errores) }</FormHelperText>*/}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="age-native-simple">Categoria</InputLabel>
                  <FormControl className={classes.formControl} fullWidth /*error = { props.getError('lineaSubInves', props.errores).length > 0}*/>
                    <Select
                      value={lineaSeleccionCat}
                      displayEmpty
                      fullWidth
                      //className={classes.selectEmpty}
                      inputProps={{ 'aria-label': 'Without label' }}
                      onChange={handleChangeCategoria}
                    >
                      <MenuItem value="">
                          <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${categoriaPar.length} Categorias Disponibles` }</em>
                      </MenuItem>
                      {categoriaPar.map((item, index) => (
                        <MenuItem key= { index } value = { item }>{`${item.nombre}`}</MenuItem>
                      ))}
                      </Select>
                  </FormControl>
                  {/*<FormHelperText>{ props.getError('lineaSubInves', props.errores) }</FormHelperText>*/}
                </Grid>
              </Grid>}
            </DialogContent>
            <DialogActions>
                  <BtnForm texto="Filtrar" callback={filtrar} />
            </DialogActions>
          </Dialog>
        </div>
      )
    }
    const ModalExperiencias = (props) => {
      const btnVer = archivo =>
          <Link href={`${Funciones.api}${archivo}`} target="_blank">
              <IconButton style={{ color: '#01579b' }} aria-label="delete">
                  <VisibilityIcon fontSize="small" />
              </IconButton>
          </Link>
  
      return(
          <Dialog open={props.openModacertificados} onClose={() => { props.Certificadocolse() }} aria-labelledby="form-dialog-title" maxWidth='sm' fullWidth={true}>
              <AppBarModal titulo='Detalle Participante' mostrarModal={() => { props.Certificadocolse() }} titulo_accion="" accion="" />
              <DialogContent>
                  <Grid container spacing={3}>
                      <Grid item xs={12}>
                          <Table>
                              <TableBody>
                                <React.Fragment >
                                    <TableRow>
                                      <TableCell component="th" scope="row">Soporte Seminario Adjunto</TableCell>
                                      {/* <TableCell align="left">{btnVer(props.dataRow2[0].adj_seminario)}</TableCell> */}
                                      <TableCell align="left">{props.dataRow2[0].adj_seminario ? btnVer(props.dataRow2[0].adj_seminario) : <span>Sin certificado</span>}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell component="th" scope="row">Soporte Metodologia de Investigación</TableCell>
                                      {/* <TableCell align="left">{btnVer(props.dataRow2[0].adj_metodologia)}</TableCell> */}
                                      <TableCell align="left">{props.dataRow2[0].adj_metodologia ? btnVer(props.dataRow2[0].adj_metodologia) : <span>Sin certificado</span>}</TableCell>
                                      </TableRow>
                                      {props.dataRow2[0].nombrecompleto_tutorlegal && (
                                      <TableRow>
                                        <TableCell component="th" scope="row">Nombre del tutor legal</TableCell>
                                        <TableCell align="left">{props.dataRow2[0].nombrecompleto_tutorlegal}</TableCell>
                                      </TableRow>
                                      )}
                                      {props.dataRow2[0].numeroidentificacion_tutorlegal && (
                                      <TableRow>
                                        <TableCell component="th" scope="row">Número de identificación del tutor legal</TableCell>
                                        <TableCell align="left">{props.dataRow2[0].numeroidentificacion_tutorlegal || 'Disponible para estudiantes menores de edad'}</TableCell>
                                        </TableRow> 
                                        )}
                                      {props.dataRow2[0].PermisoTutor  && (
                                      <TableRow>
                                      <TableCell component="th" scope="row">Autorización del tutor legal</TableCell>
                                      {/* <TableCell align="left">{btnVer(props.dataRow2[0].adj_metodologia)}</TableCell> */}
                                      <TableCell align="left">{props.dataRow2[0].PermisoTutor || 'Disponible para estudiantes menores de edad'}</TableCell>
                                    </TableRow>
                                    )}
                                </React.Fragment>
                              
                              </TableBody>
                          </Table>
                      </Grid>
                  </Grid>
              </DialogContent>
              <DialogActions>
                  <BtnForm texto="Cerrar" callback={() => { props.Certificadocolse()}} />
              </DialogActions>
          </Dialog>
      )
  }
    const MessageToDown = (props) => {
    
      const handleOnchange = (e) =>{
          if(e.target.name == "msg"){
              props.setMsg(e.target.value)
          }
      }
    
      return (
        <div>
          <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModal} onClose={() => props.closeDownModal()}>
            <AppBarModal titulo={'¡ Digite su Mensaje !'} mostrarModal={() => props.closeDownModal()} titulo_accion="" accion="" />
            <DialogContent>
              <Grid container spacing={3}>
                  <Grid item xs={12}>
                      <TextField 
                          fullWidth 
                          multiline
                          rowsMax={6}
                          label="Escriba un mensaje indicándole la razón o motivo de por qué se desvinculó del grupo." 
                          id = 'msg'
                          //error = { props.getError('celular', props.errores).length > 0}
                          //helperText = { props.getError('celular', props.errores) }
                          value={props.msg} 
                          onChange={handleOnchange}
                          name="msg"
                      />
                  </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" style={{color: "#fff"}} onClick={props.downWithMessageSenToApi}>
                  Enviar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    
    }
    
    const PintarInfo5 = (props) =>{
      const classes = useStyles();


      const [tipoProductoTec, setTipoProductoTec] = React.useState([])
      const changeVista = async (item) =>{
        props.setTipoSolicitudActual(item)

        if(item.codigo == "Semi_inves_tec"){
          props.setSeleccion('tecnica')

        }else if(item.codigo == "Soli_inves_eventos"){
          props.setSeleccion('eventos')
        }else if(item.codigo == "Semi_inves_proyectos"){
          props.setSeleccion('proyectos')
        }else if(item.codigo == "Semi_inves_bibliografica"){
          props.setSeleccion('bibliografica')
        }
      }

      const changeViewToList = (item) =>{
        props.setSeleccion('tipoProductoTecnica')
        props.setTipoProduccionTecnica(item)
      }

      const changeViewToListReal = (item) =>{
        props.setSeleccion('gestionTecnica')
        props.setTipoDeProducto(item)
      }

      const changeViewToListRealBibliografica = (item) =>{
        props.setSeleccion('gestionBibliografica')
        props.setTipoDeProductoBibliografica(item)
      }

      function laImagen (item) {

        if(item == "Semi_inves_bibliografica"){
          return bookInvestigacion
        }else if(item == "Soli_inves_eventos"){
          return eventInvestigacion
        }else if(item == "Semi_inves_tec"){
          return projectInvestigacion
        }else if(item == "Semi_inves_proyectos"){
          return clipboardInvestigacion
        }
      }

      const vista = () =>{
        switch (props.seleccion) {
          case 'tipo':
            return (
              <Grid container direction={'row'} spacing={2} style={{ width: "100%" }}>
                {props.tipoSolicitud.map((item, index) => (
                  <Grid key={index} item xs={12} sm={6}>
                    <Paper className={classes.escalar} elevation={3} onClick={() => changeVista(item)}>
                        <AccionesRapidas imagen={laImagen(item.codigo)} titulo={item.valorc.replace('Crear', 'Listar')} sub_titulo='' des_sub_titulo='' />
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            )
          case 'tecnica':
            return (
              <CardsTipoProduccionTecnica
                cargando={props.cargando}
                setCargando={props.setCargando}

                tipoSolicitudActual={props.tipoSolicitudActual}
                setTipoSolicitudActual={setTipoSolicitudActual}

                changeViewToList={changeViewToList}
              />
            )
          case 'tipoProductoTecnica':
            return(
              <CardsTipoProductoProduccionTecnica 
                tipoProduccionTecnica={tipoProduccionTecnica}
                setTipoProduccionTecnica={props.setTipoProduccionTecnica}

                tipoSolicitudActual={props.tipoSolicitudActual}
                setTipoSolicitudActual={setTipoSolicitudActual}

                tipoProductoTec={tipoProductoTec}
                setTipoProductoTec={setTipoProductoTec}

                changeViewToListReal={changeViewToListReal}
              />
            )
          case 'gestionTecnica':
            return(
              <ListTipoProduccionTecnica 
                semiActual={props.semiActual}
                tipoSolicitudActual={props.tipoSolicitudActual}
                tipoProduccionTecnica={tipoProduccionTecnica}
                tipoDeProducto={tipoDeProducto}
                setTipoProduccionTecnica={props.setTipoProduccionTecnica}

                changeViewToListReal={changeViewToListReal}

                
                setTipoDeProducto={props.setTipoDeProducto}
              />
            )
          case 'eventos':
            return (
              <ListEventosGestionSemillero 
                semiActual={props.semiActual}
                tipoSolicitudActual={props.tipoSolicitudActual}
                profesor={props.profesor}
              />
            )
          case 'proyectos':
            return (
              <ListProyectosGestionSemillero 
                semiActual={props.semiActual}
                tipoSolicitudActual={props.tipoSolicitudActual}
              />
            )
          case 'bibliografica':
            return (
              <CardsBibliograficaGestionSemillero 
                semiActual={props.semiActual}
                tipoSolicitudActual={props.tipoSolicitudActual}

                changeViewToListRealBibliografica={changeViewToListRealBibliografica}
              />
            )
          case 'gestionBibliografica':
            return (
              <ListBibliograficaGestionSemillero 
                semiActual={props.semiActual}
                tipoSolicitudActual={props.tipoSolicitudActual}
                vistaEstudiante={props.vistaEstudiante}
                profesor={props.profesor}
                tipoDeProductoBibliografica={props.tipoDeProductoBibliografica}
              />
            )
        }
      }

      return (
        <div>
          {props.cargando == true ? <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> : vista()}
        </div>
      )
    }

    const pintar = (pos, dispatch) => {
        if (props.cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
        return (
          <div>
            {pos === 'Información del Semillero' ? pintarInfo() : ''}
            {pos === 'Departamento y Programa' ? pintarInfo2() : ''}
            {pos === 'Lideres' ? pintarInfo3() : ''}
            {pos === 'Solicitudes' ? <PintarInfo4 infoSoli={infoSoli}/> : ''}
            {pos === 'Produccion del Semillero' ? <PintarInfo5 pos={pos} tipoDeProductoBibliografica={tipoDeProductoBibliografica} setTipoDeProductoBibliografica={setTipoDeProductoBibliografica} tipoDeProducto={tipoDeProducto} setTipoDeProducto={setTipoDeProducto} tipoProduccionTecnica={tipoProduccionTecnica} setTipoProduccionTecnica={setTipoProduccionTecnica} tipoSolicitudActual={tipoSolicitudActual} setTipoSolicitudActual={setTipoSolicitudActual} seleccion={seleccion} setSeleccion={setSeleccion} tipoSolicitud={tipoSolicitud} cargando={cargando} setCargando={setCargando} semiActual={props.semiActual} profesor={props.profesor} />: ''}
          </div>
        )
    
    }

    const handleClose = () =>{
      props.setPos('Información del Semillero')
      props.setOpenModalInfo(false)
    }

    const handleBack = () =>{
      if(seleccion == 'gestionBibliografica'){
        setSeleccion('bibliografica')
      }else if(seleccion == 'gestionTecnica'){
        setSeleccion('tipoProductoTecnica')
      }else if(seleccion == 'tipoProductoTecnica'){
        setSeleccion('tecnica')
      }else{
        setSeleccion('tipo')
      }
      
    }

    return (
      <div>
        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModalInfo} onClose={() => props.setOpenModalInfo(false)}>
          <AppBarModal titulo={'¡ Más Información !'} mostrarModal={handleClose} titulo_accion="" accion="" />
          <SemilleroMenuProfesor gestor={props.gestor} vistaEstudiante={props.vistaEstudiante} profesor={props.profesor} sele={props.pos} seleccionar={(pos) => {
            props.setPos(pos)
            if(pos != 'Produccion del Semillero'){
              setSeleccion('tipo')
            }
          }} />
          <DialogContent>
            {cargando == true ? <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> : pintar(props.pos)}
          </DialogContent>
          <DialogActions>
                <BtnForm texto="Cerrar" callback={handleClose} />
                <BtnForm texto="Atras" callback={handleBack} />
          </DialogActions>
        </Dialog>
      </div>
    )
}

const ListBibliograficaGestionSemillero = (props) =>{
  const isInitialMount = React.useRef(true);
  const [proyectos, setProyectos] = React.useState([]);
  const [cargandoLista, setCargandoLista] = React.useState(true);
  const [data, setData] = React.useState([])
  const [openModal, setOpenModal] = React.useState(false)
  const [dataUsers, setDataUsers] = React.useState(false)
  const [openModalUsers, setOpenModalUsers] = React.useState(false);
  const [dataEdit, setDataEdit] = React.useState([])
  const [openModalEdit, setOpenModalEdit] = React.useState(false)
  const [dataSet, setDataSet] = React.useState([])
  const [dataSetParticipantes, setDataSetParticipantes] = React.useState([])
  const [openModalFiltrar, setOpenModalFiltrar] = React.useState(false)
  const [ageFilter, setAgeFilter] = React.useState(new Date())
  const [userFilter, setUserFilter] = React.useState({nombre: ''})
  const classes = useStyles()
  React.useEffect( () => {
    //Obtengo los prceosos activos de la persona activa.
    if(isInitialMount.current){
        isInitialMount.current = false
        const obtenerProcesos = async () => {
          let data = await obtenerEventosGestionSemillero();
          setProyectos(data);

          setCargandoLista(false)
        }
        obtenerProcesos()
    }
  })

  const obtenerEventosGestionSemillero = async () => {
    return new Promise(resolve => {
        Funciones.consulta(`api/v1.0/investigacion/listaGestionSemilleroBibliografia?semillero__id=${props.semiActual.id}&tipo__codigo=${props.tipoSolicitudActual.codigo}&tipo_producto__codigo=${props.tipoDeProductoBibliografica.codigo}`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        })
    })
  }

  const actions = (row) => {
  
    const eventoDetalle = (row) => {

      setData(row)
      setOpenModal(true)
    }

    const eventoDetalleParticipantes = (row) =>{

      setDataUsers(row)
      setOpenModalUsers(true)
    }

    const editar = (row) =>{

      setDataEdit(row)
      setOpenModalEdit(true)
    }

    async function ReporteExcel (row) {

      if(row.tipo_de_producto.codigo == "Inves_tipo_biblio_articulo"){

        const obtenerTiposDePatentes = async () => {
          try{
              let data = await getInfoRowUsersBibliografica(row.id)
              data = data.map((item)=>({
                id: item.participante.id,
                primer_nombre: `${item.participante.primer_nombre}`,
                segundo_nombre: `${item.participante.segundo_nombre}`,
                primer_apellido: `${item.participante.primer_apellido}`,
                segundo_apellido: `${item.participante.segundo_apellido}`,
                correo: `${item.participante.correo}`,
                identificacion: `${item.participante.identificacion}`
              }))
              setDataSetParticipantes(data)
              setDataSet([
                {
                  nombreArticulo: row.nombre,
                  anioArticulo: row.anio,
                  idiomaArticulo: row.idioma.nombre,
                  paisArticulo: row.pais.nombre,
                  doiArticulo: row.doi,
                  enlaceArticulo: row.enlace,
                  indexacionArticulo: row.indexacion.nombre,
                  noRevista: row.no_revista,
                  issn: row.issn,
                  nombreRevista: row.nombre_de_revista,
                  numroPaginas: row.numero_de_paginas,
                  tipoArticulo: row.tipo_de_articulo.nombre,
                  tipoOtroArticulo: row.tipo_de_articulo_otro
                }
              ])
          }catch(err){
            console.log(err)
          } 
        }
        
        await obtenerTiposDePatentes()
        document.getElementById('excelArticulo').click()
      }else if(row.tipo_de_producto.codigo == "Inves_tipo_biblio_libro"){
        const obtenerTiposDePatentes = async () => {
          try{
              let data = await getInfoRowUsersBibliografica(row.id)
              data = data.map((item)=>({
                id: item.participante.id,
                primer_nombre: `${item.participante.primer_nombre}`,
                segundo_nombre: `${item.participante.segundo_nombre}`,
                primer_apellido: `${item.participante.primer_apellido}`,
                segundo_apellido: `${item.participante.segundo_apellido}`,
                correo: `${item.participante.correo}`,
                identificacion: `${item.participante.identificacion}`
              }))
              setDataSetParticipantes(data)
              setDataSet([
                {
                  nombreLibro: row.nombre,
                  anioLibro: row.anio,
                  idiomaLibro: row.idioma.nombre,
                  isbnLibro: row.isbn,
                  noEdicion: row.no_edicion,
                  editorialLibro: row.editorial,
                  paisLibro: row.pais.nombre,
                  tipoLibro: row.tipo_libro.nombre,
                  tipoOtroLibro: row.tipo_libro_otro
                }
              ])
          }catch(err){
            console.log(err)
          } 
        }
        
        await obtenerTiposDePatentes()
        document.getElementById('excelLibro').click()
      }else if(row.tipo_de_producto.codigo == "Inves_tipo_biblio_capituloLib"){
        const obtenerTiposDePatentes = async () => {
          try{
              let data = await getInfoRowUsersBibliografica(row.id)
              data = data.map((item)=>({
                id: item.participante.id,
                primer_nombre: `${item.participante.primer_nombre}`,
                segundo_nombre: `${item.participante.segundo_nombre}`,
                primer_apellido: `${item.participante.primer_apellido}`,
                segundo_apellido: `${item.participante.segundo_apellido}`,
                correo: `${item.participante.correo}`,
                identificacion: `${item.participante.identificacion}`
              }))
              setDataSetParticipantes(data)
              setDataSet([
                {
                  tipoLibro: row.tipo_libro.nombre,
                  tipoLibroOtro: row.tipo_libro_otro,
                  tituloCapitulo: row.nombre,
                  tituloLibro: row.titulo_del_libro,
                  isbnCapLibro: row.isbn,
                  editorialCapLibro: row.editorial,
                  anioCapLibro: row.anio,
                  paisCapLibro: row.pais.nombre,
                  idiomaCapLibro: row.idioma.nombre,
                  numEdicionCapLibro: row.no_edicion,
                }
              ])
          }catch(err){
            console.log(err)
          } 
        }
        
        await obtenerTiposDePatentes()
        document.getElementById('excelCapLibro').click()
      }else if(row.tipo_de_producto.codigo == "Inves_tipo_biblio_patente"){
        const obtenerTiposDePatentes = async () => {
          try{
              let data = await getInfoRowUsersBibliografica(row.id)
              data = data.map((item)=>({
                id: item.participante.id,
                primer_nombre: `${item.participante.primer_nombre}`,
                segundo_nombre: `${item.participante.segundo_nombre}`,
                primer_apellido: `${item.participante.primer_apellido}`,
                segundo_apellido: `${item.participante.segundo_apellido}`,
                correo: `${item.participante.correo}`,
                identificacion: `${item.participante.identificacion}`
              }))
              setDataSetParticipantes(data)
              setDataSet([
                {
                  tituloPatente: row.nombre,
                  anioPatente: row.anio,
                  gacetaPatente: row.gaceta_industrial,
                  numeroPatente: row.numero_patente,
                  paisPatente: row.pais.nombre,
                  institucionPatente: row.institucion,
                  tipoPatente: row.tipo_patente.nombre,
                  tipoPatenteOtro: row.tipo_patente_otro
                }
              ])
          }catch(err){
            console.log(err)
          } 
        }
        
        await obtenerTiposDePatentes()
        document.getElementById('excelPatente').click()
      }
    
      
    }
    
    const detalle = (
      <div>
          <IconButton aria-label="done" className={classes.colorEmmma} onClick={() => eventoDetalle(row)}>
              <ImportContactsIcon/>
          </IconButton>
          <IconButton aria-label="done" className={classes.colorUsers} onClick={() => eventoDetalleParticipantes(row)}>
              <PeopleIcon/>
          </IconButton>
          <IconButton aria-label="done" className={classes.colorEdit} onClick={() => editar(row)}>
              <EditIcon/>
          </IconButton>
          <Tooltip title='Descargar' aria-label="add" >
          <IconButton aria-label="done" className={classes.colorEdit} onClick={() => ReporteExcel(row)}>
              <GetAppIcon/>
          </IconButton>
          </Tooltip>
      </div>
    );
    return detalle

  }

  const aplicarFiltros = async () =>{
    setCargandoLista(true)
    let age = moment(ageFilter).format('YYYY')
    let filtro = ''
    if(userFilter.nombre == ''){
      filtro = `anio=${age}`
    }else{
      filtro = `Autor_investigacion__participante=${userFilter.id}&anio=${age}`
    }
    const obtenerProcesos = async () => {
      let data = await obtenerEventosGestionSemilleroFilter(filtro);
      setProyectos(data)
      setOpenModalFiltrar(false)
      setCargandoLista(false)
    }
    obtenerProcesos()
  }

  const obtenerEventosGestionSemilleroFilter = async (f) => {
    return new Promise(resolve => {
        Funciones.consulta(`api/v1.0/investigacion/listaGestionSemilleroBibliografia?semillero__id=${props.semiActual.id}&tipo__codigo=${props.tipoSolicitudActual.codigo}&tipo_producto__codigo=${props.tipoDeProductoBibliografica.codigo}&${f}`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        })
    })
  }

  return (
    cargandoLista == true ? <div> <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> <LinearProgress /></div> :
      <div>
        <ListarDatos
            id="tbl_listar_encargados"
            descargar="EMMA - BIBLIOGRAFICA - INVESTIGACION"
            datos={proyectos}
            opciones={true}
            filtrar={true}
            actfiltrar={() => setOpenModalFiltrar(true)}
            //agregar={true}
            buscar={true}
            //actAgregar={() => setopenModalAddParti(true)}
            css={{
            list: { padding: '0px 0px 20px 0px' },
            appBar: { padding: '0px 0px 0px 0px' },
            }}
            acciones={(row) => actions(row)}
            titulo={"Lista de Productos"}
            fila_principal={row => `${row.nombre}`}
            filas={
              props.tipoDeProductoBibliografica.codigo == "Inves_tipo_biblio_patente" ? [
                {
                  'mostrar': (row) => `${row.nombre}`,
                  'id': 'Titulo de la Patente', 'enLista': false
                },
                {
                  'mostrar': (row) => {
                    if(props.tipoDeProductoBibliografica.codigo == "Inves_tipo_biblio_patente"){
                      return `${moment(row.anio).format('YYYY')}`
                    }
                  },
                  'id': 'Año', 'enLista': true
                },
                {
                  'mostrar': (row) => `${row.gaceta_industrial}`,
                  'id': 'Gaceta Industrial', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.numero_patente}`,
                  'id': 'Numero de la Patente', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.pais.nombre}`,
                  'id': 'Pais', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.institucion}`,
                  'id': 'Institucion', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.tipo_patente.nombre}`,
                  'id': 'Tipo de Patente', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.tipo_patente_otro}`,
                  'id': 'Tipo de Patente Otro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.Autor_investigacion.map((item)=>{
                    return ` ${item.participante.primer_nombre} ${item.participante.primer_apellido}`
                  })}`,
                  'id': 'Participantes', 'enLista': false
                },
              ] :   props.tipoDeProductoBibliografica.codigo == "Inves_tipo_biblio_capituloLib" ? [
                {
                  'mostrar': (row) => `${row.tipo_libro.nombre}`,
                  'id': 'Tipo de Libro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.tipo_libro_otro}`,
                  'id': 'Tipo de Libro Otro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.nombre}`,
                  'id': 'Titulo del Capitulo', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.titulo_del_libro}`,
                  'id': 'Titulo del Libro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.isbn}`,
                  'id': 'ISBN', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.editorial}`,
                  'id': 'Editorial', 'enLista': false
                },
                {
                  'mostrar': (row) => {
                    if(props.tipoDeProductoBibliografica.codigo == "Inves_tipo_biblio_capituloLib"){
                      return `${moment(row.anio).format('YYYY')}`
                    }
                  },
                  'id': 'Año', 'enLista': true
                },
                {
                  'mostrar': (row) => `${row.pais.nombre}`,
                  'id': 'Pais', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.idioma.nombre}`,
                  'id': 'Idioma', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.no_edicion}`,
                  'id': 'No. Edicion', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.Autor_investigacion.map((item)=>{
                    return ` ${item.participante.primer_nombre} ${item.participante.primer_apellido}`
                  })}`,
                  'id': 'Participantes', 'enLista': false
                },
              ] : props.tipoDeProductoBibliografica.codigo == "Inves_tipo_biblio_articulo" ? 
                [
                  {
                    'mostrar': (row) => `${row.nombre}`,
                    'id': 'Nombre del Articulo', 'enLista': false
                  },
                  {
                    'mostrar': (row) => {
                      if(props.tipoDeProductoBibliografica.codigo == "Inves_tipo_biblio_articulo"){
                        return `${moment(row.anio).format('YYYY')}`
                      }
                    },
                    'id': 'Año', 'enLista': true
                  },
                  {
                    'mostrar': (row) => `${row.idioma.nombre}`,
                    'id': 'Idioma del Articulo', 'enLista': false
                  },
                  {
                    'mostrar': (row) => `${row.pais.nombre}`,
                    'id': 'Pais del Articulo', 'enLista': false
                  },
                  {
                    'mostrar': (row) => `${row.doi}`,
                    'id': 'DOI del Articulo', 'enLista': false
                  },
                  {
                    'mostrar': (row) => `${row.enlace}`,
                    'id': 'Enlace del Articulo', 'enLista': false
                  },
                  {
                    'mostrar': (row) => `${row.indexacion.nombre}`,
                    'id': 'Indexacion del Articulo', 'enLista': false
                  },
                  {
                    'mostrar': (row) => `${row.no_revista}`,
                    'id': 'No. de Revista', 'enLista': false
                  },
                  {
                    'mostrar': (row) => `${row.issn}`,
                    'id': 'ISSN', 'enLista': false
                  },
                  {
                    'mostrar': (row) => `${row.nombre_de_revista}`,
                    'id': 'Nombre de la Revista', 'enLista': false
                  },
                  {
                    'mostrar': (row) => `${row.numero_de_paginas}`,
                    'id': 'Numero de Paginas', 'enLista': false
                  },
                  {
                    'mostrar': (row) => `${row.tipo_de_articulo.nombre}`,
                    'id': 'Tipo de Articulo', 'enLista': false
                  },
                  {
                    'mostrar': (row) => `${row.tipo_de_articulo_otro}`,
                    'id': 'Tipo de Articulo Otro', 'enLista': false
                  },
                  {
                    'mostrar': (row) => `${row.Autor_investigacion.map((item)=>{
                      return ` ${item.participante.primer_nombre} ${item.participante.primer_apellido}`
                    })}`,
                    'id': 'Participantes', 'enLista': false
                  },
                ]
              : props.tipoDeProductoBibliografica.codigo == "Inves_tipo_biblio_libro" ? 
              [
                {
                  'mostrar': (row) => `${row.nombre}`,
                  'id': 'Nombre del Libro', 'enLista': false
                },
                {
                  'mostrar': (row) => {
                    if(props.tipoDeProductoBibliografica.codigo == "Inves_tipo_biblio_libro"){
                      return `${moment(row.anio).format('YYYY')}`
                    }
                  },
                  'id': 'Año', 'enLista': true
                },
                {
                  'mostrar': (row) => `${row.idioma.nombre}`,
                  'id': 'Idioma del Libro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.isbn}`,
                  'id': 'ISBN del Libro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.no_edicion}`,
                  'id': 'No. de Edicion', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.editorial}`,
                  'id': 'Editorial del Libro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.pais.nombre}`,
                  'id': 'Pais del Libro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.tipo_libro.nombre}`,
                  'id': 'Tipo de Libro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.tipo_libro_otro}`,
                  'id': 'Tipo de Libro Otro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.Autor_investigacion.map((item)=>{
                    return ` ${item.participante.primer_nombre} ${item.participante.primer_apellido}`
                  })}`,
                  'id': 'Participantes', 'enLista': false
                },
              ]
              :[]
          }
        />

        {openModal == true ? <ModalShowInformationProduBiblio
          data={data}
          openModal={openModal}
          setOpenModal={setOpenModal}
          
        /> : ''}
        {openModalUsers == true ? <ModalShowInformationForUsers 
          data={dataUsers}
          openModal={openModalUsers}
          setOpenModal={setOpenModalUsers}
        /> : ''}
        {openModalEdit == true ? <SemillerosGestionEdit 
          data={dataEdit}
          openModal={openModalEdit}
          setOpenModal={setOpenModalEdit}
        /> : ''}

        {openModalFiltrar == true ? <FiltrarEventosProduBiblio
          openModal={openModalFiltrar}
          setOpenModal={setOpenModalFiltrar}

          ageFilter={ageFilter}
          setAgeFilter={setAgeFilter}

          aplicarFiltros={aplicarFiltros}

          profesor={userFilter}
          setProfesor={setUserFilter}
        /> : ''
        }

        <ExcelFile element={<button className='oculto' id={'excelArticulo'} />}>
        <ExcelSheet data={dataSet} name="Proyecto">
            <ExcelColumn label="Nombre del Articulo" value="nombreArticulo"/>
            <ExcelColumn label="Año del Articulo" value="anioArticulo"/>
            <ExcelColumn label="Idioma del Articulo" value="idiomaArticulo"/>
            <ExcelColumn label="Pais del Articulo" value="paisArticulo"/>
            <ExcelColumn label="DOI del Articulo" value="doiArticulo"/>
            <ExcelColumn label="Enlace del Articulo" value="enlaceArticulo"/>
            <ExcelColumn label="Indexacion del Articulo" value="indexacionArticulo"/>
            <ExcelColumn label="No. de Revista" value="noRevista"/>
            <ExcelColumn label="ISSN" value="issn"/>
            <ExcelColumn label="Nombre de la Revista" value="nombreRevista"/>
            <ExcelColumn label="Numero de Paginas" value="numroPaginas"/>
            <ExcelColumn label="Tipo de Articulo" value="tipoArticulo"/>
            <ExcelColumn label="Tipo de Articulo Otro" value="tipoOtroArticulo"/>
        </ExcelSheet>
        <ExcelSheet data={dataSetParticipantes} name="Participantes">
            <ExcelColumn label="Primer Nombre" value="primer_nombre"/>
            <ExcelColumn label="Segundo Nombre" value="segundo_nombre"/>
            <ExcelColumn label="Primer Apellido" value="primer_apellido"/>
            <ExcelColumn label="Segundo Apellido" value="segundo_apellido"/>
            <ExcelColumn label="Identificacion" value="identificacion"/>
            <ExcelColumn label="Correo" value="correo"/>
        </ExcelSheet>
        </ExcelFile>

        <ExcelFile element={<button className='oculto' id={'excelLibro'} />}>
        <ExcelSheet data={dataSet} name="Proyecto">
            <ExcelColumn label="Nombre del Libro" value="nombreLibro"/>
            <ExcelColumn label="Año del Libro" value="anioLibro"/>
            <ExcelColumn label="Idioma del Libro" value="idiomaLibro"/>
            <ExcelColumn label="ISBN del Libro" value="isbnLibro"/>
            <ExcelColumn label="No. de Edicion" value="noEdicion"/>
            <ExcelColumn label="Editorial del Libro" value="editorialLibro"/>
            <ExcelColumn label="Pais del Libro" value="paisLibro"/>
            <ExcelColumn label="Tipo de Libro" value="tipoLibro"/>
            <ExcelColumn label="Tipo de Libro Otro" value="tipoOtroLibro"/>
        </ExcelSheet>
        <ExcelSheet data={dataSetParticipantes} name="Participantes">
            <ExcelColumn label="Primer Nombre" value="primer_nombre"/>
            <ExcelColumn label="Segundo Nombre" value="segundo_nombre"/>
            <ExcelColumn label="Primer Apellido" value="primer_apellido"/>
            <ExcelColumn label="Segundo Apellido" value="segundo_apellido"/>
            <ExcelColumn label="Identificacion" value="identificacion"/>
            <ExcelColumn label="Correo" value="correo"/>
        </ExcelSheet>
        </ExcelFile>

        <ExcelFile element={<button className='oculto' id={'excelCapLibro'} />}>
        <ExcelSheet data={dataSet} name="Proyecto">
            <ExcelColumn label="Tipo de Libro" value="tipoLibro"/>
            <ExcelColumn label="Tipo de Libro Otro" value="tipoLibroOtro"/>
            <ExcelColumn label="Titulo del Capitulo" value="tituloCapitulo"/>
            <ExcelColumn label="Titulo del Libro" value="tituloLibro"/>
            <ExcelColumn label="ISBN" value="isbnCapLibro"/>
            <ExcelColumn label="Editorial" value="editorialCapLibro"/>
            <ExcelColumn label="Año" value="anioCapLibro"/>
            <ExcelColumn label="Pais" value="paisCapLibro"/>
            <ExcelColumn label="Idioma" value="idiomaCapLibro"/>
            <ExcelColumn label="No. Edicion" value="numEdicionCapLibro"/>
        </ExcelSheet>
        <ExcelSheet data={dataSetParticipantes} name="Participantes">
            <ExcelColumn label="Primer Nombre" value="primer_nombre"/>
            <ExcelColumn label="Segundo Nombre" value="segundo_nombre"/>
            <ExcelColumn label="Primer Apellido" value="primer_apellido"/>
            <ExcelColumn label="Segundo Apellido" value="segundo_apellido"/>
            <ExcelColumn label="Identificacion" value="identificacion"/>
            <ExcelColumn label="Correo" value="correo"/>
        </ExcelSheet>
        </ExcelFile>

        <ExcelFile element={<button className='oculto' id={'excelPatente'} />}>
        <ExcelSheet data={dataSet} name="Proyecto">
            <ExcelColumn label="Titulo de la Patente" value="tituloPatente"/>
            <ExcelColumn label="Año" value="anioPatente"/>
            <ExcelColumn label="Gaceta Industrialo" value="gacetaPatente"/>
            <ExcelColumn label="Numero de la Patente" value="numeroPatente"/>
            <ExcelColumn label="Pais" value="paisPatente"/>
            <ExcelColumn label="Institución" value="institucionPatente"/>
            <ExcelColumn label="Tipo de Patente" value="tipoPatente"/>
            <ExcelColumn label="Tipo de Patente Otro" value="tipoPatenteOtro"/>
        </ExcelSheet>
        <ExcelSheet data={dataSetParticipantes} name="Participantes">
            <ExcelColumn label="Primer Nombre" value="primer_nombre"/>
            <ExcelColumn label="Segundo Nombre" value="segundo_nombre"/>
            <ExcelColumn label="Primer Apellido" value="primer_apellido"/>
            <ExcelColumn label="Segundo Apellido" value="segundo_apellido"/>
            <ExcelColumn label="Identificacion" value="identificacion"/>
            <ExcelColumn label="Correo" value="correo"/>
        </ExcelSheet>
        </ExcelFile>
    </div>
  )
}

const getInfoRowUsersBibliografica = async (id) => {
  return new Promise(resolve => {
      Funciones.consulta(`api/v1.0/investigacion/listaGestionSemilleroAutores?gestion_investigacion=${id}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
  })
}

const ModalShowInformationProduBiblio = (props) =>{
  return (
    <div>
      <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModal} onClose={() => props.setOpenModal(false)}>
        <AppBarModal titulo={'¡ Mas Informacion !'} mostrarModal={() => props.setOpenModal(false)} titulo_accion="" accion="" />
        <DialogContent>
          <DetallesProduBiblio datos={props.data}/>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const CardsBibliograficaGestionSemillero = (props) =>{
  const isInitialMount = React.useRef(true);
    const [tiposProductos, setTiposProductos] = React.useState([]);
    const [cargandoCards, setCargandoCards] = React.useState(true);
    const classes = useStyles()
    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            const obtenerProcesos = async () => {
                obtenerTiposDeProductos()
                
            }
            obtenerProcesos()
        }
    })
    const obtenerTiposDeProductos = async () => {
      try{
          let data = await Funciones.obtenerValores(Helper.TIPOS_DE_PROD_BIBLIO_INVES)
          setTiposProductos(data)
          setCargandoCards(false)
      }catch(err){
        console.log(err)
      } 
    }

    function laImagen (item) {
      if(item == "Inves_tipo_biblio_articulo"){
        return articleInvestigacion
      }else if(item == "Inves_tipo_biblio_capituloLib"){
        return chapterInvestigacion
      }else if(item == "Inves_tipo_biblio_libro"){
        return bookInvestigacion
      }else if(item == "Inves_tipo_biblio_patente"){
        return patentInvestigacion
      }
    }

  return(
    cargandoCards == true ? <div> <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> <LinearProgress /></div> : 
    <Grid container direction={'row'} spacing={2} style={{ width: "100%" }}>
      {tiposProductos.map((item, index) => (
        <Grid key={index} item xs={12} sm={6}>
          <Paper className={classes.escalar} elevation={3} onClick={() => props.changeViewToListRealBibliografica(item)}>
              <AccionesRapidas imagen={laImagen(item.codigo)} titulo={item.valord} sub_titulo='' des_sub_titulo='' />
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}

const ListProyectosGestionSemillero = (props) =>{
  const isInitialMount = React.useRef(true);
  const [proyectos, setProyectos] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false)
  const [data, setData] = React.useState([])
  const [cargandoLista, setCargandoLista] = React.useState(true);
  const [dataUsers, setDataUsers] = React.useState(false)
  const [openModalUsers, setOpenModalUsers] = React.useState(false);
  const [dataEdit, setDataEdit] = React.useState([])
  const [openModalEdit, setOpenModalEdit] = React.useState(false)
  const [dataSet, setDataSet] = React.useState([])
  const [dataSetParticipantes, setDataSetParticipantes] = React.useState([])
  const [openModalFiltrar, setOpenModalFiltrar] = React.useState(false)
  const [ageFilter, setAgeFilter] = React.useState(new Date())
  const [userFilter, setUserFilter] = React.useState({nombre: ''})
  const classes = useStyles()
  React.useEffect( () => {
    //Obtengo los prceosos activos de la persona activa.
    if(isInitialMount.current){
        isInitialMount.current = false
        const obtenerProcesos = async () => {
          let data = await obtenerEventosGestionSemillero();
          console.log(data);
          setProyectos(data)

          setCargandoLista(false)
        }
        obtenerProcesos()
    }
  })

  const actions = (row) => {
  
    const eventoDetalle = (row) => {

      setData(row)
      setOpenModal(true)
    }

    const eventoDetalleParticipantes = (row) =>{

      setDataUsers(row)
      setOpenModalUsers(true)
    }

    const editar = (row) =>{

      setDataEdit(row)
      setOpenModalEdit(true)
    }

    async function ReporteExcel (row) {
      const obtenerTiposDePatentes = async () => {
        try{
            let data = await getInfoRowUsersProyectos(row.id)
            data = data.map((item)=>({
              id: item.participante.id,
              primer_nombre: `${item.participante.primer_nombre}`,
              segundo_nombre: `${item.participante.segundo_nombre}`,
              primer_apellido: `${item.participante.primer_apellido}`,
              segundo_apellido: `${item.participante.segundo_apellido}`,
              correo: `${item.participante.correo}`,
              identificacion: `${item.participante.identificacion}`
            }))
            setDataSetParticipantes(data)
            setDataSet([
              {
                nombreDelProyecto: row.nombre,
                fechas: `Inicio: ${row.fecha_de_inicio} - Fin: ${row.fecha_de_fin}`
              }
            ])
        }catch(err){
          console.log(err)
        } 
      }
      
      await obtenerTiposDePatentes()
    
      document.getElementById('excelProyecto').click()
    }

    const detalle = (
      <div>
          <IconButton aria-label="done" className={classes.colorEmmma} onClick={() => eventoDetalle(row)}>
              <ImportContactsIcon/>
          </IconButton>
          <IconButton aria-label="done" className={classes.colorUsers} onClick={() => eventoDetalleParticipantes(row)}>
              <PeopleIcon/>
          </IconButton>
          <IconButton aria-label="done" className={classes.colorEdit} onClick={() => editar(row)}>
              <EditIcon/>
          </IconButton>
          <Tooltip title='Descargar' aria-label="add" >
          <IconButton aria-label="done" className={classes.colorEdit} onClick={() => ReporteExcel(row)}>
              <GetAppIcon/>
          </IconButton>
          </Tooltip>
      </div>
    );
    return detalle

  }



  const obtenerEventosGestionSemillero = async () => {
    return new Promise(resolve => {
        Funciones.consulta(`api/v1.0/investigacion/listaGestionSemillero?semillero__id=${props.semiActual.id}&tipo__codigo=${props.tipoSolicitudActual.codigo}`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        })
    })
  }

  const getInfoRowUsersProyectos = async (id) => {
    return new Promise(resolve => {
        Funciones.consulta(`api/v1.0/investigacion/listaGestionSemilleroAutores?gestion_investigacion=${id}`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        })
    })
  }

  const aplicarFiltros = async () =>{
    setCargandoLista(true)
    let age = moment(ageFilter).format('YYYY')
    let filtro = ''
    if(userFilter.nombre == ''){
      filtro = `&anio=${age}`
    }else{
      filtro = `Autor_investigacion__participante=${userFilter.id}&anio=${age}`
    }
    const obtenerProcesos = async () => {
      let data = await obtenerEventosGestionSemilleroFilter(filtro);
      setProyectos(data)
      setOpenModalFiltrar(false)
      setCargandoLista(false)
    }
    obtenerProcesos()
  }

  const obtenerEventosGestionSemilleroFilter = async (f) => {
    return new Promise(resolve => {
        Funciones.consulta(`api/v1.0/investigacion/listaGestionSemilleroFilter?semillero__id=${props.semiActual.id}&tipo__codigo=${props.tipoSolicitudActual.codigo}&${f}`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        })
    })
  }

  return (
    cargandoLista == true ? <div> <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> <LinearProgress /></div> :
      <div>
        <ListarDatos
            id="tbl_listar_proyectos"
            descargar="EMMA - PROYECTOS - INVESTIGACION"
            datos={proyectos}
            opciones={true}
            //agregar={true}
            buscar={true}
            filtrar={true}
            actfiltrar={() => setOpenModalFiltrar(true)}
            //actAgregar={() => setopenModalAddParti(true)}
            css={{
            list: { padding: '0px 0px 20px 0px' },
            appBar: { padding: '0px 0px 0px 0px' },
            }}
            acciones={(row) => actions(row)}
            titulo={"Lista de Productos"}
            fila_principal={row => `${row.nombre}`}
            filas={[
              {
                'mostrar': (row) => `${row.nombre}`,
                'id': 'Nombre', 'enLista': false
              },
              
              {
                  'mostrar': (row) => `Inicio: ${row.fecha_de_inicio} - Fin: ${row.fecha_de_fin}`,
                  'id': 'Fecha de Inicio y Fin', 'enLista': true
              },
              {
                'mostrar': (row) => `${row.Autor_investigacion.map((item)=>{
                  return ` ${item.participante.primer_nombre} ${item.participante.primer_apellido}`
                })}`,
                'id': 'Participantes', 'enLista': false
              },
            ]}
        />

        {openModal == true ? <ModalShowInformationProyectos 
          data={data}
          openModal={openModal}
          setOpenModal={setOpenModal}
        /> : ''}

        {openModalUsers == true ? <ModalShowInformationForUsers 
          data={dataUsers}
          openModal={openModalUsers}
          setOpenModal={setOpenModalUsers}
        /> : ''}
        {openModalEdit == true ? <SemillerosGestionEdit 
          data={dataEdit}
          openModal={openModalEdit}
          setOpenModal={setOpenModalEdit}
        /> : ''}

        {openModalFiltrar == true ? <FiltrarEventosProduBiblio
          openModal={openModalFiltrar}
          setOpenModal={setOpenModalFiltrar}

          ageFilter={ageFilter}
          setAgeFilter={setAgeFilter}

          aplicarFiltros={aplicarFiltros}

          profesor={userFilter}
          setProfesor={setUserFilter}
        /> : ''
        }
        <ExcelFile element={<button className='oculto' id={'excelProyecto'} />}>
        <ExcelSheet data={dataSet} name="Proyecto">
            <ExcelColumn label="Nombre del Proyecto" value="nombreDelProyecto"/>
            <ExcelColumn label="Fecha de Inicio y Fin" value="fechas"/>
        </ExcelSheet>
        <ExcelSheet data={dataSetParticipantes} name="Participantes">
            <ExcelColumn label="Primer Nombre" value="primer_nombre"/>
            <ExcelColumn label="Segundo Nombre" value="segundo_nombre"/>
            <ExcelColumn label="Primer Apellido" value="primer_apellido"/>
            <ExcelColumn label="Segundo Apellido" value="segundo_apellido"/>
            <ExcelColumn label="Identificacion" value="identificacion"/>
            <ExcelColumn label="Correo" value="correo"/>
        </ExcelSheet>
        </ExcelFile>
    </div>
  )
}

const ModalShowInformationProyectos = (props) =>{
  return (
    <div>
      <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModal} onClose={() => props.setOpenModal(false)}>
        <AppBarModal titulo={'¡ Mas Informacion !'} mostrarModal={() => props.setOpenModal(false)} titulo_accion="" accion="" />
        <DialogContent>
          <DetallesProyectos datos={props.data}/>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const ListEventosGestionSemillero = (props) =>{
  const isInitialMount = React.useRef(true);
  const [eventos, setEventos] = React.useState([]);
  const [cargandoLista, setCargandoLista] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [dataUsers, setDataUsers] = React.useState(false)
  const [openModalUsers, setOpenModalUsers] = React.useState(false);
  const [dataEdit, setDataEdit] = React.useState([])
  const [openModalEdit, setOpenModalEdit] = React.useState(false)
  const [dataSet, setDataSet] = React.useState([])
  const [dataSetParticipantes, setDataSetParticipantes] = React.useState([])
  const [openModalFiltrar, setOpenModalFiltrar] = React.useState(false)
  const [ageFilter, setAgeFilter] = React.useState(new Date())
  const [userFilter, setUserFilter] = React.useState({nombre: ''})
  const classes = useStyles()
  
  React.useEffect( () => {
    //Obtengo los prceosos activos de la persona activa.
    if(isInitialMount.current){
        isInitialMount.current = false
        const obtenerProcesos = async () => {
          let data = await obtenerEventosGestionSemillero();

          setEventos(data)
          setCargandoLista(false)
        }
        obtenerProcesos()
    }
})


  const obtenerEventosGestionSemillero = async () => {
    return new Promise(resolve => {
        Funciones.consulta(`api/v1.0/investigacion/listaGestionSemillero?semillero__id=${props.semiActual.id}&tipo__codigo=${props.tipoSolicitudActual.codigo}`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        })
    })
  }

  const actions = (row) => {
  
    const eventoDetalle = (row) => {

      setData(row)
      setOpenModal(true)
    }

    const eventoDetalleParticipantes = (row) =>{

      setDataUsers(row)
      setOpenModalUsers(true)
    }

    const editar = (row) =>{

      setDataEdit(row)
      setOpenModalEdit(true)
    }

    async function ReporteExcel (row) {
      const obtenerTiposDePatentes = async () => {
        try{
            let data = await getInfoRowUsersEventos(row.id)
            data = data.map((item)=>({
              id: item.participante.id,
              primer_nombre: `${item.participante.primer_nombre}`,
              segundo_nombre: `${item.participante.segundo_nombre}`,
              primer_apellido: `${item.participante.primer_apellido}`,
              segundo_apellido: `${item.participante.segundo_apellido}`,
              correo: `${item.participante.correo}`,
              identificacion: `${item.participante.identificacion}`,
              modalidadParticipacion: item.modalidad_de_participacion.nombre,
            }))
            setDataSetParticipantes(data)
            setDataSet([
              {
                nombreDelEvento: row.nombre,
                anioEvento: row.anio,
                departamentoEvento: row.departamento.nombre,
                ciudadEvento: row.ciudad.nombre,
                fechas: `Inicio: ${row.fecha_de_inicio} - Fin: ${row.fecha_de_fin}`,
                ambitoEvento: row.ambito.nombre
              }
            ])
        }catch(err){
          console.log(err)
        } 
      }
      
      await obtenerTiposDePatentes()
    
      document.getElementById('excelReporte').click()
    }

    const detalle = (
      <div>
        <Tooltip title='Informacion' aria-label="add" >
          <IconButton aria-label="done" className={classes.colorEmmma} onClick={() => eventoDetalle(row)}>
              <ImportContactsIcon/>
          </IconButton>
          </Tooltip>
          <Tooltip title='Participantes' aria-label="add" >
          <IconButton aria-label="done" className={classes.colorUsers} onClick={() => eventoDetalleParticipantes(row)}>
              <PeopleIcon/>
          </IconButton>
          </Tooltip>
          {props.profesor == true ? 
          <Tooltip title='Editar' aria-label="add" > 
          <IconButton aria-label="done" className={classes.colorEdit} onClick={() => editar(row)}>
              <EditIcon/>
          </IconButton>
          </Tooltip>
          :''}
          {props.profesor == true ? 
          <Tooltip title='Descargar' aria-label="add" >
          <IconButton aria-label="done" className={classes.colorEdit} onClick={() => ReporteExcel(row)}>
              <GetAppIcon/>
          </IconButton>
          </Tooltip>
          :''}
      </div>
    );
    return detalle

  }

  const aplicarFiltros = async () =>{
    setCargandoLista(true)
    let age = moment(ageFilter).format('YYYY')
    let filtro = ''
    if(userFilter.nombre == ''){
      filtro = `anio=${age}`
    }else{
      filtro = `Autor_investigacion__participante=${userFilter.id}&anio=${age}`
    }
    const obtenerProcesos = async () => {
      let data = await obtenerEventosGestionSemilleroFilter(filtro);
      setEventos(data)
      setOpenModalFiltrar(false)
      setCargandoLista(false)
    }
    obtenerProcesos()
  }

  const obtenerEventosGestionSemilleroFilter = async (f) => {
    return new Promise(resolve => {
        Funciones.consulta(`api/v1.0/investigacion/listaGestionSemilleroFilter?semillero__id=${props.semiActual.id}&tipo__codigo=${props.tipoSolicitudActual.codigo}&${f}`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        })
    })
  }

  return (
    cargandoLista == true ? <div> <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> <LinearProgress /></div> :
      <div>
        <ListarDatos
            id="tbl_listar_eventos"
            descargar="EMMA - EVENTOS - INVESTIGACION"
            datos={eventos}
            opciones={true}
            buscar={false}
            filtrar={true}
            actfiltrar={() => setOpenModalFiltrar(true)}
            css={{
            list: { padding: '0px 0px 20px 0px' },
            appBar: { padding: '0px 0px 0px 0px' },
            }}
            acciones={(row) => actions(row)}
            titulo={"Lista de Productos"}
            fila_principal={row => `${row.nombre}`}
            filas={[
              {
                'mostrar': (row) => `${row.nombre}`,
                'id': 'Nombre del Evento', 'enLista': false
              },
              {
                'mostrar': (row) => `${row.anio}`,
                'id': 'Año del Evento', 'enLista': false
              },
              {
                'mostrar': (row) => `${row.departamento.nombre}`,
                'id': 'Departamento del Evento', 'enLista': false
              },
              {
                'mostrar': (row) => `${row.ciudad.nombre}`,
                'id': 'Ciudad del Evento', 'enLista': false
              },
              {
                'mostrar': (row) => `${row.ambito.nombre}`,
                'id': 'Ambito del Evento', 'enLista': false
              },
              {
                  'mostrar': (row) => `Inicio: ${row.fecha_de_inicio} - Fin: ${row.fecha_de_fin}`,
                  'id': 'Fecha de Inicio y Fin', 'enLista': true
              },
              {
                'mostrar': (row) => `${row.Autor_investigacion.map((item)=>{
                  return ` ${item.participante.primer_nombre} ${item.participante.primer_apellido}`
                })}`,
                'id': 'Participantes', 'enLista': false
              },
            ]}
        />
        {openModal == true ? <ModalShowInformation 
          data={data}
          openModal={openModal}
          setOpenModal={setOpenModal}
        /> : ''}
        {openModalUsers == true ? <ModalShowInformationForUsers 
          data={dataUsers}
          openModal={openModalUsers}
          setOpenModal={setOpenModalUsers}
        /> : ''}
        {openModalEdit == true ? <SemillerosGestionEdit 
          data={dataEdit}
          openModal={openModalEdit}
          setOpenModal={setOpenModalEdit}
        /> : ''}

        {openModalFiltrar == true ? <FiltrarEventosProduBiblio
          openModal={openModalFiltrar}
          setOpenModal={setOpenModalFiltrar}

          ageFilter={ageFilter}
          setAgeFilter={setAgeFilter}

          aplicarFiltros={aplicarFiltros}

          profesor={userFilter}
          setProfesor={setUserFilter}
        /> : ''
        }

        <ExcelFile element={<button className='oculto' id={'excelReporte'} />}>
        <ExcelSheet data={dataSet} name="Evento">
            <ExcelColumn label="Nombre del Evento" value="nombreDelEvento"/>
            <ExcelColumn label="Año del Evento" value="anioEvento"/>
            <ExcelColumn label="Departamento del Evento" value="departamentoEvento"/>
            <ExcelColumn label="Ciudad del Evento" value="ciudadEvento"/>
            <ExcelColumn label="Ciudad del Evento" value="ciudadEvento"/>
            <ExcelColumn label="Fecha de Inicio y Fin" value="fechas"/>
            <ExcelColumn label="Ambito del Evento" value="ambitoEvento"/>
        </ExcelSheet>
        <ExcelSheet data={dataSetParticipantes} name="Participantes">
            <ExcelColumn label="Primer Nombre" value="primer_nombre"/>
            <ExcelColumn label="Segundo Nombre" value="segundo_nombre"/>
            <ExcelColumn label="Primer Apellido" value="primer_apellido"/>
            <ExcelColumn label="Segundo Apellido" value="segundo_apellido"/>
            <ExcelColumn label="Identificacion" value="identificacion"/>
            <ExcelColumn label="Correo" value="correo"/>
            <ExcelColumn label="Modalidad de Participacion" value="modalidadParticipacion"/>
        </ExcelSheet>
        </ExcelFile>
    </div>
  )
}

const FiltrarEventosProduBiblio = (props) =>{
  const [openModal, setOpenModal] = React.useState(false)
  const handleClickFilter = async () =>{
  }
  return(
    <>
    <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModal} onClose={() => props.setOpenModal(false)}>
        <AppBarModal titulo={'¡ Aplicar Filtros !'} mostrarModal={() => props.setOpenModal(false)} titulo_accion="" accion="" />
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                  <Fragment>
                  <DatePicker
                      views={["year"]}
                      label="Año"
                      value={props.ageFilter}
                      fullWidth
                      onChange={props.setAgeFilter}
                      animateYearScrolling
                  />
                  </Fragment> 
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <InputBuscar
                  id='profesor'
                  label='Usuario'
                  placeholder='Clic aquí para buscar'
                  value={props.profesor.nombre}
                  callback={() => setOpenModal(true)} />
              </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.aplicarFiltros}>
              Filtrar
          </Button>
        </DialogActions>
      </Dialog>
      {openModal == true ? 
        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={openModal} onClose={() => setOpenModal(false)}>
        <AppBarModal titulo={'¡ Buscar Profesor !'} mostrarModal={() => setOpenModal(false)} titulo_accion="CERRAR" accion={() => setOpenModal(false)} />
        <DialogContent style={{ padding: '0' }} className='scroll'>
            <BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido, identificacion }) => {
                props.setProfesor({id: id, nombre:  `${primer_nombre} ${primer_apellido} ${segundo_apellido}`, identificacion: identificacion})
                setOpenModal(false)
            }} />
        </DialogContent>
        <DialogActions>
                <Button variant="contained" color="primary" style={{color: "#fff"}} onClick={() => setOpenModal(false)}>
                    Cancelar
                </Button>
        </DialogActions>
        </Dialog>
    : ''}
    </>
  )
}

const getInfoRowUsersEventos = async (id) => {
  return new Promise(resolve => {
      Funciones.consulta(`api/v1.0/investigacion/listaGestionSemilleroAutores?gestion_investigacion=${id}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
  })
}


const ModalShowInformation = (props) =>{
  return (
    <div>
      <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModal} onClose={() => props.setOpenModal(false)}>
        <AppBarModal titulo={'¡ Mas Informacion !'} mostrarModal={() => props.setOpenModal(false)} titulo_accion="" accion="" />
        <DialogContent>
          <DetalleEventos datos={props.data}/>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const ModalShowInformationForUsers = (props) =>{
  const isInitialMount = React.useRef(true);
  const [data, setData] = React.useState([])
  const [cargandoList, setCargandoList] = React.useState(true)
  
  React.useEffect( () => {
    //Obtengo los prceosos activos de la persona activa.
    if(isInitialMount.current){
        isInitialMount.current = false
        const obtenerProcesos = async () => {
          let datos = await obtenerListaGestionTecTecno()

          setData(datos)
          setCargandoList(false)
        }
        obtenerProcesos()
    }
  })

  const obtenerListaGestionTecTecno = async () => {
    return new Promise(resolve => {
        Funciones.consulta(`api/v1.0/investigacion/listaGestionSemilleroAutores?gestion_investigacion=${props.data.id}`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        })
    })
  }

  return (
    <div>
      <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModal} onClose={() => props.setOpenModal(false)}>
        <AppBarModal titulo={'¡ Participantes !'} mostrarModal={() => props.setOpenModal(false)} titulo_accion="" accion="" />
        <DialogContent>
          {cargandoList == true ? <div> <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> <LinearProgress /></div> 
          :
          <DetalleAutores datos={data}/>}
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const CardsTipoProductoProduccionTecnica = (props) =>{
  const isInitialMount = React.useRef(true);
  const [tiposProductos, setTiposProductos] = React.useState([]);
  const [cargandoCards, setCargandoCards] = React.useState(true);

  const classes = useStyles()
  React.useEffect( () => {
      //Obtengo los prceosos activos de la persona activa.
      if(isInitialMount.current){
          isInitialMount.current = false
          const obtenerProcesos = async () => {
            obtenerPRODUCTO()
              
          }
          obtenerProcesos()
      }
  })

  const obtenerPRODUCTO = async () =>{
    let permisos = await Funciones.obtenerPermisosValor(props.tipoProduccionTecnica.id);

    permisos = permisos.map(({ secundario }) => ({
        value: secundario.id,
        label: secundario.nombre,
        codigo: secundario.codigo
    }));
    props.setTipoProductoTec(permisos)

    setCargandoCards(false)
  }

  return (
    cargandoCards == true ? <div> <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> <LinearProgress /></div> : 
    <Grid container direction={'row'} spacing={2} style={{ width: "100%" }}>
      {props.tipoProductoTec.map((item, index) => (
        <Grid key={index} item xs={12} sm={6}>
          <Paper className={classes.escalar} elevation={3} onClick={() => props.changeViewToListReal(item)}>
              <AccionesRapidas imagen={revisar} titulo={item.label} sub_titulo='' des_sub_titulo='' />
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}

const ListTipoProduccionTecnica = (props) =>{
  const isInitialMount = React.useRef(true);
  const [tiposProductos, setTiposProductos] = React.useState();
  const [cargandoCards, setCargandoCards] = React.useState(true);
  const [data, setData] = React.useState(true);
  const [cargandoLista, setCargandoLista] = React.useState(true)
  const [openModal, setOpenModal] = React.useState(false)
  const [producto, setProducto] = React.useState([])
  const [dataUsers, setDataUsers] = React.useState(false)
  const [openModalUsers, setOpenModalUsers] = React.useState(false);
  const [dataEdit, setDataEdit] = React.useState([])
  const [modalEdit, setModalEdit] = React.useState(false)
  const [dataSet, setDataSet] = React.useState([])
  const [openModalFiltrar, setOpenModalFiltrar] = React.useState(false)
  const [ageFilter, setAgeFilter] = React.useState(new Date())
  const [userFilter, setUserFilter] = React.useState({nombre: ''})
  const [dataSetParticipantes, setDataSetParticipantes] = React.useState([])
  const classes = useStyles()

  React.useEffect( () => {
      //Obtengo los prceosos activos de la persona activa.
      if(isInitialMount.current){
          isInitialMount.current = false
          const obtenerProcesos = async () => {
            let fecha = new Date()
            const añoActual = fecha.getFullYear();
            const data = await obtenerListaGestionTecTecno(añoActual);

            setTiposProductos(data)
            setCargandoLista(false)
          }
          obtenerProcesos()
      }
  })


  const obtenerListaGestionTecTecno = async (añoActual) => {
    return new Promise(resolve => {
        Funciones.consulta(`api/v1.0/investigacion/listaGestionTecTecno?semillero__id=${props.semiActual.id}&tipoSolicitud__id=${props.tipoSolicitudActual.id}&tipoProducto__id=${props.tipoProduccionTecnica.id}&producto__id=${props.tipoDeProducto.value}&anio=${añoActual}`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        })
    })
  }

  const actions = (row) => {
  
    const eventoDetalle = (row) => {
      setData(row)

      setProducto(props.tipoDeProducto)
      setOpenModal(true)
    }

    const eventoDetalleParticipantes = (row) =>{

      setDataUsers(row)
      setOpenModalUsers(true)
    }

    const editar = (row) =>{

      setDataEdit(row)
      setModalEdit(true)
    }

    async function ReporteExcel (row) {
      setCargandoLista(true)

      if(row.key.producto.codigo == "Inves_tipo_tec_pro_diseñoIndustrial"){
        
        const obtenerTiposDePatentes = async () => {
          try{
            setDataSet([row])
            let data = row.autores.map((item)=>({
              id: item.participante.id,
              primer_nombre: `${item.participante.primer_nombre}`,
              segundo_nombre: `${item.participante.segundo_nombre}`,
              primer_apellido: `${item.participante.primer_apellido}`,
              segundo_apellido: `${item.participante.segundo_apellido}`,
              correo: `${item.participante.correo}`,
              identificacion: `${item.participante.identificacion}`,
            }))
            setDataSetParticipantes(data)
          }catch(err){
            console.log(err)
          }
        }
        await obtenerTiposDePatentes()
        document.getElementById('excelDiseñoIndustrial').click()
        setCargandoLista(false)
      }else if(row.key.producto.codigo == "Inves_tipo_tec_pro_prototipo"){
        const obtenerTiposDePatentes = async () => {
          try{
            setDataSet([row])
            let data = row.autores.map((item)=>({
              id: item.participante.id,
              primer_nombre: `${item.participante.primer_nombre}`,
              segundo_nombre: `${item.participante.segundo_nombre}`,
              primer_apellido: `${item.participante.primer_apellido}`,
              segundo_apellido: `${item.participante.segundo_apellido}`,
              correo: `${item.participante.correo}`,
              identificacion: `${item.participante.identificacion}`,
            }))
            setDataSetParticipantes(data)
          }catch(err){
            console.log(err)
          }
        }
        await obtenerTiposDePatentes()
        document.getElementById('excelPrototipo').click()
        setCargandoLista(false)
      }else if(row.key.producto.codigo == "Inves_tipo_tec_pro_software"){
        const obtenerTiposDePatentes = async () => {
          try{
            setDataSet([row])
            let data = row.autores.map((item)=>({
              id: item.participante.id,
              primer_nombre: `${item.participante.primer_nombre}`,
              segundo_nombre: `${item.participante.segundo_nombre}`,
              primer_apellido: `${item.participante.primer_apellido}`,
              segundo_apellido: `${item.participante.segundo_apellido}`,
              correo: `${item.participante.correo}`,
              identificacion: `${item.participante.identificacion}`,
            }))
            setDataSetParticipantes(data)
          }catch(err){
            console.log(err)
          }
        }
        await obtenerTiposDePatentes()
        document.getElementById('excelSoftware').click()
        setCargandoLista(false)
      }else if(row.key.producto.codigo == "Inves_tipo_tec_pro_signo_distintivo"){
        const obtenerTiposDePatentes = async () => {
          try{
            setDataSet([row])
            let data = row.autores.map((item)=>({
              id: item.participante.id,
              primer_nombre: `${item.participante.primer_nombre}`,
              segundo_nombre: `${item.participante.segundo_nombre}`,
              primer_apellido: `${item.participante.primer_apellido}`,
              segundo_apellido: `${item.participante.segundo_apellido}`,
              correo: `${item.participante.correo}`,
              identificacion: `${item.participante.identificacion}`,
            }))
            setDataSetParticipantes(data)
          }catch(err){
            console.log(err)
          }
        }
        await obtenerTiposDePatentes()
        document.getElementById('excelSignoDistintivo').click()
        setCargandoLista(false)
      }else if(row.key.producto.codigo == "Inves_tipo_tec_pro_empBaseTec"){
        const obtenerTiposDePatentes = async () => {
          try{
            setDataSet([row])
            let data = row.autores.map((item)=>({
              id: item.participante.id,
              primer_nombre: `${item.participante.primer_nombre}`,
              segundo_nombre: `${item.participante.segundo_nombre}`,
              primer_apellido: `${item.participante.primer_apellido}`,
              segundo_apellido: `${item.participante.segundo_apellido}`,
              correo: `${item.participante.correo}`,
              identificacion: `${item.participante.identificacion}`,
            }))
            setDataSetParticipantes(data)
          }catch(err){
            console.log(err)
          }
        }
        await obtenerTiposDePatentes()
        document.getElementById('excelBaseTecnologica').click()
        setCargandoLista(false)
      }else if(row.key.producto.codigo == "Inves_tipo_tec_pro_empCreaCul"){
        const obtenerTiposDePatentes = async () => {
          try{
            setDataSet([row])
            let data = row.autores.map((item)=>({
              id: item.participante.id,
              primer_nombre: `${item.participante.primer_nombre}`,
              segundo_nombre: `${item.participante.segundo_nombre}`,
              primer_apellido: `${item.participante.primer_apellido}`,
              segundo_apellido: `${item.participante.segundo_apellido}`,
              correo: `${item.participante.correo}`,
              identificacion: `${item.participante.identificacion}`,
            }))
            setDataSetParticipantes(data)
          }catch(err){
            console.log(err)
          }
        }
        await obtenerTiposDePatentes()
        document.getElementById('excelEmpresaCultural').click()
        setCargandoLista(false)
      } else if(row.key.producto.codigo == "Inves_tipo_tec_pro_innProProSer"){
        const obtenerTiposDePatentes = async () => {
          try{
            setDataSet([row])
            let data = row.autores.map((item)=>({
              id: item.participante.id,
              primer_nombre: `${item.participante.primer_nombre}`,
              segundo_nombre: `${item.participante.segundo_nombre}`,
              primer_apellido: `${item.participante.primer_apellido}`,
              segundo_apellido: `${item.participante.segundo_apellido}`,
              correo: `${item.participante.correo}`,
              identificacion: `${item.participante.identificacion}`,
            }))
            setDataSetParticipantes(data)
          }catch(err){
            console.log(err)
          }
        }
        await obtenerTiposDePatentes()
        document.getElementById('excelInnoProPro').click()
        setCargandoLista(false)
      }else if(row.key.producto.codigo == "Inves_tipo_tec_pro_innGenGesEmp"){
        const obtenerTiposDePatentes = async () => {
          try{
            setDataSet([row])
            let data = row.autores.map((item)=>({
              id: item.participante.id,
              primer_nombre: `${item.participante.primer_nombre}`,
              segundo_nombre: `${item.participante.segundo_nombre}`,
              primer_apellido: `${item.participante.primer_apellido}`,
              segundo_apellido: `${item.participante.segundo_apellido}`,
              correo: `${item.participante.correo}`,
              identificacion: `${item.participante.identificacion}`,
            }))
            setDataSetParticipantes(data)
          }catch(err){
            console.log(err)
          }
        }
        await obtenerTiposDePatentes()
        document.getElementById('excelGesEmp').click()
        setCargandoLista(false)
      }else if(row.key.producto.codigo == "Inves_tipo_tec_pro_eveArt"){
        const obtenerTiposDePatentes = async () => {
          try{
            setDataSet([row])
            let data = row.autores.map((item)=>({
              id: item.participante.id,
              primer_nombre: `${item.participante.primer_nombre}`,
              segundo_nombre: `${item.participante.segundo_nombre}`,
              primer_apellido: `${item.participante.primer_apellido}`,
              segundo_apellido: `${item.participante.segundo_apellido}`,
              correo: `${item.participante.correo}`,
              identificacion: `${item.participante.identificacion}`,
            }))
            setDataSetParticipantes(data)
          }catch(err){
            console.log(err)
          }
        }
        await obtenerTiposDePatentes()
        document.getElementById('excelEveArte').click()
        setCargandoLista(false)
      }else if(row.key.producto.codigo == "Inves_tipo_tec_pro_obrPro"){
        const obtenerTiposDePatentes = async () => {
          try{
            setDataSet([row])
            let data = row.autores.map((item)=>({
              id: item.participante.id,
              primer_nombre: `${item.participante.primer_nombre}`,
              segundo_nombre: `${item.participante.segundo_nombre}`,
              primer_apellido: `${item.participante.primer_apellido}`,
              segundo_apellido: `${item.participante.segundo_apellido}`,
              correo: `${item.participante.correo}`,
              identificacion: `${item.participante.identificacion}`,
            }))
            setDataSetParticipantes(data)
          }catch(err){
            console.log(err)
          }
        }
        await obtenerTiposDePatentes()
        document.getElementById('excelObraPro').click()
        setCargandoLista(false)
      }else if(row.key.producto.codigo == "Inves_tipo_tec_pro_talCre"){
        const obtenerTiposDePatentes = async () => {
          try{
            setDataSet([row])
            let data = row.autores.map((item)=>({
              id: item.participante.id,
              primer_nombre: `${item.participante.primer_nombre}`,
              segundo_nombre: `${item.participante.segundo_nombre}`,
              primer_apellido: `${item.participante.primer_apellido}`,
              segundo_apellido: `${item.participante.segundo_apellido}`,
              correo: `${item.participante.correo}`,
              identificacion: `${item.participante.identificacion}`,
            }))
            setDataSetParticipantes(data)
          }catch(err){
            console.log(err)
          }
        }
        await obtenerTiposDePatentes()
        document.getElementById('tallerCrea').click()
        setCargandoLista(false)
      }
    
      
    }

    const detalle = (
      <div>
          <IconButton aria-label="done" className={classes.colorEmmma} onClick={() => eventoDetalle(row)}>
              <ImportContactsIcon/>
          </IconButton>
          <IconButton aria-label="done" className={classes.colorUsers} onClick={() => eventoDetalleParticipantes(row)}>
              <PeopleIcon/>
          </IconButton>
          <IconButton aria-label="done" className={classes.colorEdit} onClick={() => editar(row)}>
              <EditIcon/>
          </IconButton>
          <Tooltip title='Descargar' aria-label="add" >
          <IconButton aria-label="done" className={classes.colorEdit} onClick={() => ReporteExcel(row)}>
              <GetAppIcon/>
          </IconButton>
          </Tooltip>
      </div>
    );
    return detalle

  }

  const aplicarFiltros = async () =>{
    setCargandoLista(true)
    let age = moment(ageFilter).format('YYYY')
    let filtro = ''
    if(userFilter.nombre == ''){
      filtro = `anio=${age}`
    }else{
      filtro = `Autor_investigacion__participante=${userFilter.id}&anio=${age}`
    }
    const obtenerProcesos = async () => {
      let data = await obtenerListaGestionTecTecnoFilter(filtro);
      setTiposProductos(data)
      setOpenModalFiltrar(false)
      setCargandoLista(false)
    }
    obtenerProcesos()
  }

  const obtenerListaGestionTecTecnoFilter = async (f) => {
    return new Promise(resolve => {
        Funciones.consulta(`api/v1.0/investigacion/listaGestionTecTecno?semillero__id=${props.semiActual.id}&tipoSolicitud__id=${props.tipoSolicitudActual.id}&tipoProducto__id=${props.tipoProduccionTecnica.id}&producto__id=${props.tipoDeProducto.value}&${f}`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        })
    })
  }

  const fechaFormat = (item) =>{
    let anio = parseInt(moment(item).format('YYYY'))
    let mes = parseInt(moment(item).format('MM'))-1
    let dia = parseInt(moment(item).format('DD'))+1
    let fechaRegistroEmpresaCultural = new Date(anio, mes, dia)
    return moment(fechaRegistroEmpresaCultural).format('YYYY-MM-DD')
  }

  return(
    cargandoLista == true ? <div> 
      <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> <LinearProgress />
        <ExcelFile element={<button className='oculto' id={'excelDiseñoIndustrial'} />}>
          <ExcelSheet data={dataSet} name="Producto">
              <ExcelColumn label="Nombre" value="nombreDiseñoIndustrial"/>
              <ExcelColumn label="Institucion Financiadora" value="institucionFInandiadora"/>
              <ExcelColumn label="Fecha de Creacion" value="fechaCreacion"/>
              <ExcelColumn label="País" value="pais"/>
              <ExcelColumn label="Producto Tiene" value="tipoDiseñoPrototipoSelected"/>
              <ExcelColumn label="Fecha Obtencion del Registro" value="fechaRegistroDiseñoPrototipoTiene"/>
              <ExcelColumn label="No. Del Registro" value="noRegistroDiseñoPrototipoTiene"/>
              <ExcelColumn label="Titulo del Registro" value="tituloRegistroDiseñoPrototipoTiene"/>
          </ExcelSheet>
          <ExcelSheet data={dataSetParticipantes} name="Participantes">
            <ExcelColumn label="Primer Nombre" value="primer_nombre"/>
            <ExcelColumn label="Segundo Nombre" value="segundo_nombre"/>
            <ExcelColumn label="Primer Apellido" value="primer_apellido"/>
            <ExcelColumn label="Segundo Apellido" value="segundo_apellido"/>
            <ExcelColumn label="Identificacion" value="identificacion"/>
            <ExcelColumn label="Correo" value="correo"/>
          </ExcelSheet>
        </ExcelFile>
        <ExcelFile element={<button className='oculto' id={'excelPrototipo'} />}>
          <ExcelSheet data={dataSet} name="Producto">
              <ExcelColumn label="Nombre" value="nombrePrototipo"/>
              <ExcelColumn label="Institucion Financiadora" value="institucionFinanciadoraPrototipo"/>
              <ExcelColumn label="Fecha de Creacion" value="fechaCreacionPrototipo"/>
              <ExcelColumn label="País" value="paisPrototipo"/>
              <ExcelColumn label="Producto Tiene" value="tipoDiseñoPrototipoSelected"/>
              <ExcelColumn label="Fecha Obtencion del Registro" value="fechaRegistroDiseñoPrototipoTiene"/>
              <ExcelColumn label="No. Del Registro" value="noRegistroDiseñoPrototipoTiene"/>
              <ExcelColumn label="Titulo del Registro" value="tituloRegistroDiseñoPrototipoTiene"/>
              <ExcelColumn label="Tipo de Prototipo" value="tipoPrototipoSelected"/>
              <ExcelColumn label="Tipo de Prototipo Otro" value="otroTipoPrototipoSelected"/>
          </ExcelSheet>
          <ExcelSheet data={dataSetParticipantes} name="Participantes">
            <ExcelColumn label="Primer Nombre" value="primer_nombre"/>
            <ExcelColumn label="Segundo Nombre" value="segundo_nombre"/>
            <ExcelColumn label="Primer Apellido" value="primer_apellido"/>
            <ExcelColumn label="Segundo Apellido" value="segundo_apellido"/>
            <ExcelColumn label="Identificacion" value="identificacion"/>
            <ExcelColumn label="Correo" value="correo"/>
          </ExcelSheet>
        </ExcelFile>
        <ExcelFile element={<button className='oculto' id={'excelSoftware'} />}>
          <ExcelSheet data={dataSet} name="Producto">
              <ExcelColumn label="Nombre" value="nombreDelSoftware"/>
              <ExcelColumn label="Institucion Financiadora" value="institucionFinanciadoraSoftware"/>
              <ExcelColumn label="Fecha de Desarrollo" value="fechaDesarrolloSoftware"/>
              <ExcelColumn label="Fecha de Obtencion" value="fechaObtencionRegistroSoftware"/>
              <ExcelColumn label="No. Del Registro" value="noRegistroSoftware"/>
              <ExcelColumn label="País" value="paisSoftware"/>
              <ExcelColumn label="Titulo de Registro" value="tituloRegistroSoftware"/>
              <ExcelColumn label="Tipo de Software" value="tipoSoftwareSelected"/>
              <ExcelColumn label="Tipo de Software Otro" value="otroTipoSoftwareSelected"/>
          </ExcelSheet>
          <ExcelSheet data={dataSetParticipantes} name="Participantes">
            <ExcelColumn label="Primer Nombre" value="primer_nombre"/>
            <ExcelColumn label="Segundo Nombre" value="segundo_nombre"/>
            <ExcelColumn label="Primer Apellido" value="primer_apellido"/>
            <ExcelColumn label="Segundo Apellido" value="segundo_apellido"/>
            <ExcelColumn label="Identificacion" value="identificacion"/>
            <ExcelColumn label="Correo" value="correo"/>
          </ExcelSheet>
        </ExcelFile>
        <ExcelFile element={<button className='oculto' id={'excelSignoDistintivo'} />}>
          <ExcelSheet data={dataSet} name="Producto">
              <ExcelColumn label="Nombre" value="nombreTitularSignoDistintivo"/>
              <ExcelColumn label="Institucion Financiadora" value="institucionFinanciadoraSignoDistintivo"/>
              <ExcelColumn label="Fecha de Creacion" value="fechaCreacionSignoDistintivo"/>
              <ExcelColumn label="No. Del Registro" value="noRegistroSignoDistintivo"/>
              <ExcelColumn label="País" value="paisSignoDistintivo"/>
              <ExcelColumn label="Titulo de Registro" value="tituloRegistroSignoDistintivo"/>
              <ExcelColumn label="Tipo de Signo Distintivo" value="tipoSignoDistintivoSelected"/>
          </ExcelSheet>
          <ExcelSheet data={dataSetParticipantes} name="Participantes">
            <ExcelColumn label="Primer Nombre" value="primer_nombre"/>
            <ExcelColumn label="Segundo Nombre" value="segundo_nombre"/>
            <ExcelColumn label="Primer Apellido" value="primer_apellido"/>
            <ExcelColumn label="Segundo Apellido" value="segundo_apellido"/>
            <ExcelColumn label="Identificacion" value="identificacion"/>
            <ExcelColumn label="Correo" value="correo"/>
          </ExcelSheet>
        </ExcelFile>
        <ExcelFile element={<button className='oculto' id={'excelBaseTecnologica'} />}>
          <ExcelSheet data={dataSet} name="Producto">
              <ExcelColumn label="Nombre" value="nombreEmpresaBaseTecno"/>
              <ExcelColumn label="Fecha de Registro" value="fechaRegistroBaseTecno"/>
              <ExcelColumn label="Nit o Codigo Registro" value="nitCodigoRegistroBaseTecno"/>
              <ExcelColumn label="Titulo de Empresa" value="tipoEmpresaSelected"/>
          </ExcelSheet>
          <ExcelSheet data={dataSetParticipantes} name="Participantes">
            <ExcelColumn label="Primer Nombre" value="primer_nombre"/>
            <ExcelColumn label="Segundo Nombre" value="segundo_nombre"/>
            <ExcelColumn label="Primer Apellido" value="primer_apellido"/>
            <ExcelColumn label="Segundo Apellido" value="segundo_apellido"/>
            <ExcelColumn label="Identificacion" value="identificacion"/>
            <ExcelColumn label="Correo" value="correo"/>
          </ExcelSheet>
        </ExcelFile>
        <ExcelFile element={<button className='oculto' id={'excelInnoProPro'} />}>
          <ExcelSheet data={dataSet} name="Producto">
              <ExcelColumn label="Nombre" value="nombreInnovacionProceServi"/>
              <ExcelColumn label="Fecha de la Innovación" value="fechaInnovacionProceServi"/>
              <ExcelColumn label="Ins. para la que se realizó la Innovación" value="institucionRealizoInnovacionProceServi"/>
              <ExcelColumn label="Pais" value="paisProceServi"/>
              <ExcelColumn label="El Producto tiene" value="tipoDiseñoPrototipoSelected"/>
              <ExcelColumn label="Fecha de Obtención" value="fechaRegistroDiseñoPrototipoTiene"/>
              <ExcelColumn label="No. del Registro" value="noRegistroDiseñoPrototipoTiene"/>
              <ExcelColumn label="Titulo del Registro" value="tituloRegistroDiseñoPrototipoTiene"/>
          </ExcelSheet>
          <ExcelSheet data={dataSetParticipantes} name="Participantes">
            <ExcelColumn label="Primer Nombre" value="primer_nombre"/>
            <ExcelColumn label="Segundo Nombre" value="segundo_nombre"/>
            <ExcelColumn label="Primer Apellido" value="primer_apellido"/>
            <ExcelColumn label="Segundo Apellido" value="segundo_apellido"/>
            <ExcelColumn label="Identificacion" value="identificacion"/>
            <ExcelColumn label="Correo" value="correo"/>
          </ExcelSheet>
        </ExcelFile>
        <ExcelFile element={<button className='oculto' id={'excelGesEmp'} />}>
          <ExcelSheet data={dataSet} name="Producto">
              <ExcelColumn label="Nombre" value="nombreInnovacionGestionEmp"/>
              <ExcelColumn label="Fecha de la Innovación" value="fechaInnovacionGestionEmp"/>
              <ExcelColumn label="Ins. para la que se realizó la Innovación" value="institucionRealizoInnovacionGestionEmp"/>
              <ExcelColumn label="Pais" value="paisGestionEmp"/>
              <ExcelColumn label="El Producto Innovacion" value="tipoEmpresaSelected"/>
              <ExcelColumn label="El Producto tiene" value="tipoDiseñoPrototipoSelected"/>
              <ExcelColumn label="Fecha de Obtención" value="fechaRegistroDiseñoPrototipoTiene"/>
              <ExcelColumn label="No. del Registro" value="noRegistroDiseñoPrototipoTiene"/>
              <ExcelColumn label="Titulo del Registro" value="tituloRegistroDiseñoPrototipoTiene"/>
          </ExcelSheet>
          <ExcelSheet data={dataSetParticipantes} name="Participantes">
            <ExcelColumn label="Primer Nombre" value="primer_nombre"/>
            <ExcelColumn label="Segundo Nombre" value="segundo_nombre"/>
            <ExcelColumn label="Primer Apellido" value="primer_apellido"/>
            <ExcelColumn label="Segundo Apellido" value="segundo_apellido"/>
            <ExcelColumn label="Identificacion" value="identificacion"/>
            <ExcelColumn label="Correo" value="correo"/>
          </ExcelSheet>
        </ExcelFile>
        <ExcelFile element={<button className='oculto' id={'excelEveArte'} />}>
          <ExcelSheet data={dataSet} name="Producto">
              <ExcelColumn label="Nombre" value="nombreEventoArtistico"/>
              <ExcelColumn label="Pais" value="paisEventoArtistico"/>
              <ExcelColumn label="Fecha Inicio" value="fechaInicioEventoArtistico"/>
              <ExcelColumn label="Fecha Final" value="fechaFinalEventoArtistico"/>
              <ExcelColumn label="Ambito" value="tipoAmbitoSelected"/>
          </ExcelSheet>
          <ExcelSheet data={dataSetParticipantes} name="Participantes">
            <ExcelColumn label="Primer Nombre" value="primer_nombre"/>
            <ExcelColumn label="Segundo Nombre" value="segundo_nombre"/>
            <ExcelColumn label="Primer Apellido" value="primer_apellido"/>
            <ExcelColumn label="Segundo Apellido" value="segundo_apellido"/>
            <ExcelColumn label="Identificacion" value="identificacion"/>
            <ExcelColumn label="Correo" value="correo"/>
          </ExcelSheet>
        </ExcelFile>
        <ExcelFile element={<button className='oculto' id={'excelObraPro'} />}>
          <ExcelSheet data={dataSet} name="Producto">
              <ExcelColumn label="Nombre de la Obra" value="nombreObraProducto"/>
              <ExcelColumn label="Distinción Obtenida" value="distincionObtenidaObraProducto"/>
              <ExcelColumn label="Fecha de Creación" value="fechaCreacionObraProducto"/>
              <ExcelColumn label="Fecha de Presentación" value="fechaPresentacionObraProducto"/>
              <ExcelColumn label="Nombre del Espacio o Evento" value="nombreEspacioEventoObraProducto"/>
              <ExcelColumn label="País" value="paisObraProducto"/>

              <ExcelColumn label="Tipo de espacio o evento" value="TipoEspacioEvento"/>
              <ExcelColumn label="Tipo de espacio o evento Otro" value="otroTipoEspacioEvento"/>
              <ExcelColumn label="Disciplina o Ambito de Origen" value="disciplinaAmbitoSelected"/>
              <ExcelColumn label="Ambito" value="tipoAmbitoSelected"/>
          </ExcelSheet>
          <ExcelSheet data={dataSetParticipantes} name="Participantes">
            <ExcelColumn label="Primer Nombre" value="primer_nombre"/>
            <ExcelColumn label="Segundo Nombre" value="segundo_nombre"/>
            <ExcelColumn label="Primer Apellido" value="primer_apellido"/>
            <ExcelColumn label="Segundo Apellido" value="segundo_apellido"/>
            <ExcelColumn label="Identificacion" value="identificacion"/>
            <ExcelColumn label="Correo" value="correo"/>
          </ExcelSheet>
        </ExcelFile>
        <ExcelFile element={<button className='oculto' id={'tallerCrea'} />}>
          <ExcelSheet data={dataSet} name="Producto">
              <ExcelColumn label="Nombre del Evento" value="nombreEventoTalleresCreacion"/>
              <ExcelColumn label="Distinción Obtenida Creacion" value="distincionObtenidaTalleresCreacion"/>
              <ExcelColumn label="Fecha de Inicio Creacion" value="fechaInicioTalleresCreacion"/>
              <ExcelColumn label="Fecha Final Creacion" value="fechaFinalTalleresCreacion"/>
              <ExcelColumn label="Pais" value="paisTalleresCreacion"/>

              <ExcelColumn label="Tipo Evento" value="tipoEventoSelected"/>
              <ExcelColumn label="Ambito" value="tipoAmbitoSelected"/>
          </ExcelSheet>
          <ExcelSheet data={dataSetParticipantes} name="Participantes">
            <ExcelColumn label="Primer Nombre" value="primer_nombre"/>
            <ExcelColumn label="Segundo Nombre" value="segundo_nombre"/>
            <ExcelColumn label="Primer Apellido" value="primer_apellido"/>
            <ExcelColumn label="Segundo Apellido" value="segundo_apellido"/>
            <ExcelColumn label="Identificacion" value="identificacion"/>
            <ExcelColumn label="Correo" value="correo"/>
          </ExcelSheet>
        </ExcelFile>
        <ExcelFile element={<button className='oculto' id={'excelEmpresaCultural'} />}>
          <ExcelSheet data={dataSet} name="Producto">
              <ExcelColumn label="Disciplina Ambito" value="disciplinaAmbitoSelected"/>
              <ExcelColumn label="Fecha de Registro" value="fechaRegistroEmpresaCultural"/>
              <ExcelColumn label="Nit o Codigo" value="nitCodigoEmpresaCultural"/>
              <ExcelColumn label="Nombre" value="nombreEmpresaCreativaCultural"/>
          </ExcelSheet>
          <ExcelSheet data={dataSetParticipantes} name="Participantes">
            <ExcelColumn label="Primer Nombre" value="primer_nombre"/>
            <ExcelColumn label="Segundo Nombre" value="segundo_nombre"/>
            <ExcelColumn label="Primer Apellido" value="primer_apellido"/>
            <ExcelColumn label="Segundo Apellido" value="segundo_apellido"/>
            <ExcelColumn label="Identificacion" value="identificacion"/>
            <ExcelColumn label="Correo" value="correo"/>
          </ExcelSheet>
        </ExcelFile>
      </div> :
        <div>
        <ListarDatos
            id="tbl_listar_encargados"
            descargar="EMMA - TEC TECNO - INVESTIGACION"
            datos={tiposProductos}
            opciones={true}
            filtrar={true}
            actfiltrar={() => setOpenModalFiltrar(true)}
            //agregar={true}
            buscar={true}
            //actAgregar={() => setopenModalAddParti(true)}
            css={{
            list: { padding: '0px 0px 20px 0px' },
            appBar: { padding: '0px 0px 0px 0px' },
            }}
            acciones={(row) => actions(row)}
            titulo={"Lista de Productos"}
            fila_principal={row => {
              if(props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_diseñoIndustrial'){
                return `${row.nombreDiseñoIndustrial}`
              }else if(props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_software'){
                return `${row.nombreDelSoftware}`
              }else if(props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_prototipo'){
                return `${row.nombrePrototipo}`
              }else if(props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_signo_distintivo'){
                return `${row.tipoSignoDistintivoSelected}`
              }else if(props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_empBaseTec'){
                return `${row.nombreEmpresaBaseTecno}`
              }else if(props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_empCreaCul'){
                return `${row.nombreEmpresaCreativaCultural}`
              }else if(props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_innProProSer'){
                return `${row.nombreInnovacionProceServi}`
              }else if(props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_innGenGesEmp'){
                return `${row.nombreInnovacionGestionEmp}`
              }else if(props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_eveArt'){
                return `${row.nombreEventoArtistico}`
              }else if(props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_obrPro'){
                return `${row.nombreObraProducto}`
              }else if(props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_talCre'){
                return `${row.nombreEventoTalleresCreacion}`
              }
              else{
                return ''
              }
            }}
            filas={
              props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_diseñoIndustrial' ? 
              [
                {
                  'mostrar': (row) => `${moment(row.fechaCreacion).format('YYYY')}`,
                  'id': 'Fecha de Creacion', 'enLista': true
                },
                {
                  'mostrar': (row) => `${row.institucionFInandiadora}`,
                  'id': 'Institucion Financiadora', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.nombreDiseñoIndustrial}`,
                  'id': 'Nombre Diseño Industrial', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.pais}`,
                  'id': 'País', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.fechaRegistroDiseñoPrototipoTiene}`,
                  'id': 'Fecha de Obtencion del Registro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.noRegistroDiseñoPrototipoTiene}`,
                  'id': 'No. del Registro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.tituloRegistroDiseñoPrototipoTiene}`,
                  'id': 'Titulo del Registro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.tipoDiseñoPrototipoSelected}`,
                  'id': 'El Producto tiene', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.autores.map((item)=>{
                    return ` ${item.participante.primer_nombre} ${item.participante.primer_apellido}`
                  })}`,
                  'id': 'Autores', 'enLista': false
                },
              ]: props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_software' ? 
              [
                {
                  'mostrar': (row) => `${row.tipoSoftwareSelected}`,
                  'id': 'Tipo de Software ', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.otroTipoSoftwareSelected}`,
                  'id': 'Nombre del Otro ', 'enLista': false
                },
                {
                  'mostrar': (row) => `${moment(row.fechaDesarrolloSoftware).format('YYYY')}`,
                  'id': 'Fecha de Desarrollo', 'enLista': true
                },
                {
                  'mostrar': (row) => `${row.fechaObtencionRegistroSoftware}`,
                  'id': 'Fecha de Obtención del Registro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.institucionFinanciadoraSoftware}`,
                  'id': 'Institución Financiadora', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.noRegistroSoftware}`,
                  'id': 'No. del Registro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.nombreDelSoftware}`,
                  'id': 'Nombre del Software', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.paisSoftware}`,
                  'id': 'País', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.tituloRegistroSoftware}`,
                  'id': 'Título del Registro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.autores.map((item)=>{
                    return ` ${item.participante.primer_nombre} ${item.participante.primer_apellido}`
                  })}`,
                  'id': 'Autores', 'enLista': false
                },
              ]
              : props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_prototipo' ? 
              [
                {
                  'mostrar': (row) => `${moment(row.fechaCreacionPrototipo).format('YYYY')}`,
                  'id': 'Fecha de Creacion', 'enLista': true
                },
                {
                  'mostrar': (row) => `${row.tipoPrototipoSelected}`,
                  'id': 'Tipo de Prototipo', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.otroTipoPrototipoSelected}`,
                  'id': 'Tipo de Prototipo Otro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.tipoDiseñoPrototipoSelected}`,
                  'id': 'El Producto tiene', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.fechaRegistroDiseñoPrototipoTiene}`,
                  'id': 'Fecha de Obtención del Registro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.noRegistroDiseñoPrototipoTiene}`,
                  'id': 'No. del Registro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.tituloRegistroDiseñoPrototipoTiene}`,
                  'id': 'Titulo del Registro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.fechaCreacionPrototipo}`,
                  'id': 'Fecha de Creación', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.institucionFinanciadoraPrototipo}`,
                  'id': 'Institución Financiadora', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.nombrePrototipo}`,
                  'id': 'Nombre del Prototipo', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.paisPrototipo}`,
                  'id': 'País', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.autores.map((item)=>{
                    return ` ${item.participante.primer_nombre} ${item.participante.primer_apellido}`
                  })}`,
                  'id': 'Autores', 'enLista': false
                },
              ]: props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_signo_distintivo' ? 
              [
                {
                  'mostrar': (row) => `${fechaFormat(row.fechaCreacionSignoDistintivo)}`,
                  'id': 'Fecha de Creacion', 'enLista': true
                },
                {
                  'mostrar': (row) => `${row.tipoSignoDistintivoSelected}`,
                  'id': 'Tipo de Signo Distintivo', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.institucionFinanciadoraSignoDistintivo}`,
                  'id': 'Institución Financiadora', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.noRegistroSignoDistintivo}`,
                  'id': 'No. del Registro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.nombreTitularSignoDistintivo}`,
                  'id': 'Nombre del Titular', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.paisSignoDistintivo}`,
                  'id': 'País', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.tituloRegistroSignoDistintivo}`,
                  'id': 'Título del Registro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.autores.map((item)=>{
                    return ` ${item.participante.primer_nombre} ${item.participante.primer_apellido}`
                  })}`,
                  'id': 'Autores', 'enLista': false
                },
              ]: props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_empBaseTec' ? 
              [
                {
                  'mostrar': (row) => `${fechaFormat(row.fechaRegistroBaseTecno)}`,
                  'id': 'Fecha del Registro', 'enLista': true
                },
                {
                  'mostrar': (row) => `${row.tipoEmpresaSelected}`,
                  'id': 'Tipo de Empresa', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.nitCodigoRegistroBaseTecno}`,
                  'id': 'Nit o Código', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.nombreEmpresaBaseTecno}`,
                  'id': 'Nombre de la Empresa', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.autores.map((item)=>{
                    return ` ${item.participante.primer_nombre} ${item.participante.primer_apellido}`
                  })}`,
                  'id': 'Autores', 'enLista': false
                },
              ]: props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_innProProSer' ? 
              [
                {
                  'mostrar': (row) => `${fechaFormat(row.fechaInnovacionProceServi)}`,
                  'id': 'Fecha de la Innovación', 'enLista': true
                },
                {
                  'mostrar': (row) => `${row.institucionRealizoInnovacionProceServi}`,
                  'id': 'Institución para la que se realizó la Innovación', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.nombreInnovacionProceServi}`,
                  'id': 'Nombre de la Innovación', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.paisProceServi}`,
                  'id': 'País', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.tipoDiseñoPrototipoSelected}`,
                  'id': 'El Producto tiene', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.fechaRegistroDiseñoPrototipoTiene}`,
                  'id': 'Fecha de Obtención del Registro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.noRegistroDiseñoPrototipoTiene}`,
                  'id': 'No. del Registro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.tituloRegistroDiseñoPrototipoTiene}`,
                  'id': 'Titulo del Registro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.autores.map((item)=>{
                    return ` ${item.participante.primer_nombre} ${item.participante.primer_apellido}`
                  })}`,
                  'id': 'Autores', 'enLista': false
                },
              ]: props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_innGenGesEmp' ? 
              [
                {
                  'mostrar': (row) => `${fechaFormat(row.fechaInnovacionGestionEmp)}`,
                  'id': 'Fecha de la Innovación', 'enLista': true
                },
                {
                  'mostrar': (row) => `${row.institucionRealizoInnovacionGestionEmp}`,
                  'id': 'Institución para la que se realizó la Innovación', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.nombreInnovacionGestionEmp}`,
                  'id': 'Nombre de la Innovación', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.paisGestionEmp}`,
                  'id': 'País', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.tipoDiseñoPrototipoSelected}`,
                  'id': 'El Producto tiene', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.fechaRegistroDiseñoPrototipoTiene}`,
                  'id': 'Fecha de Obtención', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.noRegistroDiseñoPrototipoTiene}`,
                  'id': 'No. del Registro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.tituloRegistroDiseñoPrototipoTiene}`,
                  'id': 'Titulo del Registro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.autores.map((item)=>{
                    return ` ${item.participante.primer_nombre} ${item.participante.primer_apellido}`
                  })}`,
                  'id': 'Autores', 'enLista': false
                },
              ]:props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_eveArt' ? 
              [
                {
                  'mostrar': (row) => `Inicio: ${fechaFormat(row.fechaInicioEventoArtistico)} - Fin: ${moment(row.fechaFinalEventoArtistico).format('YYYY-MM-DD')}`,
                  'id': 'Fecha', 'enLista': true
                },
                {
                  'mostrar': (row) => `${row.tipoAmbitoSelected}`,
                  'id': 'Ambito', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.nombreEventoArtistico}`,
                  'id': 'Nombre del Evento', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.paisEventoArtistico}`,
                  'id': 'País', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.autores.map((item)=>{
                    return ` ${item.participante.primer_nombre} ${item.participante.primer_apellido}`
                  })}`,
                  'id': 'Autores', 'enLista': false
                },
              ]: props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_obrPro' ? 
              [
                {
                  'mostrar': (row) => `${fechaFormat(row.fechaCreacionObraProducto)}`,
                  'id': 'Fecha de Creacion', 'enLista': true
                },
                {
                  'mostrar': (row) => `${row.fechaPresentacionObraProducto}`,
                  'id': 'Fecha de Presentación', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.nombreObraProducto}`,
                  'id': 'Nombre de la Obra', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.nombreEspacioEventoObraProducto}`,
                  'id': 'Nombre del Espacio o Evento', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.paisObraProducto}`,
                  'id': 'País', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.TipoEspacioEvento}`,
                  'id': 'Tipo de espacio o evento', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.otroTipoEspacioEvento}`,
                  'id': 'Tipo Espacio Evento Otro', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.disciplinaAmbitoSelected}`,
                  'id': 'Disciplina o Ambito de Origen', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.tipoAmbitoSelected}`,
                  'id': 'Ambito', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.distincionObtenidaObraProducto}`,
                  'id': 'Distinción Obtenida', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.autores.map((item)=>{
                    return ` ${item.participante.primer_nombre} ${item.participante.primer_apellido}`
                  })}`,
                  'id': 'Autores', 'enLista': false
                },
              ]:props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_talCre' ? 
              [
                {
                  'mostrar': (row) => `Inicio: ${fechaFormat(row.fechaInicioTalleresCreacion)} - Fin: ${moment(row.fechaFinalTalleresCreacion).format('YYYY-MM-DD')}`,
                  'id': 'Fecha', 'enLista': true
                },
                {
                  'mostrar': (row) => `${row.tipoEventoSelected}`,
                  'id': 'Tipo Evento', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.tipoAmbitoSelected}`,
                  'id': 'Ambito', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.distincionObtenidaTalleresCreacion}`,
                  'id': 'Distinción Obtenida', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.nombreEventoTalleresCreacion}`,
                  'id': 'Nombre del Evento', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.paisTalleresCreacion}`,
                  'id': 'País', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.autores.map((item)=>{
                    return ` ${item.participante.primer_nombre} ${item.participante.primer_apellido}`
                  })}`,
                  'id': 'Autores', 'enLista': false
                },
              ]: props.tipoDeProducto.codigo == 'Inves_tipo_tec_pro_empCreaCul' ? 
              [
                {
                  'mostrar': (row) => `${fechaFormat(row.fechaRegistroEmpresaCultural)}`,
                  'id': 'Fecha Registro', 'enLista': true
                },
                {
                  'mostrar': (row) => `${row.nombreEmpresaCreativaCultural}`,
                  'id': 'Nombre', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.nitCodigoEmpresaCultural}`,
                  'id': 'Nit o Codigo', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.disciplinaAmbitoSelected}`,
                  'id': 'Disciplina Ambito', 'enLista': false
                },
                {
                  'mostrar': (row) => `${row.autores.map((item)=>{
                    return ` ${item.participante.primer_nombre} ${item.participante.primer_apellido}`
                  })}`,
                  'id': 'Autores', 'enLista': false
                },
              ]: []
            }
        />

        

        {openModal == true ? <ModalShowInformationTecnica 
          data={data}
          openModal={openModal}
          setOpenModal={setOpenModal}
          tipoProducto={producto}
        /> : ''}
        {openModalUsers == true ? <ModalShowInformationTecnicaUsers 
          data={dataUsers}
          openModal={openModalUsers}
          setOpenModal={setOpenModalUsers}
        /> : ''}
        {modalEdit == true ? <SemillerosGestionEditTecTecno
          data={dataEdit}
          openModal={modalEdit}
          setOpenModal={setModalEdit}
        />: ''}
        {openModalFiltrar == true ? <FiltrarEventosProduBiblio
          openModal={openModalFiltrar}
          setOpenModal={setOpenModalFiltrar}

          ageFilter={ageFilter}
          setAgeFilter={setAgeFilter}

          aplicarFiltros={aplicarFiltros}

          profesor={userFilter}
          setProfesor={setUserFilter}
        /> : ''}
        {/* <ExcelSheet data={dataSetParticipantes} name="Participantes">
            <ExcelColumn label="Primer Nombre" value="primer_nombre"/>
            <ExcelColumn label="Segundo Nombre" value="segundo_nombre"/>
            <ExcelColumn label="Primer Apellido" value="primer_apellido"/>
            <ExcelColumn label="Segundo Apellido" value="segundo_apellido"/>
            <ExcelColumn label="Identificacion" value="identificacion"/>
            <ExcelColumn label="Correo" value="correo"/>
        </ExcelSheet> */}
    </div>
  )
}

const ModalShowInformationTecnicaUsers = (props) =>{

  const isInitialMount = React.useRef(true);
  const [cargandoList, setCargandoList] = React.useState(true);
  const [data, setData] = React.useState([])
  
  React.useEffect( () => {
    //Obtengo los prceosos activos de la persona activa.
    if(isInitialMount.current){
        isInitialMount.current = false
        const obtenerProcesos = async () => {
          let data = await obtenerListaGestionTecTecno();
          setData(data)

          setCargandoList(false)
        }
        obtenerProcesos()
    }
  })

  const obtenerListaGestionTecTecno = async () => {
    return new Promise(resolve => {
        Funciones.consulta(`api/v1.0/investigacion/listaGestionSemilleroAutores?produccion_tec_tecno=${props.data.key.id}`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        })
    })
  }

  return (
    <div>
      <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModal} onClose={() => props.setOpenModal(false)}>
        <AppBarModal titulo={'¡ Participantes !'} mostrarModal={() => props.setOpenModal(false)} titulo_accion="" accion="" />
        <DialogContent>
        {cargandoList == true ? <div> <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> <LinearProgress /></div> 
        :
        <DetalleAutoresTecTecno datos={data}/>}
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  )
}


const ModalShowInformationTecnica = (props) =>{

  const isInitialMount = React.useRef(true);
  const [cargandoList, setCargandoList] = React.useState(true);
  const [data, setData] = React.useState([])
  React.useEffect( () => {
    //Obtengo los prceosos activos de la persona activa.
    if(isInitialMount.current){
        isInitialMount.current = false
        const obtenerProcesos = async () => {
          let data = await obtenerListaGestionTecTecno();
          setData(data)

          setCargandoList(false)
        }
        obtenerProcesos()
    }
  })

  const obtenerListaGestionTecTecno = async () => {
    return new Promise(resolve => {
        Funciones.consulta(`api/v1.0/investigacion/listaGestionTecTecnoUno?key=${props.data.key.id}`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        })
    })
  }

  return (
    <div>
      <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModal} onClose={() => props.setOpenModal(false)}>
        <AppBarModal titulo={'¡ Mas Informacion !'} mostrarModal={() => props.setOpenModal(false)} titulo_accion="" accion="" />
        <DialogContent>
        {cargandoList == true ? <div> <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> <LinearProgress /></div> 
        :
        <DetallesTecYTecno datos={data} producto={props.tipoProducto.codigo}/>}
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const CardsTipoProduccionTecnica = (props) =>{
    const isInitialMount = React.useRef(true);
    const [tiposProductos, setTiposProductos] = React.useState([]);
    const [cargandoCards, setCargandoCards] = React.useState(true);
    const classes = useStyles()
    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            const obtenerProcesos = async () => {
                obtenerTiposDeProductos()
                
            }
            obtenerProcesos()
        }
    })
    const obtenerTiposDeProductos = async () => {
      try{
          let data = await Funciones.obtenerValores(Helper.TIPOS_DE_PROD_TEC_INVES)
          
          setTiposProductos(data)

          setCargandoCards(false)
      }catch(err){
        console.log(err)
      } 
    }

    const changeVista = () =>{
      console.log("Cambio a listar datos")
    }

  return(
    cargandoCards == true ? <div> <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> <LinearProgress /></div> : 
    <Grid container >
      {tiposProductos.map((item, index) => (
        <Grid key={index} item xs={12} sm={6} style={{ padding: '2% 2% 0% 2%' }} onClick={() => { props.changeViewToList(item) }}>
        <Paper className={classes.color} elevation={8} square>
            <Card  className={classes.color} style={{ maxWidth: "100%", cursor: 'pointer'  }} elevation={1}>
            <CardHeader
                avatar={<Avatar aria-label="recipe" style={{ backgroundColor: item.valora }}>{item.valorc.charAt(0)}</Avatar>}
                title={item.valorc}
            />
            <CardContent >
                <Grid container direction={'row'} justify={'center'}>
                    <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "150px" }}>
                        <img src={assigment} style={{ width: '150px', maxWidth: '200px', minWidth: '100px', height: "150px" }} alt='Imagen Tipo' />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} style={{ padding: "15px 10px 0px 10px", }}>
                        <Typography className={classes.center} variant="body2" color="textSecondary" component="p" align='justify'>
                            {item.valord}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
            </Card>
        </Paper>
        </Grid>
      ))}
      </Grid>
  )
}
