import React, { Component } from 'react';
import {
    FormControl,
    Grid,
    Container
} from '@material-ui/core';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import TextField from '@material-ui/core/TextField';
import InputFile from "../../general/InputFile";
import Chip from '@material-ui/core/Chip';
import AttachFile from '@material-ui/icons/AttachFile'
import { api } from '../../../global/js/funciones'


class FormInformacionEmpresa extends Component {
    constructor(props){
        super(props);
        this.state = {
            nombre_archivo: '',
        }
    }

    onChange = ({ target }) => {
        let { value, name } = target;
        this.setState({
          [name]: value
        })
    }

    render(){
        let { onChangeState, datosEmpresa } = this.props
        let { nombre_archivo } = this.state
        return(
            <React.Fragment>
                <FormControl className='form-control'>
                    <Container maxWidth="sm">
                        <Grid
                            container
                            spacing={3}
                            direction='column'
                            justify='center'
                        >
                            <Grid item xs={12}>
                                <TextValidator 
                                    margin="dense"
                                    id="inf_nit"
                                    label="NIT"
                                    type="text"
                                    fullWidth
                                    value={datosEmpresa.nit}
                                    name="nit"
                                    onChange={onChangeState}
                                    // validators={["required"]}
                                    // errorMessages={["El campo es requerido"]}
                                />
                            </Grid>
                            <Grid container item xs ={12} direction="row" justify="space-between">
                                <Grid item xs={5}>
                                    <TextValidator 
                                        margin="dense"
                                        id="inf_nombre"
                                        label="Nombre de la empresa"
                                        type="text"
                                        fullWidth
                                        value={datosEmpresa.nombre}
                                        name="nombre"
                                        onChange={onChangeState}
                                        // validators={["rewquired"]}
                                        // errorMessages={["El campo es requerido"]}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextValidator 
                                        margin="dense"
                                        id="inf_razon_social"
                                        label="Razón social"
                                        type="text"
                                        value={datosEmpresa.razon_social}
                                        fullWidth
                                        name="razon_social"
                                        onChange={onChangeState}
                                        // validators={["rewquired"]}
                                        // errorMessages={["El campo es requerido"]}
                                    />
                                </Grid>
                            </Grid> 
                            <Grid item xs={12}>
                                <TextValidator 
                                    margin="dense"
                                    id="inf_numero_empleados"
                                    label="Número de empleados"
                                    type="text"
                                    fullWidth
                                    value={datosEmpresa.numero_empleados}
                                    name="numero_empleados"
                                    onChange={onChangeState}
                                    // validators={["required", "isNumber"]}
                                    // errorMessages={["El campo es requerido", "El campo debe ser un número"]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextValidator 
                                    margin="dense"
                                    id="inf_direccion"
                                    label="Direccion"
                                    value={datosEmpresa.direccion}
                                    type="text"
                                    fullWidth
                                    name="direccion"
                                    onChange={onChangeState}
                                    // validators={["required"]}
                                    // errorMessages={["El campo es requerido"]}
                                />
                            </Grid>
                            <Grid container item xs={12} direction="row" justify="space-between">
                                <Grid item xs={5}>
                                    <TextValidator 
                                        margin="dense"
                                        id="inf_departamento"
                                        label="Departamento"
                                        type="text"
                                        fullWidth
                                        value={datosEmpresa.departamento}
                                        name="departamento"
                                        onChange={onChangeState}
                                        // validators={["required"]}
                                        // errorMessages={["El campo es requerido"]}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextValidator 
                                        margin="dense"
                                        id="inf_ciudad"
                                        label="Ciudad"
                                        type="text"
                                        fullWidth
                                        name="ciudad"
                                        value={datosEmpresa.ciudad}
                                        onChange={onChangeState}
                                        // validators={["required"]}
                                        // errorMessages={["El campo es requerido"]}
                                    />
                                </Grid>
                            </Grid> 
                            <Grid container item xs={12} direction="row" justify="space-between">
                                <Grid item xs={5}>
                                    <TextValidator 
                                        margin="dense"
                                        id="inf_localidad"
                                        label="Localidad"
                                        type="text"
                                        fullWidth
                                        value={datosEmpresa.localidad}
                                        name="localidad"
                                        onChange={onChangeState}
                                        // validators={["required"]}
                                        // errorMessages={["El campo es requerido"]}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextValidator 
                                        margin="dense"
                                        id="inf_descripcion"
                                        label="Descripción"
                                        type="text"
                                        fullWidth
                                        name="descripcion"
                                        value={datosEmpresa.descripcion}
                                        onChange={onChangeState}
                                        // validators={["required"]}
                                        // errorMessages={["El campo es requerido"]}
                                    />
                                </Grid>
                            </Grid> 
                            <Grid container item xs={12} direction="row" justify="space-between">
                                <Grid item xs={5}>
                                    <TextValidator 
                                        margin="dense"
                                        id="inf_year_creacion"
                                        label="Año de creación"
                                        type="text"
                                        fullWidth
                                        value={datosEmpresa.year_creacion}
                                        name="year_creacion"
                                        onChange={onChangeState}
                                        // validators={["isNumber"]}
                                        // errorMessages={["El campo debe ser un número"]}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextValidator 
                                        margin="dense"
                                        id="inf_web_side"
                                        label="Web side"
                                        type="text"
                                        fullWidth
                                        name="web_side"
                                        value={datosEmpresa.web_side}
                                        onChange={onChangeState}
                                        // validators={["required"]}
                                        // errorMessages={["El campo es requerido"]}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    className='oculto'
                                    ref={this.fileInput}
                                    type="file"
                                    id="info_archivo_mod"
                                    name="nombre_archivo"
                                    label={"Logo de la Empresa"}
                                    fullWidth
                                    autoComplete="billing address-line1"
                                    onChange={ tag => {
                                        this.onChange(tag)
                                        onChangeState(tag)
                                    }}
                                />
                                <Grid item xs={12}>
                                    <InputFile label={"Logo de la Empresa"} id='info_archivo_mod' value={datosEmpresa.archivo ? datosEmpresa.archivo.replace(/^.*[\\\/]/, '') : nombre_archivo} />
                                    {nombre_archivo ? <Chip size="small" color="primary" clickable label="Ver Archivo" icon={<AttachFile />} component="a" href={`${api + datosEmpresa.archivo}`} target="_blank" /> : ''}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </FormControl>
            </React.Fragment>
        )
    }
}

class FormInformacionResponsable extends Component {
    render(){
        let { datosResponsable, onChangeState } = this.props
        return (
            <React.Fragment>
                <FormControl className='form-control'>
                    <Container maxWidth="sm">
                        <Grid
                            container
                            spacing={3}
                            direction='column'
                            justify='center'
                        >
                            <Grid container item xs ={12} direction="row" justify="space-between">
                                <Grid item xs={5}>
                                    <TextValidator 
                                        margin="dense"
                                        id="rep_nombre"
                                        label="Nombre del responsable"
                                        type="text"
                                        fullWidth
                                        value={datosResponsable.nombre}
                                        name="nombre"
                                        onChange={onChangeState}
                                        // validators={["required"]}
                                        // errorMessages={["El campo es requerido"]}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextValidator 
                                        margin="dense"
                                        id="rep_apellido"
                                        label="Apellido del responsable"
                                        type="text"
                                        value={datosResponsable.apellido}
                                        fullWidth
                                        name="apellido"
                                        onChange={onChangeState}
                                        // validators={["required"]}
                                        // errorMessages={["El campo es requerido"]}
                                    />
                                </Grid>
                            </Grid> 
                            <Grid item xs={12}>
                                <TextValidator 
                                    margin="dense"
                                    id="res_cargo"
                                    label="Cargo"
                                    type="text"
                                    fullWidth
                                    value={datosResponsable.cargo}
                                    name="cargo"
                                    onChange={onChangeState}
                                    // validators={["required"]}
                                    // errorMessages={["El campo es requerido"]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextValidator 
                                    margin="dense"
                                    id="rep_telefono"
                                    label="Teléfono"
                                    type="text"
                                    fullWidth
                                    value={datosResponsable.telefono}
                                    name="telefono"
                                    onChange={onChangeState}
                                    validators={["isNumber"]}
                                    errorMessages={["El campo debe ser un número"]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextValidator 
                                    margin="dense"
                                    id="rep_celular"
                                    label="Celular"
                                    type="text"
                                    fullWidth
                                    value={datosResponsable.celular}
                                    name="celular"
                                    onChange={onChangeState}
                                    validators={["isNumber"]}
                                    errorMessages={["El campo debe ser un número"]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextValidator 
                                    margin="dense"
                                    id="rep_email"
                                    label="E-mail"
                                    type="text"
                                    fullWidth
                                    name="email"
                                    value={datosResponsable.email}
                                    onChange={onChangeState}
                                    // validators={["required"]}
                                    // errorMessages={["El campo es requerido"]}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </FormControl>
            </React.Fragment>
        )
    }
}


class EmpresaAddData extends Component {
    renderFuncionData = () => {
        let { llave, onChangeState, datosEmpresa, datosResponsable } = this.props
        switch (llave) {
            case 'Empresa':
                return (
                    <FormInformacionEmpresa 
                        onChangeState={onChangeState}
                        datosEmpresa={datosEmpresa}
                    />
                )
            case 'Responsable':
                return (
                    <FormInformacionResponsable
                        onChangeState={onChangeState}
                        datosResponsable={datosResponsable}
                    />
                )
            default:
                return <></>
        }
    }

    render() {
        return (
            <div>
                <ValidatorForm className="scroll" style={{marginTop: "20px"}}>
                    {this.renderFuncionData()}
                    <button type="submit" className='oculto' id='btn_crear_empresa_pro'></button>
                </ValidatorForm>
            </div>
        );
    }
}

export default EmpresaAddData;