import React, { Component, useState, useEffect, createRef, useRef } from "react";
import PropTypes from "prop-types";
import {Dialog, DialogContent, DialogActions, Grid, TextField, Input,MenuItem, Select,InputLabel, FormControl, Button, List} from '@material-ui/core';
import { consulta, obtenerValores, api, crear_form_data, obtenerMisDatos, mostrarError, formulario} from "../../../global/js/funciones";
import AppBarModal from "../../general/AppBarModal";
import { BtnForm, BtnDetalle, BtnEliminar } from "../../general/BotonesAccion";
import { BottomNavigation, BottomNavigationAction, FormHelperText } from '@material-ui/core';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SchoolIcon from '@material-ui/icons/School';
import StarIcon from '@material-ui/icons/Star';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import ListarDatos from "./../../general/ListarDatos";
import ConfirmarAccion from '../../general/ConfirmarAccion';
import moment from "moment";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from "../../../global/imagenes/emma_w.png";
import InputFile from "../../general/InputFile";
import EnviarMultiplesArchivos from "../../general/EnviarMultiplesArchivos";
import AlertasSimple from "../../general/AlertasSimple";
import CustomDropzone from "../../general/CustomDropzone";

// HELPERS
import {
  DIPLOMADOS,
  FECHASGRADO,
  TIPO_IDENTIFICACION,
  ASPIRANTE,
  OPCION_GRADO2,
  ESTRATOS,
  SEXO,
  EGRESADO,
  TRABAJA,
  ACTIVIDAD_LABORAL,
  RANGO_SALARIAL,
  PROGRAMAS,
  TIPOS_ESTUDIO
} from './helper';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { es } from 'date-fns/locale'

const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;
let cant = 0;
export default function MatriculaEditar({ mostrarEditarMatricula, modalEditarMatricula, idMatricula,  actualizarMensaje, actRefrescar}) {
  const prevProps = useRef()


    const [cargando, setCargando] = useState(false);
    const [cargandoM, setCargandoM] = useState(false);
    const [seleccion, setSeleccion] = useState('datos');
    const [tiposIdentificacion, setTiposIdentificacion] = useState([]);
    const [tipo_identificacion, setTipoIdentificacion] = useState('');
    const [primer_nombre, setPrimer_nombre] = useState('');
    const [segundo_nombre, setSegundo_nombre] = useState('');
    const [primer_apellido, setPrimer_apellido] = useState('');
    const [segundo_apellido, setSegundo_apellido] = useState('');
    const [identificacion, setIdentificacion] = useState('');
    const [fecha_nacimiento, setFecha_nacimiento] = useState('');
    const [fecha_expedicion, setFecha_expedicion] = useState(null);
    const [ciudad_expedicion, setCiudad_expedicion] = useState('');
    const [lugar_nacimiento, setLugar_nacimiento] = useState('');
    const [celular, setCelular] = useState('');
    const [telefono, setTelefono] = useState('');
    const [correo_personal, setCorreo_personal] = useState('');

    const [fechasGrado, setFechasGrado] = useState([]);
    const [fechaGrado, setFechaGrado] = useState('');
    const [tiposAspirante, setTiposAspirante] = useState([]);
    const [aspirante, setAspirante] = useState('');
    const [programa, setPrograma] = useState('');
    const [misDatos, setMisDatos] = useState([]);
    const [periodo, setPeriodo] = useState('');
    const [diplomados, setDiplomados] = useState([]);
    const [opcionesGradoTipo2, setOpcionesGradoTipo2] = useState([]);
    const [opcionGrado1, setOpcionGrado1] = useState('');
    const [opcionGrado2, setOpcionGrado2] = useState('');
    const [trabajo_grado, setTrabajo_grado] = useState('');
    const [estratos, setEstratos] = useState([]);
    const [estrato, setEstrato] = useState('');
    const [sexos, setSexos] = useState([]);
    const [sexo, setSexo] = useState('');
    const [egresados, setEgresados] = useState([]);
    const [egresado, setEgresado] = useState('');
    const [opcionesTrabaja, setOpcionesTrabaja] = useState([]);
    const [trabaja, setTrabaja] = useState('');
    const [actividadesLaborales, setActividadesLaborales] = useState([]);
    const [actividadLaboral, setActividadLaboral] = useState('');
    const [rangosSalariales, setRangosSalariales] = useState([]);
    const [ProgramasModi, setProgramasModi] = useState([]);
    const [rango, setRango] = useState('');
    const [nombre_programa, setNombre_programa] = useState('');
    const [ano_graduo, setAgraduo] = useState('');
    const [institucion_educativa, setInstitucion_educativa] = useState('');
    const [direccion_residencia, setDireccion_residencia] = useState('');
    const [ciudad_residencia, setCiudad_residencia] = useState('');
    const [pais, setPais] = useState('');
    const [empresa_labora, setEmpresa_labora] = useState('');
    const [direccion, setDireccion] = useState('');
    const [cargo_desempena, setCargo_desempena] = useState('');
    const [telefono_empresarial, setTelefono_empresarial] = useState('');
    const [correo_empresarial, setCorreo_empresarial] = useState('');
    const [adjuntos, setAdjuntos] = useState([]);
    const [docs, setDocs] = useState([]);
    const [docsReintegro, setDocsReintegro] = useState([]);
    const [requisito, setRequisito] = useState([]);
    const [Req_Mat_Cc, setReq_Mat_Cc] = useState('');
    const [Req_Mat_Sab_Pro, setReq_Mat_Sab_Pro] = useState('');
    const [Req_Mat_Tra_Gra, setReq_Mat_Tra_Gra] = useState('');
    const [Req_Mat_Apr_Jud, setReq_Mat_Apr_Jud] = useState('');

    const [matricula, setMatricula] = useState([]);
    const [codigoMatricula, setCodigoMatricula] = useState('');
    const [nombreMatricula, setNombreMatricula] = useState('');
    const [menciones, setMenciones] = useState([]);
    const [idMencionEliminar, setIdMencionEliminar] = useState(0);
    const [idDocumentoEliminar, setIdDocumentoEliminar] = useState(0);
    const [confirmarAccionValue, setConfirmarAccion] = useState(false);
    const [confirmarAccionValueDoc, setConfirmarAccionDocumento] = useState(false);
    
    // Reintegro
    const [tipo_estudio, setTipoEstudio] = useState('');
    const [tipos_estudios, setTiposEstudios] = useState('');
    const [programa_procedencia, setProgramaProcedencia] = useState('');
    const [institucion_procedencia, setInstitucionProcedencia] = useState('');
    const [numero_creditos, setNumeroCreditos] = useState('');
    const [AdjuntarDocumentos, setAdjuntarDocumentos] = useState(false);
    const [DocumentosCargados, setDocumentosCargados] = useState([]);
    let [archivos, setArchivos] = useState([]);
    
    useEffect( () => {

      async function fechasgrados() {
        return new Promise((resolve) => {
          consulta(`api/v1.0/matriculas/fechasgrados`, null, null, (error, estado, resp) => {
            resolve(estado === 200 && !error ? resp : null);
          });
        });
	    }

      async function fetchData() {
        if (prevProps.current !== undefined && prevProps.current !== modalEditarMatricula) {
          if (modalEditarMatricula) {
            setCargandoM(true)
            await getDetalleMatricula(idMatricula).then(async data =>{
              setMatricula(data)
              setCodigoMatricula(data.tipo.codigo);
              setNombreMatricula(data.tipo.nombre);
              if(data.tipo.codigo == 'Mat_Pro_Sol_Gra') await obtenerDatosMatriculaGrado(data);
              if(data.tipo.codigo == 'Mat_Pro_Sol_Rein_Tran') {
                setSeleccion('programa')
                await obtenerDatosMatriculaReintegro(data)
              };
            })
            await fechasgrados().then(data => setFechasGrado(data))
            // await obtenerValores(FECHASGRADO).then(data => setFechasGrado(data));
            await obtenerValores(TIPO_IDENTIFICACION).then(data => setTiposIdentificacion(data));
            await obtenerValores(TIPOS_ESTUDIO).then(data => setTiposEstudios(data));
            await obtenerValores(ASPIRANTE).then(data => setTiposAspirante(data));
            await obtenerMisDatos().then(data => setMisDatos(data));
            await obtenerValores(DIPLOMADOS).then(data => setDiplomados(data));
            await obtenerValores(OPCION_GRADO2).then(data => setOpcionesGradoTipo2(data));
            await obtenerValores(ESTRATOS).then(data => setEstratos(data));
            await obtenerValores(SEXO).then(data => setSexos(data));
            await obtenerValores(EGRESADO).then(data => setEgresados(data));
            await obtenerValores(TRABAJA).then(data => setOpcionesTrabaja(data));
            await obtenerValores(ACTIVIDAD_LABORAL).then(data => setActividadesLaborales(data));
            await obtenerValores(RANGO_SALARIAL).then(data => setRangosSalariales(data));
            await obtenerValores(PROGRAMAS).then(data => setProgramasModi(data));
            setCargandoM(false)
          }else{
            resetStates()
          }
        }
      prevProps.current = modalEditarMatricula;
      }

      fetchData();
    }, [modalEditarMatricula])


    const obtenerDatosMatriculaGrado = async (matricula2) =>{
      if(matricula2 && primer_nombre == ''){
        const { usuario_registro, programa, periodo,  
          informacion_solicitud_grado: { opcionGrado1, opcionGrado2, trabajo_grado, aspirante, fechaGrado, estrato, 
            egresado, nombre_programa, ano_graduo, institucion_educativa, sexo, direccion_residencia, ciudad_residencia, pais,
            trabaja, actividadLaboral, empresa_labora, direccion, cargo_desempena, telefono_empresarial, correo_empresarial, rango 
          } 
        } = matricula2;
        setFecha_expedicion(usuario_registro.fecha_expedicion);
        setPrimer_nombre(usuario_registro.primer_nombre);
        setSegundo_nombre(usuario_registro.segundo_nombre);
        setPrimer_apellido(usuario_registro.primer_apellido);
        setSegundo_apellido(usuario_registro.segundo_apellido);
        setCiudad_expedicion(usuario_registro.lugar_expedicion);
        setIdentificacion(usuario_registro.identificacion);
        setFechaGrado(fechaGrado.id);
        setAspirante(aspirante.id);
        setTipoIdentificacion(usuario_registro.tipo_identificacion);
        setFecha_nacimiento(usuario_registro.fecha_nacimiento)
        setCelular(usuario_registro.celular);
        setTelefono(usuario_registro.telefono);
        setCorreo_personal(usuario_registro.correo_personal);
        setPeriodo(periodo);
        setPrograma(programa.id);
        setOpcionGrado1(opcionGrado1.id);
        setOpcionGrado2(opcionGrado2.id);
        setTrabajo_grado(trabajo_grado);
        setDireccion_residencia(direccion_residencia);
        setCiudad_residencia(ciudad_residencia);
        setPais(pais);
        setEstrato(estrato.id);
        setSexo(sexo.id);
        setEgresado(egresado.id);
        setNombre_programa(nombre_programa);
        setInstitucion_educativa(institucion_educativa);
        setAgraduo(ano_graduo);
        setTrabaja(trabaja.id)
        setActividadLaboral(actividadLaboral.id)
        setEmpresa_labora(empresa_labora)
        setDireccion(direccion)
        setCargo_desempena(cargo_desempena)
        setTelefono_empresarial(telefono_empresarial)
        setCorreo_empresarial(correo_empresarial)
        setRango(rango.id);
      }
      
      if(matricula2) {
        cant++;
        if(cant == 1){
          let doc = await getAdjuntosMatricula(matricula2.id);
          let a = [];
          let req = [];
          doc.map(({requisito, fecha_registro, archivo, nombre_archivo}) => {
            if(requisito.codigo == 'Req_Mat_Cc') setReq_Mat_Cc('');
            if(requisito.codigo == 'Req_Mat_Sab_Pro') setReq_Mat_Sab_Pro('');
            if(requisito.codigo == 'Req_Mat_Tra_Gra') setReq_Mat_Tra_Gra('');
            if(requisito.codigo == 'Req_Mat_Apr_Jud') setReq_Mat_Apr_Jud('');
            a.push({ nombre_doc: requisito.nombre, codigo_doc:requisito.codigo, adj: archivo, fecha: fecha_registro });
            req.push({codigo:requisito.codigo, nombre:requisito.nombre });
          });
          setRequisito(req);
          setDocs(a);
        }

        let menciones = await getMencionesMatricula(matricula2.id);
        setMenciones(menciones)
      }
   }

  const obtenerDatosMatriculaReintegro = async (matricula2) =>{
      if(matricula2 && primer_nombre == ''){
        const { usuario_registro, tipo_estudio, programa, programa_procedencia, institucion_procedencia, numero_creditos } = matricula2;
        setPrimer_nombre(usuario_registro.primer_nombre);
        setSegundo_nombre(usuario_registro.segundo_nombre);
        setPrimer_apellido(usuario_registro.primer_apellido);
        setSegundo_apellido(usuario_registro.segundo_apellido);
        setCiudad_expedicion(usuario_registro.lugar_expedicion);
        setIdentificacion(usuario_registro.identificacion);
        setTipoIdentificacion(usuario_registro.tipo_identificacion);
        setFecha_nacimiento(usuario_registro.fecha_nacimiento)
        setCelular(usuario_registro.celular);
        setTelefono(usuario_registro.telefono);
        setCorreo_personal(usuario_registro.correo_personal);
        setDireccion_residencia(usuario_registro.direccion);
        setCiudad_residencia(usuario_registro.lugar_residencia);
        setFecha_nacimiento(usuario_registro.fecha_nacimiento)
        setLugar_nacimiento(usuario_registro.lugar_nacimiento)
        setTipoEstudio(tipo_estudio.id)
        setPrograma(programa.id)
        setProgramaProcedencia(programa_procedencia)
        setInstitucionProcedencia(institucion_procedencia)
        setNumeroCreditos(numero_creditos)
      }
      let doc = await getAdjuntosMatricula(matricula2.id);
      let a = [];
      let req = [];
      if (doc){
        doc.map(({requisito, fecha_registro, archivo, nombre_archivo, id}) => {
          a.push({ id: id, nombre_doc: nombre_archivo, adj: archivo, fecha: fecha_registro });
          // req.push({codigo:requisito.codigo, nombre: nombre_archivo });
        });
        setDocsReintegro(a);
      } 
  }

  const getMencionesMatricula = (id) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/matriculas/${id}/menciones`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };

    const resetStates = () => {
      setPrimer_nombre('');
      setSegundo_nombre('');
      setPrimer_apellido('');
      setSegundo_apellido('');
      setCiudad_expedicion('');
      setIdentificacion('');
      setFechaGrado('');
      setAspirante('');
      setTipoIdentificacion('');
      setFecha_nacimiento('')
      setCelular('');
      setTelefono('');
      setCorreo_personal('');
      setPeriodo('')
      setPrograma('');
      setOpcionGrado1('');
      setOpcionGrado2('');
      setTrabajo_grado('')
      setDireccion_residencia('')
      setCiudad_residencia('')
      setPais('')
      setEstrato('');
      setSexo('');
      setEgresado('');
      setNombre_programa('')
      setInstitucion_educativa('')
      setAgraduo('')
      setTrabaja('')
      setActividadLaboral('')
      setEmpresa_labora('')
      setDireccion('')
      setCargo_desempena('')
      setTelefono_empresarial('')
      setCorreo_empresarial('')
      setRango('');
    }

    const getDetalleMatricula = (id) => {
      return new Promise((resolve) => {
        consulta(`api/v1.0/matriculas/editar/${id}`,null,null,(error, estado, resp) => {
            resolve(resp);
          }
        );
      });
    }

    const getAdjuntosMatricula = (id) => {
      return new Promise((resolve) => {
        consulta(`api/v1.0/matriculas/${id}/adjuntos`,null,null,
          (error, estado, resp) => {
            resolve(resp);
          }
        );
      });
    };

    // const conError = (valor) => {
    //   return valor ? true : false;
    // }

    const onSubmitDatos = async () =>{
      let sw = true;
      if(fechaGrado == '' || aspirante == '' || primer_nombre == '' || primer_apellido == '' || tipo_identificacion == '' || ciudad_expedicion == ''){
          actualizarMensaje({ 
              titulo: "Por favor, llenar todos los campos.", 
              tipo: "info", 
              mostrar: true, 
              tiempo: 6000 
          });	
        sw = false;
      }
      if(sw){
        let auxData = {
          fechaGrado:fechaGrado,
          aspirante:aspirante,
          primer_nombre:primer_nombre,
          segundo_nombre:segundo_nombre,
          primer_apellido:primer_apellido,
          segundo_apellido:segundo_apellido, 
          tipo_identificacion:tipo_identificacion,
          ciudad_expedicion:ciudad_expedicion,
          fecha_expedicion: fecha_expedicion ? moment(fecha_expedicion).format("YYYY-MM-DD") : "",
        };
        modificar_datos(auxData, seleccion);
      }
    }

    const onSubmitDatosReintegro = async () =>{
      let sw = true;
      if(tipo_identificacion == '' || primer_nombre == '' || primer_apellido == '' || fecha_nacimiento == '' || fecha_nacimiento == '' || lugar_nacimiento == '' || ciudad_residencia == '' || direccion_residencia == '' || celular == '', telefono == '' || correo_personal == ''){
          actualizarMensaje({ 
              titulo: "Por favor, llenar todos los campos.", 
              tipo: "info", 
              mostrar: true, 
              tiempo: 6000 
          });	
        sw = false;
      }
      if(sw){
        let auxData = {
          tipo_identificacion: tipo_identificacion,
          primer_nombre: primer_nombre,
          segundo_nombre: segundo_nombre,
          primer_apellido: primer_apellido,
          segundo_apellido: segundo_apellido,
          fecha_nacimiento: fecha_nacimiento,
          lugar_nacimiento: lugar_nacimiento,
          ciudad_residencia: ciudad_residencia,
          direccion_residencia: direccion_residencia,
          celular: celular,
          telefono: telefono,
          correo_personal: correo_personal
        };
        modificar_datos(auxData, seleccion);
      }
    }

    const pintarDatos_Reintegro = () => {
      return (
        !cargando ?
          <form style={{margin:'15px' }} noValidate autoComplete="off">
            <Grid container spacing={1} style={{ marginBottom: '40px' }}>
              <Grid item md={6} xs={12}>
                <FormControl className='form-control'>
                  <TextField value={primer_nombre} required type="text" id="primer_nombre" name="primer_nombre" label="Primer Nombre" fullWidth onChange={(e) => setPrimer_nombre(e.target.value)} />
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl className='form-control'>
                  <TextField value={segundo_nombre} type="text" id="segundo_nombre" name="segundo_nombre" label="Segundo Nombre" fullWidth onChange={(e) => setSegundo_nombre(e.target.value)} />
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl className='form-control'>
                  <TextField value={primer_apellido} required type="text" id="primer_apellido" name="primer_apellido" label="Primer Apellido" fullWidth onChange={(e) => setPrimer_apellido(e.target.value)} />
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl className='form-control'>
                  <TextField value={segundo_apellido} type="text" id="segundo_apellido" name="segundo_apellido" label="Segundo Apellido" fullWidth onChange={(e) => setSegundo_apellido(e.target.value)} />
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl className='form-control'>
                  <InputLabel>Tipo Identificacion</InputLabel>
                  <Select id='tipo_identificacion' value={tipo_identificacion} onChange={(e) => setTipoIdentificacion(e.target.value)} name='tipo_identificacion' >
                      {
                          tiposIdentificacion.map((d) => (
                              <MenuItem key={d.id} value={d.id}> {d.nombre} </MenuItem>
                          ))
                      }
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl className='form-control'>
                  <TextField value={identificacion} type="number" id="identificacion" name="identificacion" label="Identificacion" disabled="true" />
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField value={fecha_nacimiento} id="fecha_nacimiento" name="fecha_nacimiento" label="Fecha de Nacimiento" type="date" InputLabelProps={{ shrink: true, }} fullWidth onChange={(e) => setFecha_nacimiento(e.target.value)} />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl className='form-control'>
                  <TextField value={lugar_nacimiento} type="text" id="lugar_nacimiento" name="lugar_nacimiento" label="Lugar de Nacimiento" onChange={(e) => setLugar_nacimiento(e.target.value)}/>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl className='form-control'>
                  <TextField value={ciudad_residencia} type="text" id="ciudadResidencia" name="ciudadResidencia" label="Ciudad de Residencia" onChange={(e) => setCiudad_residencia(e.target.value)}/>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl className='form-control'>
                  <TextField value={direccion_residencia} type="text" id="DireccionResidencia" name="DireccionResidencia" label="Dirección de Residencia" onChange={(e) => setDireccion_residencia(e.target.value)} />
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl className='form-control'>
                  <TextField value={celular} type="number" id="celular" name="celular" label="Celular" onChange={(e) => setCelular(e.target.value)}/>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl className='form-control'>
                  <TextField value={telefono} type="number" id="telefono" name="telefono" label="Teléfono" onChange={(e) => setTelefono(e.target.value)} />
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl className='form-control'>
                  <TextField value={correo_personal} type="text" id="correo_personal" name="correo_personal" label="Correo Personal" onChange={(e) => setCorreo_personal(e.target.value)}/>
                </FormControl>
              </Grid>
            </Grid>
            <DialogActions>
              <Button size="large" variant="contained" style={{color:'white'}} color="primary" onClick={onSubmitDatosReintegro} disableElevation>
                ACTUALIZAR
              </Button>
            </DialogActions>
          </form>
        : <TareasTerminadas mensaje="Cargando.." marginTop="7%" imagen={emma_w} widthImg="7%" />
      )
    }

    const pintarDatos_Grado = () => {
        return (
        !cargando ?
          <form style={{margin:'15px' }} noValidate autoComplete="off">
            <Grid container spacing={1} style={{ marginBottom: '40px' }}>
                <Grid item md={6} xs={12}>
                  <FormControl className='form-control'>
                    <InputLabel>Aspirante a Grado de</InputLabel>
                    <Select
                        id='aspirante'
                        value={aspirante}
                        onChange={(e) => setAspirante(e.target.value)}
                        name='aspirante'
                      >
                        {
                            tiposAspirante.map((fg) => (
                                <MenuItem key={fg.id} value={fg.id}> {fg.nombre} </MenuItem>
                            ))
                        }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl className='form-control'>
                    <InputLabel>Fecha de Grado</InputLabel>
                    <Select
                      id='fechaGrado'
                      value={fechaGrado}
                      onChange={(e) => setFechaGrado(e.target.value)}
                      name='fechaGrado'
                    >
                      {
                          fechasGrado.map((fg) => (
                              <MenuItem key={fg.id} value={fg.id}> {fg.nombre} </MenuItem>
                          ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl className='form-control'>
                    <TextField
                      value={primer_nombre}
                      required
                      type="text"
                      id="primer_nombre"
                      name="primer_nombre"
                      label="Primer Nombre"
                      // error={conError(primer_nombre.error)}
                      // helperText={primer_nombre.error}
                      fullWidth
                      onChange={(e) => setPrimer_nombre(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl className='form-control'>
                    <TextField
                      value={segundo_nombre}
                      type="text"
                      id="segundo_nombre"
                      name="segundo_nombre"
                      label="Segundo Nombre"
                      fullWidth
                      onChange={(e) => setSegundo_nombre(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl className='form-control'>
                    <TextField
                      value={primer_apellido}
                      required
                      type="text"
                      id="primer_apellido"
                      name="primer_apellido"
                      label="Primer Apellido"
                      // error={conError(primer_apellido.error)}
                      // helperText={primer_apellido.error}
                      fullWidth
                      onChange={(e) => setPrimer_apellido(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl className='form-control'>
                    <TextField
                      value={segundo_apellido}
                      type="text"
                      id="segundo_apellido"
                      name="segundo_apellido"
                      label="Segundo Apellido"
                      // error={conError(segundo_apellido.error)}
                      // helperText={segundo_apellido.error}
                      fullWidth
                      onChange={(e) => setSegundo_apellido(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl className='form-control'>
                    <InputLabel>Tipo Identificacion</InputLabel>
                    <Select
                      id='tipos_identificacion'
                      value={tipo_identificacion}
                      onChange={(e) => setTipoIdentificacion(e.target.value)}
                      name='tipo_identificacion'
                    >
                    {
                        tiposIdentificacion.map((ti) => (
                            <MenuItem key={ti.id} value={ti.id}> {ti.nombre} </MenuItem>
                        ))
                    }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl className='form-control'>
                    <TextField
                      value={identificacion}
                      type="number"
                      id="identificacion"
                      name="identificacion"
                      label="Identificacion"
                      disabled="true"
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl className='form-control'>
                    <TextField
                      value={ciudad_expedicion}
                      type="text"
                      id="ciudad_expedicion"
                      name="ciudad_expedicion"
                      label="Ciudad Expedición"
                      // error={conError(ciudad_expedicion.error)}
                      // helperText={ciudad_expedicion.error}
                      fullWidth
                      onChange={(e) => setCiudad_expedicion(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      required={true}
                      style={{ marginTop: 3, width: '100%' }}
                      margin="normal"
                      label="Fecha de Expedición"
                      format="yyyy-MM-dd"
                      value={moment(fecha_expedicion)}
                      onChange={setFecha_expedicion}
                      KeyboardButtonProps={{
                        'aria-label': 'Cambiar fecha',
                      }}
                      maxDate={Date("yyyy-MM-dd")}
                      maxDateMessage={`La fecha debe ser menor que la fecha actual.`}
                      invalidDateMessage='Formato Invalido'
                      cancelLabel="Cancelar"
                      okLabel="Aceptar"
                      todayLabel="Hoy"
                      clearLabel="Limpiar"
                      dayText="Día"
                      monthText="Mes"
                      yearText="Año"
                      formatDay="EE"
                      formatMonth="MMMM"
                      formatYear="yyyy"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
          <DialogActions>
              <Button size="large" variant="contained" style={{color:'white'}} color="primary" onClick={onSubmitDatos} disableElevation>
                ACTUALIZAR
              </Button>
          </DialogActions>
          </form>
        : <TareasTerminadas mensaje="Cargando.." marginTop="7%" imagen={emma_w} widthImg="7%" />
      )
    }

    const validar_programa = () =>{
      let cod_opcionGrado2 = '';
      opcionesGradoTipo2.map(({ id, codigo }) => {
          if (id == opcionGrado2) cod_opcionGrado2 = codigo;
      })

      if(programa == '' || periodo == '' || opcionGrado1 == '' || opcionGrado2 == ''){
          actualizarMensaje({ 
              titulo: "Campos sin digitar.", 
              tipo: "info", 
              mostrar: true, 
              tiempo: 6000 
          });	
          return true
      }else{
          if(cod_opcionGrado2 == 'Mat_Opc_Tra_Gra_Otro' && trabajo_grado == ''){
              actualizarMensaje({ 
                  titulo: "Campos sin digitar.", 
                  tipo: "info", 
                  mostrar: true, 
                  tiempo: 6000 
              });	
              return true
          }
      }
      return false;
    }
    const validar_programaReintegro = () =>{
      if(tipo_estudio == '' || programa == '' || programa_procedencia == '' || institucion_procedencia == '' || numero_creditos == ''){
        actualizarMensaje({ titulo: "Campos sin digitar.",  tipo: "info",  mostrar: true,  tiempo: 6000  });
        return true
      }
      return false;
    }

    const onSubmitPrograma = () =>{
      if(validar_programa()) return;
      let auxData = {
        programa:programa,
        periodo:periodo,
        opcionGrado1:opcionGrado1,
        opcionGrado2:opcionGrado2,
        trabajo_grado:trabajo_grado
      };
      modificar_datos(auxData, seleccion);
    }

    const onSubmitProgramaReintegro = () =>{
      if(validar_programaReintegro()) return;
      let auxData = {
        programa:programa,
        tipo_estudio: tipo_estudio, 
        programa_procedencia: programa_procedencia, 
        institucion_procedencia: institucion_procedencia, 
        numero_creditos: numero_creditos
      };
      modificar_datos(auxData, seleccion);
    }

    const pintarProgramaGrado = () =>{
      return (
        !cargando ?
          <form style={{margin:'15px' }} noValidate autoComplete="off">
              <Grid container spacing={1} style={{ marginBottom: '40px' }}>
                <Grid item xs={6} >
                  <FormControl className='form-control'>
                    <InputLabel>Nombre del programa a graduar</InputLabel>
                    <Select
                        id='programa'
                        value={programa}
                        onChange={(e) => setPrograma(e.target.value)}
                        name='programa'
                      >
                      {
                          ProgramasModi.map((p) => (
                            <MenuItem key={p.id} value={p.id}> {p.nombre} </MenuItem>
                          ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className='form-control'>
                    <InputLabel>Periodo en que terminó académicamente</InputLabel>
                    <Input
                        value={periodo}
                        type="text"
                        id="periodo"
                        name="periodo"
                        label="Periodo"
                        // error={conError(periodo.error)}
                        // helperText={periodo.error}
                        fullWidth
                        onChange={(e) => setPeriodo(e.target.value)}
                    />
                    <FormHelperText>
                        Ejemplo: 2017-2
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6} >
                  <FormControl className='form-control'>
                    <InputLabel>Nombre de la Opción de Grado Tipo 1</InputLabel>
                    <Select
                        id='opcionGrado1'
                        value={opcionGrado1}
                        onChange={(e) => setOpcionGrado1(e.target.value)}
                        name='opcionGrado1'
                      >
                      {
                          diplomados.map((d) => (
                              <MenuItem key={d.id} value={d.id}> {d.nombre} </MenuItem>
                          ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} >
                  <FormControl className='form-control'>
                    <InputLabel>Nombre de la Opción de Grado Tipo 2</InputLabel>
                    <Select
                        id='opcionGrado2'
                        value={opcionGrado2}
                        onChange={(e) => setOpcionGrado2(e.target.value)}
                        name='opcionGrado2'
                      >
                      {
                          opcionesGradoTipo2.map((d) => (
                              <MenuItem key={d.id} value={d.id}> {d.nombre} </MenuItem>
                          ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl className='form-control'>
                      <InputLabel>Trabajo de Grado</InputLabel>
                      <Input
                          value={trabajo_grado}
                          type="text"
                          id="trabajo_grado"
                          name="trabajo_grado"
                          label="Trabajo de Grado"
                          // error={conError(trabajo_grado.error)}
                          // helperText={trabajo_grado.error}
                          fullWidth
                          onChange={(e) => setTrabajo_grado(e.target.value)}
                      />
                      <FormHelperText>
                          Ingrese el nombre de su Opción de grado tipo 2 .
                      </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <DialogActions>
                  <Button size="large" variant="contained" style={{color:'white'}} color="primary" onClick={onSubmitPrograma} disableElevation>
                    ACTUALIZAR
                  </Button>
              </DialogActions>
          </form>
        : <TareasTerminadas mensaje="Cargando.." marginTop="7%" imagen={emma_w} widthImg="7%" />
      );
    }
    
    const pintarProgramaReintegro = () =>{
      return (
        !cargando ?
          <form style={{margin:'15px' }} noValidate autoComplete="off">
              <Grid container spacing={1} style={{ marginBottom: '40px' }}>
                <Grid item xs={6}>
                  <FormControl className='form-control'>
                    <InputLabel>Tipo de estudio</InputLabel>
                    <Select disabled="true" id='tipo_estudio' value={tipo_estudio} onChange={(e) => setTipoEstudio(e.target.value)} name='tipo_estudio'  >
                      { tipos_estudios.map((p) => ( <MenuItem key={p.id} value={p.id}> {p.nombre} </MenuItem> )) }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className='form-control'>
                    <InputLabel>Programa</InputLabel>
                    <Select id='programa' value={programa} onChange={(e) => setPrograma(e.target.value)} name='programa' >
                      { ProgramasModi.map((p) => ( <MenuItem key={p.id} value={p.id}> {p.nombre} </MenuItem> )) }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className='form-control'>
                    <InputLabel>Programa Procedencia</InputLabel>
                    <Input disabled="true" name='programa_procedencia' value={programa_procedencia} onChange={(e) => setProgramaProcedencia(e.target.value)} />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Institución Procedencia</InputLabel> 
                        <Input disabled="true" name='institucion_procedencia' value={institucion_procedencia} onChange={(e) => setInstitucionProcedencia(e.target.value)} />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Numero de Créditos</InputLabel>
                        <Input disabled="true" type='number' name='numero_creditos' value={numero_creditos} onChange={(e) => setNumeroCreditos(e.target.value)} InputProps={{ inputProps: { min: 0, max: 10 } }} />
                        <FormHelperText>
                            De 1 a 21 creditos
                        </FormHelperText>
                    </FormControl>
                </Grid>
              </Grid>
              <DialogActions>
                  <Button size="large" variant="contained" style={{color:'white'}} color="primary" onClick={onSubmitProgramaReintegro} disableElevation>
                    ACTUALIZAR
                  </Button>
              </DialogActions>
          </form>
        : <TareasTerminadas mensaje="Cargando.." marginTop="7%" imagen={emma_w} widthImg="7%" />
      );
    }

    const onSubmitAdicional = () =>{
      if(telefono == '' || sexo == '' || fecha_nacimiento == '' || estrato == '' || pais == '' || correo_personal == '' || direccion_residencia == '' || ciudad_residencia == '' || celular == '' ){
        actualizarMensaje({ 
            titulo: "Campos sin digitar.", 
            tipo: "info", 
            mostrar: true, 
            tiempo: 6000 
        });
      }else{
        let auxData = { 
          celular:celular,
          telefono:telefono,
          correo_personal:correo_personal,
          direccion_residencia:direccion_residencia,
          ciudad_residencia:ciudad_residencia,
          pais:pais,
          estrato:estrato,
          fecha_nacimiento:fecha_nacimiento,
          sexo:sexo,
          egresado:egresado,
          nombre_programa:nombre_programa,
          ano_graduo:ano_graduo,
          institucion_educativa:institucion_educativa,
        };
        modificar_datos(auxData, seleccion);
      }
    }

    const pintarAdicional = () =>{
      return (
        !cargando ?
          <form style={{margin:'15px' }} noValidate autoComplete="off">
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Teléfono Principal</InputLabel>
                        <Input
                            value={celular}
                            type="text"
                            id="celular"
                            name="celular"
                            label="Teléfono Principal"
                            // error={conError(celular.error)}
                            // helperText={celular.error}
                            fullWidth
                            onChange={(e) => setCelular(e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Teléfono Alterno</InputLabel>
                        <Input
                            value={telefono}
                            type="text"
                            id="telefono"
                            name="telefono"
                            label="Teléfono Alterno"
                            // error={conError(telefono.error)}
                            // helperText={telefono.error}
                            fullWidth
                            onChange={(e) => setTelefono(e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Correo Electrónico Personal</InputLabel>
                        <Input
                            value={correo_personal}
                            type="text"
                            id="correo_personal"
                            name="correo_personal"
                            label="Correo Personal"
                            // error={conError(correo_personal.error)}
                            // helperText={correo_personal.error}
                            fullWidth
                            onChange={(e) => setCorreo_personal(e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Dirección de Residencia</InputLabel>
                        <Input
                            value={direccion_residencia}
                            type="text"
                            id="direccion_residencia"
                            name="direccion_residencia"
                            label="Dirección de Residencia"
                            // error={conError(direccion_residencia.error)}
                            // helperText={direccion_residencia.error}
                            fullWidth
                            onChange={(e) => setDireccion_residencia(e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Ciudad de Residencia</InputLabel>
                        <Input
                            value={ciudad_residencia}
                            type="text"
                            id="ciudad_residencia"
                            name="ciudad_residencia"
                            label="Ciudad de Residencia"
                            // error={conError(ciudad_residencia.error)}
                            // helperText={ciudad_residencia.error}
                            fullWidth
                            onChange={(e) => setCiudad_residencia(e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>País</InputLabel>
                        <Input
                            value={pais}
                            type="text"
                            id="pais"
                            name="pais"
                            label="País"
                            // error={conError(pais.error)}
                            // helperText={pais.error}
                            fullWidth
                            onChange={(e) => setPais(e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Estrato Socio Económico</InputLabel>
                        <Select
                            id='estrato'
                            name='estrato'
                            value={estrato}
                            onChange={(e) => setEstrato(e.target.value)}
                        >
                            {
                                estratos.map((e) => (
                                    <MenuItem key={e.id} value={e.id}> {e.nombre} </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <TextField
                            value={fecha_nacimiento}
                            id="fecha_nacimiento"
                            name="fecha_nacimiento"
                            label="Fecha de Nacimiento"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // error={conError(fecha_nacimiento.error)}
                            // helperText={fecha_nacimiento.error}
                            fullWidth
                            onChange={(e) => setFecha_nacimiento(e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Sexo</InputLabel>
                        <Select
                            id='sexo'
                            value={sexo}
                            onChange={(e) => setSexo(e.target.value)}
                            name='sexo'
                        >
                          {
                              sexos.map((s) => (
                                  <MenuItem key={s.id} value={s.id}> {s.nombre} </MenuItem>
                              ))
                          }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Egresado de otro programa</InputLabel>
                        <Select
                            id='egresado'
                            value={egresado}
                            onChange={(e) => setEgresado(e.target.value)}
                            name='egresado'
                        >
                            {
                                egresados.map((e) => (
                                    <MenuItem key={e.id} value={e.id}> {e.nombre} </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Nombre del Programa</InputLabel>
                        <Input
                            value={nombre_programa}
                            type="text"
                            id="nombre_programa"
                            name="nombre_programa"
                            label="Nombre del Programa"
                            // error={conError(nombre_programa.error)}
                            // helperText={nombre_programa.error}
                            fullWidth
                            onChange={(e) => setNombre_programa(e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                        <InputLabel>Año en que Graduó</InputLabel>
                        <Input
                            value={ano_graduo}
                            type="number"
                            id="ano_graduo"
                            name="ano_graduo"
                            label="Año en que Graduó"
                            // error={conError(ano_graduo.error)}
                            // helperText={ano_graduo.error}
                            fullWidth
                            onChange={(e) => setAgraduo(e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className='form-control'>
                        <InputLabel>Institución Educativa</InputLabel>
                        <Input
                            value={institucion_educativa}
                            type="text"
                            id="institucion_educativa"
                            name="institucion_educativa"
                            label="Institución Educativa"
                            // error={conError(institucion_educativa.error)}
                            // helperText={institucion_educativa.error}
                            fullWidth
                            onChange={(e) => setInstitucion_educativa(e.target.value)}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <DialogActions>
                <Button size="large" variant="contained" style={{color:'white'}} color="primary" onClick={onSubmitAdicional} disableElevation>
                  ACTUALIZAR
                </Button>
            </DialogActions>
          </form>
        : <TareasTerminadas mensaje="Cargando.." marginTop="7%" imagen={emma_w} widthImg="7%" />
      )
    }

    const validar_laboral = () =>{
      var cod_trabaja = '';
      opcionesTrabaja.map(({ id, codigo }) => {
          if (id == trabaja){
              cod_trabaja = codigo;
          }
      })

      if(trabaja == ''){
          actualizarMensaje({ 
              titulo: "Campos sin digitar.", 
              tipo: "info", 
              mostrar: true, 
              tiempo: 6000 
          });	
          return true
      }else if(cod_trabaja == 'Tra_Si' && (actividadLaboral == '' ||  rango == '' || empresa_labora == '' || direccion == '' || cargo_desempena == '' || telefono_empresarial == '' || correo_empresarial == '')){
        actualizarMensaje({ 
            titulo: "Campos sin digitar.", 
            tipo: "info", 
            mostrar: true, 
            tiempo: 6000 
        });	
        return true
      }
      return false;
    }

    const onSubmitLaboral = () =>{
      if(validar_laboral()) return;
      let auxData = { 
        trabaja,
        actividadLaboral,
        empresa_labora,
        direccion_empresa:direccion,
        cargo_desempena,
        telefono_empresarial,
        correo_empresarial,
        rango
      }
      modificar_datos(auxData, seleccion);
    }

    const eliminarArchivo = (i) => {
      let aux = DocumentosCargados.filter((elemento, index) => index != i);
      setDocumentosCargados(aux);

      const nuevosArchivos = archivos.filter((elemento, index) => index != i);
      setArchivos(nuevosArchivos);

      actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 })
    }
    
    const agregarArchivos = (arc) => {
      const nuevosArchivos = arc.map(element => ({
        'codigo': element.name,
        'archivo': element
      }));
      actualizarMensaje({ titulo: `Archivo Agregado.`, tipo: 'success', mostrar: true, tiempo: 6000 })
      setArchivos([...archivos, ...nuevosArchivos]);

      let archivo2 = adjuntos.concat(arc);
      setDocumentosCargados([...DocumentosCargados, ...archivo2])

    }

    const pintarLaboral = () =>{
      return (
        !cargando ?
          <form style={{margin:'15px' }} noValidate autoComplete="off">
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <FormControl className='form-control'>
                      <InputLabel>Trabaja Actualmente</InputLabel>
                      <Select
                          value={trabaja}
                          id='trabaja'
                          onChange={(e) => setTrabaja(e.target.value)}
                          name='trabaja'
                      >
                        {
                            opcionesTrabaja.map((op) => (
                                <MenuItem key={op.id} value={op.id}> {op.nombre} </MenuItem>
                            ))
                        }
                      </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className='form-control'>
                    <InputLabel>Actividad Laboral</InputLabel>
                    <Select
                        value={actividadLaboral}
                        id='actividadlaboral'
                        name='actividadlaboral'
                        onChange={(e) => setActividadLaboral(e.target.value)}
                    >
                      {
                          actividadesLaborales.map((al) => (
                              <MenuItem key={al.id} value={al.id}> {al.nombre} </MenuItem>
                          ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className='form-control'>
                    <InputLabel>Empresa Donde Labora</InputLabel>
                    <Input
                        value={empresa_labora}
                        type="text"
                        id="empresa_labora"
                        name="empresa_labora"
                        label="Empresa Donde Labora"
                        // error={conError(empresa_labora.error)}
                        // helperText={empresa_labora.error}
                        fullWidth
                        onChange={(e) => setEmpresa_labora(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className='form-control'>
                    <InputLabel>Dirección</InputLabel>
                    <Input
                        value={direccion}
                        type="text"
                        id="direccion"
                        name="direccion"
                        label="Dirección Empresa"
                        // error={conError(direccion.error)}
                        // helperText={direccion.error}
                        fullWidth
                        onChange={(e) => setDireccion(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className='form-control'>
                    <InputLabel>Cargo que Desempeña</InputLabel>
                    <Input
                        value={cargo_desempena}
                        type="text"
                        id="cargo_desempena"
                        name="cargo_desempena"
                        label="Cargo que Desempeña"
                        // error={conError(cargo_desempena.error)}
                        // helperText={cargo_desempena.error}
                        fullWidth
                        onChange={(e) => setCargo_desempena(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className='form-control'>
                    <InputLabel>Teléfonos</InputLabel>
                    <Input
                        value={telefono_empresarial}
                        type="text"
                        id="telefono_empresarial"
                        name="telefono_empresarial"
                        label="Teléfonos"
                        // error={conError(telefono_empresarial.error)}
                        // helperText={telefono_empresarial.error}
                        fullWidth
                        onChange={(e) => setTelefono_empresarial(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className='form-control'>
                    <InputLabel>Correo Electrónico Empresarial</InputLabel>
                    <Input
                        value={correo_empresarial}
                        type="text"
                        id="correo_empresarial"
                        name="correo_empresarial"
                        label="Correo Electrónico Empresarial"
                        // error={conError(correo_empresarial.error)}
                        // helperText={correo_empresarial.error}
                        fullWidth
                        onChange={(e) => setCorreo_empresarial(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className='form-control'>
                      <InputLabel>Rango Salarial</InputLabel>
                      <Select
                        value={rango}
                        id='rango'
                        name='rango'
                        onChange={(e) => setRango(e.target.value)}
                      >
                        {
                            rangosSalariales.map((r)=>(
                                <MenuItem key={r.id} value={r.id}> {r.nombre} </MenuItem>
                            ))
                        }
                      </Select>
                  </FormControl>
              </Grid>
            </Grid>
            <DialogActions>
              <Button size="large" variant="contained" style={{color:'white'}} color="primary" onClick={onSubmitLaboral} disableElevation>
                ACTUALIZAR
              </Button>
            </DialogActions>
          </form>
        : <TareasTerminadas mensaje="Cargando.." marginTop="7%" imagen={emma_w} widthImg="7%" />
      );
    }

    const onSubmitDocumento = () =>{
      if(adjuntos.length <= 0){
        actualizarMensaje({ titulo: 'No se ha cargado ningun documento', tipo: 'info', mostrar: true, tiempo: 6000 });
      }else{
        let auxData = {
          cantidad: adjuntos.length,
        }
        modificar_datos(auxData, seleccion) 
      }
    }
    const onSubmitDocumentoReintegro = () =>{
      if(archivos.length <= 0){
        actualizarMensaje({ titulo: 'No se ha cargado ningun documento', tipo: 'info', mostrar: true, tiempo: 6000 });
      }else{
        let auxData = {
          cantidad: archivos.length,
        }
        modificar_datos(auxData, seleccion) 
      }
    }

    const onChangeSelect = ({ target }) => {
      let { value, name } = target;
      adjuntos.push({
        'codigo':name,
        'archivo':document.getElementById(name).files[0],
      })

      if(name == 'Req_Mat_Cc') setReq_Mat_Cc(value);
      if(name == 'Req_Mat_Sab_Pro') setReq_Mat_Sab_Pro(value);
      if(name == 'Req_Mat_Tra_Gra') setReq_Mat_Tra_Gra(value);
      if(name == 'Req_Mat_Apr_Jud') setReq_Mat_Apr_Jud(value);
    }

    const pintarRequisitos_Grado = (acciones) =>{
      let value = null;
      return(
        !cargando ?
          <>
            <ListarDatos 
              datos={docs}
              id='tbl_docs_matricula'
              avatar={({ nombre_doc }) => nombre_doc.charAt(0)}
              titulo=''
              acciones={({adj}) => acciones(adj)}
              fila_principal={({nombre_doc}) => nombre_doc}
              filas={[
                { 'id': 'fecha', 'mostrar': ({ fecha }) => moment(fecha).format('YYYY-MM-DD, h:mm a'), 'nombre': 'Fecha: ' },
              ]}
            />
            <form style={{margin:'15px' }} noValidate autoComplete="off">
                <Grid container spacing={3} style={{ marginBottom: '40px' }}>
                    {requisito.map(({codigo, nombre}) =>{
                      if(codigo == 'Req_Mat_Cc') value = Req_Mat_Cc;
                      if(codigo == 'Req_Mat_Sab_Pro') value = Req_Mat_Sab_Pro;
                      if(codigo == 'Req_Mat_Tra_Gra') value = Req_Mat_Tra_Gra;
                      if(codigo == 'Req_Mat_Apr_Jud') value = Req_Mat_Apr_Jud;
                        return (
                          <>
                            <Grid item xs={12} className='oculto'>
                                <TextField
                                  ref={createRef()}
                                  required
                                  type="file"
                                  id={codigo}
                                  name={codigo}
                                  label={nombre}
                                  fullWidth
                                  autoComplete="billing address-line1"
                                  onChange={onChangeSelect}
                                />
                            </Grid>
                            <Grid item xs={12}>
                              <InputFile label={nombre} id={codigo} value={value}/>
                            </Grid>
                          </>
                        )
                      })
                    }
                    <Grid item xs={12}>
                      <DialogActions>
                        <Button size="large" variant="contained" style={{color:'white'}} color="primary" onClick={onSubmitDocumento} disableElevation>
                          ACTUALIZAR
                        </Button>
                      </DialogActions>
                    </Grid>
                </Grid>
              </form>
          </>
        : <TareasTerminadas mensaje="Cargando.." marginTop="7%" imagen={emma_w} widthImg="7%" />
      )
    }
    const pintarDocs_Reintegro = (acciones) =>{
      let value = null;
      return(
        !cargando ?
          <>
            <ListarDatos 
              datos={docsReintegro}
              id='tbl_docs_matricula'
              avatar={({ nombre_doc }) => nombre_doc.charAt(0)}
              titulo=''
              acciones={({adj, id}) => acciones(adj, id)}
              fila_principal={({nombre_doc}) => nombre_doc}
              filas={[
                { 'id': 'fecha', 'mostrar': ({ fecha }) => moment(fecha).format('YYYY-MM-DD, h:mm a'), 'nombre': 'Fecha: ' },
              ]}
            />
            <Grid container>
              <Grid item xs={12} md={12}>
                <List component="nav" arial-label="main">
                </List>        
                <AlertasSimple
                  tipo='success'
                  titulo={'¡Click Aquí para cargar archivos!'}
                  margin = '0px'
                  callback={() => setAdjuntarDocumentos(true)}
                />
              </Grid>

              <Dialog open={AdjuntarDocumentos} fullWidth={true} maxWidth='sm' onClose={() => setAdjuntarDocumentos(false)}>
                <AppBarModal titulo='¡ Cargar Documentos !' mostrarModal={() => setAdjuntarDocumentos(false)} titulo_accion='' />
                <DialogContent className='scroll'>
                  <CustomDropzone archivos={DocumentosCargados} addFunction={agregarArchivos} deleteFunction={eliminarArchivo} actualizarMensaje={actualizarMensaje} />
                </DialogContent>
                <DialogActions>
                    <Button size="large" variant="contained" style={{color:'white'}} color="primary" onClick={onSubmitDocumentoReintegro} disableElevation>
                      ACTUALIZAR
                    </Button>
                  <BtnForm texto="Cerrar" callback={() => setAdjuntarDocumentos(false)} />
                </DialogActions>
              </Dialog>
            </Grid>
            <ConfirmarAccion
              dato={{ id: 0 }}
              titulo={' ¿ Eliminar Documento ?'}
              ejecutarAccion={() => eliminarDocumento(idDocumentoEliminar)}
              modalConAccion={confirmarAccionValueDoc}
              mostrarModalConfAccion={(v) => setConfirmarAccion(v)}
            />
          </>
        : <TareasTerminadas mensaje="Cargando.." marginTop="7%" imagen={emma_w} widthImg="7%" />
      )
    }

    const pintarAdjuntos = () => {
      const acciones = (adj) => {
        let detalle = <a href={`${api}${adj}`} rel="noopener noreferrer" target="_blank" className='sin_decorador'><BtnDetalle callback={() => { }} color='#01579b' texto='Abrir' /></a>;
        return <div>{detalle}</div>;
      }
      return pintarRequisitos_Grado(acciones)
    }

    const pintarDocsReintegro = () => {
      const acciones = (adj, id) => {
        let detalle = <a href={`${api}${adj}`} rel="noopener noreferrer" target="_blank" className='sin_decorador'><BtnDetalle callback={() => { }} color='#01579b' texto='Abrir' /></a>;
        const onClickEliminar = (id) => {
          setIdDocumentoEliminar(id)
          setConfirmarAccionDocumento(true)
        };
        let eliminar = <BtnEliminar callback={() => onClickEliminar(id)} color='#01579b' texto='Eliminar' />;
        return <div>{detalle}{eliminar}</div>;
      }
      return pintarDocs_Reintegro(acciones)
    }

    const pintarMenciones = () => {
      const acciones = (mencion) => {
        const onClickEliminar = (datos) => {
          setIdMencionEliminar(datos.id)
          setConfirmarAccion(true)
        };



        let eliminar = <BtnEliminar callback={() => onClickEliminar(mencion)} color='#01579b' texto='Eliminar' />;
        return <div>{eliminar}</div>;
      }

      return (
        <ListarDatos
          datos={menciones}
          // cargando={this.state.cargando}
          acciones={(data) => acciones(data)}
          id="tbl_menciones_edi"
          avatar={({ mencion: { nombre } }) => nombre[0]}
          fila_principal={({ mencion: { nombre } }) => nombre}
          filas={[
            {
              id: "fecha_registro",
              mostrar: ({ fecha_registro }) =>
                moment(fecha_registro).format("YYYY-MM-DD, h:mm a"),
              nombre: "Fecha Registro : ",
            },
            {
              id: "usuario_registro",
              mostrar: ({
                usuario_registro: {
                  primer_nombre,
                  primer_apellido,
                  segundo_apellido,
                },
              }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
            },
          ]}
        />
      );
    };

    

    const pintarSolicitudGrado = () => {
      return (
        <div>
          {seleccion === 'datos' && pintarDatos_Grado()}
          {seleccion === 'programa' && pintarProgramaGrado()}
          {seleccion === 'adicional' && pintarAdicional()}
          {seleccion === 'laboral' && pintarLaboral()}
          {seleccion === 'documentos' && pintarAdjuntos()}
          {seleccion === 'menciones' && pintarMenciones()}
        </div>
      )
    }
  
    const pintarReintegros = () => {
      // obtenerDatosMatriculaTransferencia();
      return (
        <div>
          {/* {seleccion === 'datos' && pintarDatos_Reintegro()} */}
          {seleccion === 'programa' && pintarProgramaReintegro()}
          {seleccion === 'documentos' && pintarDocsReintegro()}
        </div>
      )
    }

    const pintarRequisitos_Reintegros = () =>{}

    const pintar_menuSolicitudGrado = () => {
        return (
          <div className={'menu_modal'}>
            <BottomNavigation showLabels >
              <BottomNavigationAction label={'Datos'} icon={<ImportContactsIcon />} className={clase('datos', seleccion)} onClick={() => setSeleccion('datos')} />
              <BottomNavigationAction label={'Programa'} icon={<SchoolIcon />} className={clase('programa', seleccion)} onClick={() => setSeleccion('programa')} />
              <BottomNavigationAction label={'Adicional'} icon={<PersonPinIcon />} className={clase('adicional', seleccion)} onClick={() => setSeleccion('adicional')} />
              <BottomNavigationAction label={'Laboral'} icon={<MenuBookIcon />} className={clase('laboral', seleccion)} onClick={() => setSeleccion('laboral')} />
              <BottomNavigationAction label={'Documentos'} icon={<MenuBookIcon />} className={clase('documentos', seleccion)} onClick={() => setSeleccion('documentos')} />
              <BottomNavigationAction label={'Menciones'} icon={<StarIcon />} className={clase('menciones', seleccion)} onClick={() => setSeleccion('menciones')} />
            </BottomNavigation>
          </div>
        )
    }

    const pintar_menuReintegros = () => {
        return (
          <div className={'menu_modal'}>
            <BottomNavigation showLabels >
              <BottomNavigationAction label={'Programa'} icon={<SchoolIcon />} className={clase('programa', seleccion)} onClick={() => setSeleccion('programa')} />
              {/* <BottomNavigationAction label={'Datos'} icon={<ImportContactsIcon />} className={clase('datos', seleccion)} onClick={() => setSeleccion('datos')} /> */}
              <BottomNavigationAction label={'Documentos'} icon={<MenuBookIcon />} className={clase('documentos', seleccion)} onClick={() => setSeleccion('documentos')} />
            </BottomNavigation>
          </div>
        )
    }

    const limpiarReq = () => {
      setReq_Mat_Cc('');
      setReq_Mat_Sab_Pro('');
      setReq_Mat_Tra_Gra('');
      setReq_Mat_Apr_Jud('');
    }

    const eliminarMencion = id => {
      // let { actualizarMensaje, showModalGestionar, showModalDetalle } = this.props;
      return new Promise((resolve) => {
        consulta(`api/v1.0/matriculas/eliminarMencion/${id}`, {}, 'put', async (error, estado, resp) => {
          let title = 'Ha ocurrido un error, contacte con el administrador.';
          let icon = 'error';
          if (!error) {
            if (estado === 200) {
              icon = 'success';
              title = resp.titulo;
              let menciones = await getMencionesMatricula(idMatricula);
              setMenciones(menciones)
              setConfirmarAccion(false);
            } else {
              icon = 'info';
              title = resp.titulo ? resp.titulo : mostrarError(resp);
            }
          }
          actualizarMensaje({ titulo: title, tipo: icon, mostrar: true, tiempo: 6000 });
        });
      });
    };

    const eliminarDocumento = id => {
      // let { actualizarMensaje, showModalGestionar, showModalDetalle } = this.props;
      return new Promise((resolve) => {
        consulta(`api/v1.0/matriculas/eliminarDocumento/${id}`, {}, 'put', async (error, estado, resp) => {
          let title = 'Ha ocurrido un error, contacte con el administrador.';
          let icon = 'error';
          if (!error) {
            if (estado === 200) {
              icon = 'success';
              title = resp.titulo;
              await getDetalleMatricula(idMatricula).then(async data =>{
                setMatricula(data)
                setCodigoMatricula(data.tipo.codigo);
                setNombreMatricula(data.tipo.nombre);
                if(data.tipo.codigo == 'Mat_Pro_Sol_Gra') await obtenerDatosMatriculaGrado(data);
                if(data.tipo.codigo == 'Mat_Pro_Sol_Rein_Tran'){
                  await obtenerDatosMatriculaReintegro(data);
                  setArchivos = []
                } 
              })
              setConfirmarAccionDocumento(false);
            } else {
              icon = 'info';
              title = resp.titulo ? resp.titulo : mostrarError(resp);
            }
          }
          actualizarMensaje({ titulo: title, tipo: icon, mostrar: true, tiempo: 6000 });
        });
      });
    };

    const modificar_datos = async (auxData, tipoMod) =>{
      const data =  await crear_form_data(auxData);
        data.append(`tipo_modificacion`, tipoMod);
        if(tipoMod == 'documentos'){
          let adjuntos1 = archivos.length > 0 ? archivos : adjuntos
          adjuntos1.map(({ archivo, codigo }, index) => {
            data.append(`archivo${index}`, archivo)
            data.append(`codigo${index}`, codigo)
            return true;
          })
          limpiarReq();
          setAdjuntarDocumentos(false);
        }
        setCargando(true);
        formulario(`api/v1.0/matriculas/${matricula.id}/modificar_datos`, data, 'put', async (error, estado, resp) => {
          let title = 'Ha ocurrido un error, contacte con el administrador.';
          let icon = 'error';
          let tipo = '';
          if (!error) {
            if (estado === 200) {
              // matricula = await getDetalleMatricula(matricula.id);
              await getDetalleMatricula(idMatricula).then(async data =>{
                if(tipoMod != 'documentos' && codigoMatricula != 'Mat_Pro_Sol_Gra' ){
                  actRefrescar(data.tipo.id, data.periodo, data.estado_solicitud.codigo );
                }
                mostrarEditarMatricula(true);
                setMatricula(data)
                setCodigoMatricula(data.tipo.codigo);
                setNombreMatricula(data.tipo.nombre);
                if(data.tipo.codigo == 'Mat_Pro_Sol_Gra') await obtenerDatosMatriculaGrado(data);
                if(data.tipo.codigo == 'Mat_Pro_Sol_Rein_Tran'){
                  await obtenerDatosMatriculaReintegro(data);
                  setArchivos = []
                } 
              })
              cant = 0;
              icon = 'success';
              title = resp.titulo;
              // obtenerDatosMatriculaGrado(matricula);
              // mostrarEditarMatricula(false);
            } else if (estado === 302) {
              icon = 'info';
              title = resp.titulo;
            } else {
              tipo = 'info';
              title = resp.titulo ? resp.titulo : mostrarError(resp);
            }
          }
          actualizarMensaje({ titulo: title, tipo: icon, mostrar: true, tiempo: 6000 });
          setCargando(false);
        });
    }

    return (
        <div>
        <Dialog 
           open={modalEditarMatricula}
           maxWidth={'md'}
           fullWidth={true} 
           onClose={()=> mostrarEditarMatricula(false) }
           aria-labelledby='customized-dialog-title'
           className='scroll'
           >
          <AppBarModal titulo={`¡ Modificar ` + nombreMatricula + ` !`} mostrarModal={mostrarEditarMatricula} titulo_accion='' />
          { !cargandoM && (codigoMatricula == 'Mat_Pro_Sol_Gra' ? pintar_menuSolicitudGrado() : pintar_menuReintegros())}
          <DialogContent style={{ padding: 0 }} className='scroll' >
            { !cargandoM ?
              codigoMatricula == 'Mat_Pro_Sol_Gra' ? pintarSolicitudGrado() : pintarReintegros():
              <TareasTerminadas mensaje="Cargando.." marginTop="7%" imagen={emma_w} widthImg="7%" cargando={true}/>
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={()=> mostrarEditarMatricula(false) } />
          </DialogActions>
        </Dialog>

        <ConfirmarAccion
            dato={{ id: 0 }}
            titulo={' ¿ Eliminar Mencion ?'}
            ejecutarAccion={() => eliminarMencion(idMencionEliminar)}
            modalConAccion={confirmarAccionValue}
            mostrarModalConfAccion={(v) => setConfirmarAccion(v)}
          />

        </div>
    );
}
