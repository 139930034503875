import React, { Component } from 'react';
import { Dialog, DialogContent, DialogActions, Grid, Container, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { BtnForm } from '../../general/BotonesAccion'
import AppBarModal from '../../general/AppBarModal'
import { consulta } from '../../../global/js/funciones'
import TareasTerminadas from '../../general/TareasTerminadas'
import emma_w from '../../../global/imagenes/emma_w.png'
import { ProcesoMenu } from './Menu'

import '../../../global/css/stylePortal.css'

class VacanteDetalle extends Component {

    constructor(){
        super()
        this.state = {
            seleccion: '',
            proceso: [],
            cargando: true
        }
    }

    async componentDidUpdate({ modalDetalleVacante }, { seleccion }) {
        let { seleccion: seleccionNew } = this.state;
        let { id_proceso, modalDetalleVacante: modalDetalleVacanteNew, vistaDetalle } = this.props;
        if (seleccionNew !== seleccion) {
          if (seleccionNew === 'proceso') this.obtenerProceso(id_proceso);
        }
        if (modalDetalleVacante !== modalDetalleVacanteNew) this.setState({ seleccion: modalDetalleVacanteNew ? vistaDetalle : '' })
    }

    async obtenerProceso(id = 0) {
        return new Promise(resolve => {
            consulta(`api/v1.0/portal/${id}/detalle`, null, null, (error, estado, resp) => {
                resolve(estado === 200 && !error ? resp : null);
                if (estado === 200) this.setState({ cargando: false, proceso: resp[0] });
            })
        })
    }

    pintarProceso = () => {
        let { modalDetalleVacante, mostrarModalDetalleVacante } = this.props
        let { proceso } = this.state
        return (
            <div>
                <Dialog open={modalDetalleVacante} onClose={() => mostrarModalDetalleVacante(false)} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
                    <AppBarModal titulo={"Detalle de la Vacante"} mostrarModal={() => mostrarModalDetalleVacante(false)} titulo_accion="" />
                    <DialogContent style={{ padding: 10, overflowX: 'hidden' }} className="scroll">
                        <div>
                            <Container maxWidth="sm">
                                <Grid
                                    container
                                    spacing={3}
                                    direction='column'
                                    justify='center'
                                    style={{marginTop: "10px"}}
                                >
                                    <Table>
                                        <TableBody>
                                            <TableRow key={1}>
                                                <TableCell component="th" scope="row">Nombre de cargo</TableCell>
                                                <TableCell align="center">{proceso.vacante.nombre_cargo}</TableCell>
                                            </TableRow>
                                            <TableRow key={2}>
                                                <TableCell component="th" scope="row">Nombre del responsable</TableCell>
                                                <TableCell align="center">{proceso.vacante.nombre_responsable}</TableCell>
                                            </TableRow>
                                            <TableRow key={3}>
                                                <TableCell component="th" scope="row">Descripción del cargo</TableCell>
                                                <TableCell align="center">{proceso.vacante.descripcion}</TableCell>
                                            </TableRow>
                                            <TableRow key={4}>
                                                <TableCell component="th" scope="row">Tipo de contrato</TableCell>
                                                <TableCell align="center">{proceso.vacante.tipo_de_contrato}</TableCell>
                                            </TableRow>
                                            <TableRow key={5}>
                                                <TableCell component="th" scope="row">Fecha de publicación</TableCell>
                                                <TableCell align="center">{proceso.vacante.fecha_publicacion}</TableCell>
                                            </TableRow>
                                            <TableRow key={6}>
                                                <TableCell component="th" scope="row">Fecha de vencimiento</TableCell>
                                                <TableCell align="center">{proceso.vacante.fecha_vencimiento}</TableCell>
                                            </TableRow>
                                            <TableRow key={7}>
                                                <TableCell component="th" scope="row">Salario</TableCell>
                                                <TableCell align="center">{"$"+ new Intl.NumberFormat().format(proceso.vacante.salario)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Container>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <BtnForm texto="CERRAR" callback={() => mostrarModalDetalleVacante(false)} />
                    </DialogActions>
                </Dialog>                            
            </div>
        )
    }

    pintar = () => {
        let { seleccion, cargando } = this.state;
        if (cargando) return <></>;
        return (
            <div>
                {seleccion === 'proceso' && this.pintarProceso()}
            </div>
        )
    }


    render() {
        return (
            <>
                {this.pintar()}
            </>
        );
    }
}

export default VacanteDetalle;