import React, { useState, useEffect } from "react";
import moment from "moment";

// Redux
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { actualizarMensaje } from "../../redux/actions/actGlobal";
import * as actionCreators from "../../redux/actions/actGenericas";
import {coloresEmma } from "../../global/js/funciones";
// Material UI - ICONS
import { ArrowRightAltRounded } from "@material-ui/icons";

// Material UI
import { makeStyles } from "@material-ui/core";

// Gloabl functions
import {
  crear_form_data,
  formulario,
  mostrarError,
  obtenerPermisosValor,
  obtenerValores,
  obtenerValoresFiltros,
  validarCorreo,
} from "../../global/js/funciones";

// Helpers
import {
  CARRERAS,
  DEPARTAMENTOS,
  DISCAPACIDADES_INSCRIPCIONES,
  EPS,
  EPS_OTRO,
  ESTADO_CIVIL,
  ESTADO_CIVIL_OTRO,
  ESTRATOS,
  GENERO,
  MAESTRIA_EDUCACION,
  OPCIONES_CONOCISTE_INSTITUCION,
  PAISES,
  RANGO_INGRESOS,
  TIEMPO_TRABAJO,
  TIPOS_IDENTIFICACION,
  VALIDACION_DOCUMENTOS_INSCRIPCIONES,
} from "./helper";

// General components
import TareasTerminadas from "../general/TareasTerminadas";
import ConfirmarAccion from "../general/ConfirmarAccion";

// Components
import InscripcionPosgradoFormulario from "./InscripcionFormPosgrado";
import InscripcionPosgradoInicio from "./InscripcionesInicioPosgrado";

// Images and documents
import emma_s from "../../global/imagenes/emma_s1.png";

const useStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffff",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    margin: theme.spacing(2, 2),
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(2),
    borderLeft:`3px solid ${coloresEmma.primarycolor}`,
  },

  title: {
    display: "flex",
    alignItems: "center",
    color: "#2c3e50",
  },

  icon: {
    marginRight: theme.spacing(1),
  },

  buttonAlert: {
    textDecoration: "none",
    color: "black",
    borderBottom: `1px solid ${coloresEmma.primarycolor}`,
  },

  buttonIcon: {
    display: "flex",
    alignItems: "center",
    color: coloresEmma.primarycolor,
    gap: theme.spacing(1),
  },

  info: {
    color: "#4c4d4f",
  },
}));

const InscripcionPosgradoAgregar = () => {
  const classes = useStyle();

  // Use selector - Acciones
  const { modalDel } = useSelector((state) => state.redGenericas);

  // Use dispatch - Acciones
  const dispatch = useDispatch();
  const { mostrarModalDel } = bindActionCreators(actionCreators, dispatch);

  // Use state - Formulario
  const [adjuntoIdentificacion, setAdjuntoIdentificacion] = useState("");
  const [adjuntoHojaVida, setAdjuntoHojaVida] = useState("");
  const [adjuntoDiplomaProfesional, setAdjuntoDiplomaProfesional] =
    useState("");
  const [adjuntoEnsayo, setAdjuntoEnsayo] = useState("");
  const [formatoMaestriaEducacion, setFormatoMaestriaEducacion] =
    useState("");

  const [barrio, setBarrio] = useState("");
  const [cargando, setCargando] = useState(true);
  const [carrera, setCarrera] = useState(null);
  const [carreras, setCarreras] = useState({});
  const [checkDiscapacidad, setCheckDiscapacidad] = useState(false);
  const [checkTrabaja, setCheckTrabaja] = useState(false);
  const [checkEstudioHomologacion, setCheckEstudioHomologacion] =
    useState(false);
  const [celular, setCelular] = useState("");
  const [correo, setCorreo] = useState("");
  const [direccion, setDireccion] = useState("");
  const [estrato, setEstrato] = useState("");
  const [estratos, setEstratos] = useState({});
  const [eps, setEps] = useState("");
  const [epsResp, setEpsResp] = useState({});
  const [otroEps, setOtroEps] = useState("");
  const [estadoCivilOtro, setEstadoCivilOtro] = useState("");
  const [departamento, setDepartamento] = useState("");
  const [departamentos, setDepartamentos] = useState({});
  const [ciudad, setCiudad] = useState("");
  const [ciudades, setCiudades] = useState({});
  const [ciudadNacimiento, setCiudadNacimiento] = useState("");
  const [discapacidad, setDiscapacidad] = useState("");
  const [discapacidades, setDiscapacidades] = useState({});
  const [error, setError] = useState({});
  const [estadoCivil, setEstadoCivil] = useState("");
  const [estadosCiviles, setEstadosCiviles] = useState({});
  const [fechaNacimiento, setFechaNacimiento] = useState(null);
  const [genero, setGenero] = useState("");
  const [generos, setGeneros] = useState({});
  const [guardado, setGuardado] = useState(false);
  const [iniciar, setIniciar] = useState(false);
  const [identificacion, setIdentificacion] = useState("");
  const [libretaMilitar, setLibretaMilitar] = useState("");
  const [nombreEmpresa, setNombreEmpresa] = useState("");
  const [actividadEmpresa, setActividadEmpresa] = useState("");
  const [direccionEmpresa, setDireccionEmpresa] = useState("");
  const [telefonoEmpresa, setTelefonoEmpresa] = useState("");
  const [cargoEmpresa, setCargoEmpresa] = useState("");
  const [tiempoTrabajo, setTiempoTrabajo] = useState("");
  const [tiemposTrabajo, setTiemposTrabajo] = useState({});
  const [rangoIngresos, setRangoIngresos] = useState("");
  const [rangosIngresos, setRangosIngresos] = useState({});
  const [opcionConocisteInstitucion, setOpcionConocisteInstitucion] =
    useState("");
  const [opcionesConocisteInstitucion, setOpcionesConocisteInstitucion] =
    useState([]);
  const [pais, setPais] = useState("");
  const [paises, setPaises] = useState({});
  const [primerNombre, setPrimerNombre] = useState("");
  const [primerApellido, setPrimerApellido] = useState("");
  const [segundoNombre, setSegundoNombre] = useState("");
  const [segundoApellido, setSegundoApellido] = useState("");
  const [telefono, setTelefono] = useState("");
  const [tipoIdentificacion, setTipoIdentificacion] = useState("");
  const [tiposIdentificacion, setTiposIdentificacion] = useState({});
  const [validacionDocumento, setValidacionDocumento] = useState(false);
  const [archivos, setArchivos] = useState([]);
  const [archivosMap, setArchivosMap] = useState({});
  const [observaciones, setObservaciones] = useState("");
  const [link_politica, setLink_politica] = useState("");

  const transformarData = (data) => {
    return data.map((item) => ({
      value: item.id,
      label: item.nombre,
      codigo: item.codigo,
      valord: item?.valord ?? "",
    }));
  };

  const transformarDataCiudad = (data) => {
    return data.map(({ secundario }) => ({
      value: secundario.id,
      label: secundario.nombre,
      codigo: secundario.codigo,
    }));
  };

  // Use effect

  useEffect(() => {
    cargarTipos();
  }, []);

  useEffect(() => {
    if (departamento) {
      obtenerCiudades();
    }

    if (checkTrabaja === false) {
      limpiarDatosTrabajo();
    }

    if (checkDiscapacidad === false) {
      setDiscapacidad("");
    }

    if (estadoCivil.value !== ESTADO_CIVIL_OTRO) {
      setEstadoCivilOtro("");
    }
  }, [departamento, checkTrabaja, checkDiscapacidad, estadoCivil]);

  const limpiarDatosTrabajo = () => {
    setNombreEmpresa("");
    setActividadEmpresa("");
    setDireccionEmpresa("");
    setTelefonoEmpresa("");
    setCargoEmpresa("");
    setTiempoTrabajo("");
    setRangoIngresos("");
  };

  /******************************************************************
   * * Cargar ciudades de acuerdo al departamento seleccionado *    *
   * @returns {void} - No retorna ningún valor                      *
   * @description - Carga las ciudades de acuerdo al departamento   *
   *                seleccionado en el state ciudades.              *
   *                                                                * 
   ******************************************************************
   */
  const obtenerCiudades = async () => {
    const ciudadesConsulta = await obtenerPermisosValor(departamento);
    setCiudades(transformarDataCiudad(ciudadesConsulta));
  };

  /****************************************************************************************************
   * * Cargar tipos de datos necesarios para el formulario: Países, tipos de identificación, etc. *   *
   * @returns {void} - No retorna ningún valor                                                        *
   * @description - Carga los datos necesarios para el formulario de inscripción                      *   
   *                de posgrados                                                                      *      
   * @async - Se utiliza la función de tipo asíncrona para poder utilizar                             *
   *          la función de obtenerValores y obtenerValoresFiltros.                                   *        
   * @function obtenerValores - Se utiliza para obtener los valores de las tablas                     *    
   *                            de la base de datos.                                                  *
   * @function obtenerValoresFiltros - Se utiliza para obtener los valores de las tablas              *
   *                                   de la base de datos con filtros.                               *  
   * @function transformarData - Se utiliza para transformar los datos obtenidos de la base de datos. *
   *                                                                                                  * 
   ****************************************************************************************************
   */
  const cargarTipos = async () => {
    const [
      validacionDocumentosConsulta,
      carreras,
      tiposIdentificacionConsulta,
      opcionesConocisteInstitucion,
      departamentosConsulta,
      discapacidades,
      estratosConsulta,
      paisesConsulta,
      generosConsulta,
      estadoCivilConsulta,
      tiemposTrabajoConsulta,
      rangosIngresosConsulta,
      epsResp,
    ] = await Promise.all([
      obtenerValores(VALIDACION_DOCUMENTOS_INSCRIPCIONES),
      obtenerValoresFiltros([
        { llave: "generica", valor: CARRERAS },
        { llave: "valorc", valor: "posgrado" },
        { llave: "valora", valor: "ofertado" },
      ]),
      obtenerValoresFiltros([
        { llave: "generica", valor: TIPOS_IDENTIFICACION },
        { llave: "valora", valor: "persona" },
      ]),
      obtenerValores(OPCIONES_CONOCISTE_INSTITUCION),
      obtenerValores(DEPARTAMENTOS),
      obtenerValores(DISCAPACIDADES_INSCRIPCIONES),
      obtenerValores(ESTRATOS),
      obtenerValores(PAISES),
      obtenerValores(GENERO),
      obtenerValores(ESTADO_CIVIL),
      obtenerValores(TIEMPO_TRABAJO),
      obtenerValores(RANGO_INGRESOS),
      obtenerValores(EPS)
    ]);

    setCarreras(transformarData(carreras));
    setTiposIdentificacion(transformarData(tiposIdentificacionConsulta));
    setOpcionesConocisteInstitucion(
      transformarData(opcionesConocisteInstitucion)
    );
    setPaises(transformarData(paisesConsulta));
    setGeneros(transformarData(generosConsulta));
    setEstratos(transformarData(estratosConsulta));
    setDepartamentos(transformarData(departamentosConsulta));
    setDiscapacidades(transformarData(discapacidades));
    setEstadosCiviles(transformarData(estadoCivilConsulta));
    setTiemposTrabajo(transformarData(tiemposTrabajoConsulta));
    setRangosIngresos(transformarData(rangosIngresosConsulta));
    setValidacionDocumento(
      validacionDocumentosConsulta
        .sort((a, b) => a.valorb - b.valorb)
        .map((item) => ({
          id: item.id,
          nombre: item.nombre,
          valora: item.valora,
          estado: item.estado,
        }))
    );
    setEpsResp(transformarData(epsResp));

    const politica = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Pol_Dat_Cuc' }]);
    setLink_politica(politica && politica[0] ? politica[0].archivo : '')

    setCargando(false);
  };

  /******************************************************************
   * * Validar formulario de inscripción *                          *
   * @param {number} step - Paso del formulario                     *
   * @returns {Boolean} - true si hay error, false si no hay error  *
   * @description - Valida los campos del formulario de inscripción *
   *                                                                *  
   ******************************************************************
   */
  const validarFormulario = (step) => {
    let validarError = {};
    if (step === 2) {
      validarError = {
        carrera: !carrera && "Seleccione carrera.",
        opcionConocisteInstitucion:
          !opcionConocisteInstitucion &&
          "Seleccione como conociste la institución.",
        nombreEmpresa:
          checkTrabaja && !nombreEmpresa && "Ingrese nombre de la empresa.",
        actividadEmpresa:
          checkTrabaja &&
          !actividadEmpresa &&
          "Ingrese actividad de la empresa.",
        direccionEmpresa:
          checkTrabaja &&
          !direccionEmpresa &&
          "Ingrese dirección de la empresa.",
        telefonoEmpresa:
          checkTrabaja && !telefonoEmpresa && "Ingrese teléfono de la empresa.",
        cargoEmpresa:
          checkTrabaja && !cargoEmpresa && "Ingrese cargo de la empresa.",
        tiempoTrabajo:
          checkTrabaja && !tiempoTrabajo && "Seleccione tiempo de trabajo.",
        rangoIngresos:
          checkTrabaja && !rangoIngresos && "Seleccione rango de ingresos.",
        adjuntoEnsayo:
          !adjuntoEnsayo &&
          (carrera?.valord === "doctorado" || carrera?.valord === "maestría")
            ? "Adjunte ensayo."
            : handleFileChange(document.getElementById("adjunto_ensayo").files),
        formatoMaestriaEducacion:
          !formatoMaestriaEducacion &&
          carrera?.valord === "maestría" &&
          carrera?.value === MAESTRIA_EDUCACION
            ? "Adjunte formato maestría educación."
            : handleFileChange(
                document.getElementById("adjunto_formato_maestria_educacion")
                  .files
              ),
      };
    } else if (step === 0) {
      validarError = {
        tipoIdentificacion:
          !tipoIdentificacion && "Seleccione tipo de identificación.",
        identificacion: !identificacion && "Digite identificación.",
        primerNombre: !primerNombre && "Ingrese primer nombre.",
        primerApellido: !primerApellido && "Ingrese primer apellido.",
        segundoApellido: !segundoApellido && "Ingrese segundo apellido.",
        celular: !celular && "Digite numero de celular.",
        telefono: !telefono && "Digite numero de telefono.",
        correo:
          (!correo && "Ingrese correo electrónico.") ||
          (!validarCorreo(correo) && "Ingrese un correo electrónico válido."),
        discapacidad:
          checkDiscapacidad &&
          !discapacidad &&
          "Seleccione el tipo de discapacidad.",
        pais: !pais && "Seleccione país.",
        genero: !genero && "Seleccione género.",
        ciudadNacimiento: !ciudadNacimiento && "Ingrese ciudad de nacimiento.",
        fechaNacimiento:
          (!fechaNacimiento && "Ingrese fecha de nacimiento.") ||
          (fechaNacimiento > new Date() && "Ingrese una fecha válida."),
        adjuntoIdentificacion: !adjuntoIdentificacion
          ? "Adjunte documento de identificación."
          : handleFileChange(
              document.getElementById("adjunto_identificacion").files
            ),
        adjuntoDiplomaProfesional: !adjuntoDiplomaProfesional
          ? "Adjunte diploma profesional."
          : handleFileChange(
              document.getElementById("adjunto_diploma_profesional").files
            ),
        adjuntoHojaVida: !adjuntoHojaVida
          ? "Adjunte hoja de vida."
          : handleFileChange(
              document.getElementById("adjunto_hoja_vida").files
            ),
      };
    } else if (step === 1) {
      validarError = {
        departamento: !departamento && "Seleccione departamento.",
        ciudad: !ciudad && "Seleccione ciudad.",
        barrio: !barrio && "Ingrese barrio.",
        direccion: !direccion && "Ingrese dirección.",
        estrato: !estrato && "Seleccione estrato.",
        eps: !eps && "Seleccione EPS.",
        otroEps: eps === EPS_OTRO && !otroEps && "Ingrese EPS.",
        estadoCivil: !estadoCivil && "Seleccione estado civil.",
        estadoCivilOtro:
          estadoCivil.value === ESTADO_CIVIL_OTRO &&
          !estadoCivilOtro &&
          "Ingrese estado civil.",
      };
    }

    const hasError = Object.values(validarError).some(Boolean);
    if (hasError) {
      setError(validarError);
      return true;
    }
  };

  /****************************************************************************
   * * Cargar archivos adjuntos de la inscripción *                           *
   * @param {files} dataFile - Archivos adjuntos de la inscripción            *      
   * @returns {void} - No retorna ningún valor                                *
   * @description - Carga los archivos adjuntos de la inscripción             *  
   *                en el state archivos y archivosMap para validar que no    *
   *                se repitan los archivos adjuntos.                         *
   *                                                                          *
   ****************************************************************************
   */
  const handleFileChange = (dataFile ) => {
    const files = dataFile;
    const nuevoAdjunto = Array.from(files);
    nuevoAdjunto.forEach((adjunto) => {
      if (!archivosMap[adjunto.name]) {
        archivosMap[adjunto.name] = true;
        archivos.push(adjunto);
      }
    });
    setArchivos([...archivos]);
    setArchivosMap({ ...archivosMap });
  };

  /****************************************************************************
   * * Guardar inscripción de posgrado *                                      *
   * @returns {void} - No retorna ningún valor                                *
   * @description - Guarda la inscripción de posgrado en la base de datos.    *
   *                En caso de que la inscripción se guarde correctamente,    *
   *                se muestra un mensaje de éxito y se cierra el modal.      *
   *                En caso de que la inscripción no se guarde correctamente, *
   *                se muestra un mensaje de error.                           *
   * @async - Se utiliza la función de tipo asíncrona para poder utilizar     *
   *          la función de crear_form_data y formulario.                     * 
   * @function crear_form_data - Se utiliza para crear un objeto de tipo      *
   *                             FormData para enviar los datos al backend.   *
   * @function formulario - Se utiliza para enviar los datos al backend.      * 
   * @function dispatch - Se utiliza para enviar los datos al store de redux. *
   *                                                                          *
   ****************************************************************************
   */
  const guardarInscripcionPosgrado = async () => {
    dispatch(
      actualizarMensaje({
        titulo: "Enviando datos, por favor espere...",
        tipo: "info",
        mostrar: true,
        tiempo: 10000,
      })
    );
    const usuario = correo.split("@")[0];
    const data = await crear_form_data({
      tipo_identificacion: tipoIdentificacion,
      identificacion,
      libreta_militar: libretaMilitar,
      primer_nombre: primerNombre,
      segundo_nombre: segundoNombre,
      primer_apellido: primerApellido,
      segundo_apellido: segundoApellido,
      correo,
      correo_personal: correo,
      carrera: carrera?.value ?? 0,
      conocio: opcionConocisteInstitucion,
      celular,
      telefono,
      departamento,
      ciudad,
      nombre_eps: eps,
      nombre_otro_eps: otroEps,
      lugar_residencia: barrio,
      estrato,
      direccion,
      estado_civil: estadoCivil.label,
      estado_civil_otro: estadoCivilOtro,
      usuario,
      checkDiscapacidad,
      discapacidad: checkDiscapacidad ? "1" : "0",
      check_trabaja: checkTrabaja ? "1" : "0",
      discapacidad_valor: discapacidad,
      pais_nacimiento: pais,
      lugar_nacimiento: ciudadNacimiento,
      genero,
      fecha_nacimiento: moment(fechaNacimiento).format("YYYY-MM-DD"),
      checkTrabaja,
      nombre_empresa: nombreEmpresa,
      actividad_empresa: actividadEmpresa,
      direccion_empresa: direccionEmpresa,
      telefono_empresa: telefonoEmpresa,
      cargo_empresa: cargoEmpresa,
      tiempo_trabajo: tiempoTrabajo,
      rango_ingresos: rangoIngresos,
      estudio_homologacion: checkEstudioHomologacion ? "1" : "0",
      total: archivos.length,
      observaciones
    });

    archivos.forEach((file, index) => {
      data.append(`archivos${index}`, file);
    });
    formulario(
      "api/v1.0/inscripciones_posgrado/crear",
      data,
      "post",
      async (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador";
        let tipo = "warning";

        if (!error) {
          if (estado === 201 || estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            setGuardado(true);
            dispatch(mostrarModalDel(false));
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        dispatch(
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
        );
      },
      false
    );
  };

  if (cargando) {
    return (
      <TareasTerminadas
        mensaje="Cargando..."
        marginTop="7%"
        imagen={emma_s}
        widthImg="7%"
        cargando={true}
      />
    );
  }

  /**************************************************************************************
   * * Mostrar formulario de inscripción de posgrado *                                  *
   * @param {Boolean} iniciar - Indica si el usuario ha iniciado el proceso             *
   * @returns {JSX} - Retorna un componente de tipo JSX.                                *
   * @description - Muestra el formulario de inscripción de posgrado.                   *
   *                En caso de que el usuario no haya iniciado el proceso               *
   *                de inscripción, se muestra el componente InscripcionPosgradoInicio. * 
   *                                                                                    * 
   **************************************************************************************
   */
  if (iniciar) {
    return (
      <div>
        <ConfirmarAccion
          mensaje2=""
          mensaje1={
            <div style={{ textAlign: "justify" }}>
              <span style={{ textAlign: "justify" }}>
                Al continuar usted acepta las políticas de protección de datos
                de la Universidad de la Costa.
                <div
                  className={classes.buttonIcon}
                  style={{ marginTop: "10px" }}
                >
                  <a
                    className={classes.buttonAlert}
                    href={link_politica}
                    target="_black"
                    rel="noopener noreferrer"
                  >
                    Ver políticas
                  </a>
                  <ArrowRightAltRounded />
                </div>
              </span>
            </div>
          }
          ejecutarAccion={guardarInscripcionPosgrado}
          mostrarModalConfAccion={mostrarModalDel}
          modalConAccion={modalDel}
          dato={{ id: 0 }}
          titulo={"¿Enviar datos?"}
        />
        <InscripcionPosgradoFormulario
          primerNombre={primerNombre}
          segundoNombre={segundoNombre}
          primerApellido={primerApellido}
          segundoApellido={segundoApellido}
          barrio={barrio}
          direccion={direccion}
          correo={correo}
          telefono={telefono}
          celular={celular}
          carrera={carrera}
          carreras={carreras}
          eps={eps}
          epsResp={epsResp}
          otroEps={otroEps}
          tipoIdentificacion={tipoIdentificacion}
          tiposIdentificacion={tiposIdentificacion}
          identificacion={identificacion}
          libretaMilitar={libretaMilitar}
          departamentos={departamentos}
          departamento={departamento}
          ciudad={ciudad}
          ciudades={ciudades}
          ciudadNacimiento={ciudadNacimiento}
          checkTrabaja={checkTrabaja}
          estadoCivil={estadoCivil}
          estadosCiviles={estadosCiviles}
          estadoCivilOtro={estadoCivilOtro}
          estratos={estratos}
          estrato={estrato}
          discapacidad={discapacidad}
          discapacidades={discapacidades}
          checkDiscapacidad={checkDiscapacidad}
          checkEstudioHomologacion={checkEstudioHomologacion}
          fechaNacimiento={fechaNacimiento}
          error={error}
          guardado={guardado}
          setError={setError}
          adjuntoIdentificacion={adjuntoIdentificacion}
          adjuntoHojaVida={adjuntoHojaVida}
          adjuntoDiplomaProfesional={adjuntoDiplomaProfesional}
          adjuntoEnsayo={adjuntoEnsayo}
          formatoMaestriaEducacion={formatoMaestriaEducacion}
          opcionesConocisteInstitucion={opcionesConocisteInstitucion}
          opcionConocisteInstitucion={opcionConocisteInstitucion}
          pais={pais}
          paises={paises}
          genero={genero}
          generos={generos}
          nombreEmpresa={nombreEmpresa}
          actividadEmpresa={actividadEmpresa}
          direccionEmpresa={direccionEmpresa}
          terminarProceso={mostrarModalDel}
          telefonoEmpresa={telefonoEmpresa}
          cargoEmpresa={cargoEmpresa}
          tiempoTrabajo={tiempoTrabajo}
          tiemposTrabajo={tiemposTrabajo}
          rangosIngresos={rangosIngresos}
          rangoIngresos={rangoIngresos}
          observaciones={observaciones}
          validacionDocumento={validacionDocumento}
          validarFormulario={validarFormulario}
          setTiempoTrabajo={setTiempoTrabajo}
          setRangoIngresos={setRangoIngresos}
          setCargoEmpresa={setCargoEmpresa}
          setCarreras={setCarreras}
          setEps={setEps}
          setOtroEps={setOtroEps}
          setNombreEmpresa={setNombreEmpresa}
          setActividadEmpresa={setActividadEmpresa}
          setDireccionEmpresa={setDireccionEmpresa}
          setTelefonoEmpresa={setTelefonoEmpresa}
          setCheckEstudioHomologacion={setCheckEstudioHomologacion}
          setCheckTrabaja={setCheckTrabaja}
          setLibretaMilitar={setLibretaMilitar}
          setEstadoCivilOtro={setEstadoCivilOtro}
          setEstadoCivil={setEstadoCivil}
          setFechaNacimiento={setFechaNacimiento}
          setPais={setPais}
          setCiudadNacimiento={setCiudadNacimiento}
          setGenero={setGenero}
          setDireccion={setDireccion}
          setEstratos={setEstratos}
          setEstrato={setEstrato}
          setBarrio={setBarrio}
          setDepartamentos={setDepartamentos}
          setDepartamento={setDepartamento}
          setCiudades={setCiudades}
          setCiudad={setCiudad}
          setPrimerNombre={setPrimerNombre}
          setSegundoNombre={setSegundoNombre}
          setPrimerApellido={setPrimerApellido}
          setSegundoApellido={setSegundoApellido}
          setCorreo={setCorreo}
          setTelefono={setTelefono}
          setCelular={setCelular}
          setCarrera={setCarrera}
          setTipoIdentificacion={setTipoIdentificacion}
          setIdentificacion={setIdentificacion}
          setDiscapacidad={setDiscapacidad}
          setCheckDiscapacidad={setCheckDiscapacidad}
          setAdjuntoIdentificacion={setAdjuntoIdentificacion}
          setAdjuntoHojaVida={setAdjuntoHojaVida}
          setAdjuntoDiplomaProfesional={setAdjuntoDiplomaProfesional}
          setAdjuntoEnsayo={setAdjuntoEnsayo}
          setFormatoMaestriaEducacion={setFormatoMaestriaEducacion}
          setOpcionConocisteInstitucion={setOpcionConocisteInstitucion}
          setValidacionDocumento={setValidacionDocumento}
          setObservaciones={setObservaciones}
          />
      </div>
    );
  } else {
    return (
      <InscripcionPosgradoInicio
        accion={() => setIniciar(true)}
      />
    );
  }
};

export default InscripcionPosgradoAgregar;
