import moment from 'moment'
import React, { Component } from 'react'
import ReactExport from "react-export-excel";
import GetAppIcon from '@material-ui/icons/GetApp';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from "../../../global/imagenes/emma_w.png";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export default class ExportarReporteInter extends Component {

  renderExcel() {
    let { data, nombre, columnas, lideres, modal } = this.props
    // const columnasSinTableData = columnas.map(({ tableData, ...rest }) => rest);
    // const filasSinTableData = data.map(({ tableData, ...rest }) => rest);

    // Log para verificar el resultado
    // console.log(columnasSinTableData);
    // const multiDataSet = [
    //   // {
    //   //   xSteps: 2,
    //   //   ySteps: 0,
    //   //   columns: [
    //   //     // { title: "Columna fija 1", width: { wch: 23 }, font: { sz: "11", bold: true, name: 'Arial Narrow' }, style: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } }, // Ancho de la columna
    //   //     "Columna fija 2",
    //   //     // "Columna fija 3",
    //   //     // "Columna fija 4",
    //   //     // "Columna fija 5"
    //   //   ],
    //   //   data: [
    //   //     [
    //   //       { value: "Fila fija 1 (ocupa 4 columnas)", style: { fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } } } }, // Celda fusionada
    //   //       // "", // Vacía para la fusión
    //   //       // "", // Vacía para la fusión
    //   //       // "", // Vacía para la fusión
    //   //       // "", // Vacía para la fusión
    //   //     ],
    //   //     // Puedes agregar más filas aquí
    //   //   ],
    //   //   mergeCells: [
    //   //     { start: { row: 0, column: 0 }, end: { row: 0, column: 3 } } // Fusión desde columna 1 a la columna 4 en la fila 1 (índice 0)
    //   //   ]
    //   // },
    //   {
    //     xSteps: 0, // Cuántas columnas dejar antes de comenzar con los datos dinámicos
    //     ySteps: 0, // Cuántas filas dejar antes de comenzar con los datos dinámicos
    //     columns: columnasSinTableData.map(({ title }) => title), // Generación de columnas dinámicas
    //     data: filasSinTableData.map((e) => columnasSinTableData.map(({ field }) => e[field])) // Llenado de datos dinámicos
    //   }
    // ];

    // console.log(columnas)

    const multiDataSet = [{
      columns: columnas.map(({ title }) => title),
      data: data.map((e) => columnas.map(({ field }) => e[field]))
    }];



    return (
      <ExcelFile filename={`${nombre}-${moment().format('YYYY-MM-DD, h:mm a')}`} element={!lideres ?
        <Tooltip title='Descargar' aria-label="add"><IconButton edge="end" color="secondary"><GetAppIcon /></IconButton></Tooltip>
        :
        <>
          <TareasTerminadas mensaje='Para descargar el consolidado, haz clic en el botón que aparece a continuación' marginTop='5%' imagen={emma_w} widthImg="13%" />
          <div style={{ textAlign: 'center', position: 'sticky', bottom: '0', zIndex: '1000', backgroundColor: 'white', display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" color="primary" style={{ borderRadius: '20px', color: 'white' }} onClick={modal}  >DESCARGAR</Button>
          </div>
        </>
      }>
        <ExcelSheet dataSet={multiDataSet} name="Organization" />
      </ExcelFile >
    )
  }

  render() {
    return (
      this.renderExcel()
    )
  }
}
