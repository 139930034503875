import React, { Component } from 'react';
import { Dialog, DialogContent, DialogActions, Grid, Container, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import AppBarModal from '../../general/AppBarModal'
import { BtnForm } from '../../general/BotonesAccion'

class VacanteDetalleLista extends Component {
    render() {
        let { proceso, modalDetalleVacante, mostrarModalDetalleVacante } = this.props
        return (
            <div>
                <Dialog open={modalDetalleVacante} onClose={() => mostrarModalDetalleVacante(false)} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
                    <AppBarModal titulo={"Detalle de la vacante"} mostrarModal={() => mostrarModalDetalleVacante(false)} titulo_accion="" />
                    <DialogContent style={{ padding: 10, overflowX: 'hidden' }} className="scroll">
                        <div>
                            <Container maxWidth="sm">
                                <Grid
                                    container
                                    spacing={3}
                                    direction='column'
                                    justify='center'
                                >
                                    <Table>
                                        <TableBody>
                                            {
                                                proceso.tipo_oferta && proceso.tipo_oferta.codigo === 'Vac_Lab' ?
                                                    <>
                                                        <TableRow key={4}>
                                                            <TableCell component="th" scope="row">Codigo de vacante</TableCell>
                                                            <TableCell align="center">{proceso.id}</TableCell>
                                                        </TableRow>
                                                        <TableRow key={1}>
                                                            <TableCell component="th" scope="row">Título de la vacante</TableCell>
                                                            <TableCell align="center">{proceso.titulo}</TableCell>
                                                        </TableRow>
                                                        {proceso.tipo_de_contrato ?
                                                            <TableRow key={32}>
                                                                <TableCell component="th" scope="row">Típo de contrato</TableCell>
                                                                <TableCell align="center">{proceso.tipo_de_contrato}</TableCell>
                                                            </TableRow>
                                                            :
                                                            <TableRow key={32}>
                                                            </TableRow>
                                                        }
                                                        {proceso.solicitud_de_vacante ?
                                                            <TableRow key={31}>
                                                                <TableCell component="th" scope="row">Típo de vacante</TableCell>
                                                                <TableCell align="center">{proceso.solicitud_de_vacante}</TableCell>
                                                            </TableRow>
                                                            :
                                                            <TableRow key={31}>
                                                            </TableRow>
                                                        }
                                                    </>
                                                    : <>
                                                        <TableRow key={4}>
                                                            <TableCell component="th" scope="row">Codigo de práctica</TableCell>
                                                            <TableCell align="center">{proceso.id}</TableCell>
                                                        </TableRow>
                                                        <TableRow key={30}>
                                                            <TableCell component="th" scope="row">Codigo de prestador</TableCell>
                                                            <TableCell align="center">{proceso.codigo_prestador}</TableCell>
                                                        </TableRow>
                                                        <TableRow key={29}>
                                                            <TableCell component="th" scope="row">Título de la práctica laboral</TableCell>
                                                            <TableCell align="center">{proceso.titulo_practica}</TableCell>
                                                        </TableRow>
                                                        <TableRow key={28}>
                                                            <TableCell component="th" scope="row">Típo de contrato</TableCell>
                                                            <TableCell align="center">{proceso.tipo_de_contrato_practica}</TableCell>
                                                        </TableRow>
                                                    </>
                                            }
                                            <TableRow key={2}>
                                                <TableCell component="th" scope="row">Descripción de la vacante</TableCell>
                                                <TableCell align="center">{proceso.tipo_oferta && proceso.tipo_oferta.codigo === 'Vac_Lab' ? proceso.descripcion : proceso.descripcion_actividades}</TableCell>
                                            </TableRow>
                                            {
                                                proceso.tipo_oferta && proceso.tipo_oferta.codigo === 'Vac_Lab' &&
                                                <>
                                                    <TableRow key={3}>
                                                        <TableCell component="th" scope="row">Experiencia relacionada(años)</TableCell>
                                                        <TableCell align="center">{proceso.anos}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={4}>
                                                        <TableCell component="th" scope="row">Meses</TableCell>
                                                        <TableCell align="center">{proceso.meses}</TableCell>
                                                    </TableRow>

                                                    <TableRow key={5}>
                                                        <TableCell component="th" scope="row">Cantidad de vacantes</TableCell>
                                                        <TableCell align="center">{proceso.n_vacantes}</TableCell>
                                                    </TableRow>
                                                </>
                                            }

                                            <TableRow key={6}>
                                                <TableCell component="th" scope="row">Cargo</TableCell>
                                                <TableCell align="center">{proceso.nombre_cargo}</TableCell>
                                            </TableRow>

                                            <TableRow key={7}>
                                                <TableCell component="th" scope="row">Tipo de documento del empleador</TableCell>
                                                <TableCell align="center">{proceso.tipo_documento}</TableCell>
                                            </TableRow>
                                            <TableRow key={8}>
                                                <TableCell component="th" scope="row">Identificación</TableCell>
                                                <TableCell align="center">{proceso.identificacion}</TableCell>
                                            </TableRow>
                                            <TableRow key={9}>
                                                <TableCell component="th" scope="row">Razón social</TableCell>
                                                <TableCell align="center">{proceso.razon_social}</TableCell>
                                            </TableRow>
                                            <TableRow key={10}>
                                                <TableCell component="th" scope="row">Fecha de publicación</TableCell>
                                                <TableCell align="center">{proceso.fecha_publicacion}</TableCell>
                                            </TableRow>
                                            <TableRow key={11}>
                                                <TableCell component="th" scope="row">Fecha de vencimiento</TableCell>
                                                <TableCell align="center">{proceso.fecha_vencimiento}</TableCell>
                                            </TableRow>
                                            {
                                                proceso.tipo_oferta && proceso.tipo_oferta.codigo === 'Vac_Lab' &&
                                                <>
                                                    <TableRow key={12}>
                                                        <TableCell component="th" scope="row">Nivel de estudios requeridos</TableCell>
                                                        <TableCell align="center">{proceso.nivel_estudio}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={13}>
                                                        <TableCell component="th" scope="row">Profesión</TableCell>
                                                        <TableCell align="center">{proceso.profesion}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={14}>
                                                        <TableCell component="th" scope="row">Salario/ingreso</TableCell>
                                                        <TableCell align="center">{proceso.salario}</TableCell>
                                                    </TableRow>
                                                </>
                                            }

                                            <TableRow key={15}>
                                                <TableCell component="th" scope="row">{proceso.tipo_oferta && proceso.tipo_oferta.codigo === 'Vac_Lab' ? 'Municipio' : 'Codigo municipio'}</TableCell>
                                                <TableCell align="center">{proceso.municipio}</TableCell>
                                            </TableRow>

                                            <TableRow key={16}>
                                                <TableCell component="th" scope="row">Sector económico</TableCell>
                                                <TableCell align="center">{proceso.sector_economico}</TableCell>
                                            </TableRow>
                                            <TableRow key={28}>
                                                <TableCell component="th" scope="row">Estado de la solicitud</TableCell>
                                                <TableCell align="center">{proceso.estado_solicitud && proceso.estado_solicitud.nombre}</TableCell>
                                            </TableRow>
                                            <TableRow key={28}>
                                                <TableCell component="th" scope="row">Nombre del responsable</TableCell>
                                                <TableCell align="center">{proceso.nombre_responsable && proceso.nombre_responsable}</TableCell>
                                            </TableRow>
                                            <TableRow key={28}>
                                                <TableCell component="th" scope="row">Correo del responsable</TableCell>
                                                <TableCell align="center">{proceso.correo && proceso.correo}</TableCell>
                                            </TableRow>
                                            <TableRow key={28}>
                                                <TableCell component="th" scope="row">Telefono del responsable</TableCell>
                                                <TableCell align="center">{proceso.telefono && proceso.telefono}</TableCell>
                                            </TableRow>
                                            {
                                                proceso.tipo_oferta && proceso.tipo_oferta.codigo === 'Vac_Pra' &&
                                                <>
                                                    <TableRow key={17}>
                                                        <TableCell component="th" scope="row">Tiempo de experiencia relacionada</TableCell>
                                                        <TableCell align="center">{proceso.tiempo_experiencia}</TableCell>
                                                    </TableRow>

                                                    <TableRow key={18}>
                                                        <TableCell component="th" scope="row">Modalidad de formación requerida</TableCell>
                                                        <TableCell align="center">{proceso.modalidad_formacion}</TableCell>
                                                    </TableRow>

                                                    <TableRow key={19}>
                                                        <TableCell component="th" scope="row">Área de conocimiento o programa académico requerido</TableCell>
                                                        <TableCell align="center">{proceso.area_programa_req}</TableCell>
                                                    </TableRow>

                                                    <TableRow key={20}>
                                                        <TableCell component="th" scope="row">Valor del auxilio de práctica</TableCell>
                                                        <TableCell align="center">{proceso.valor_auxilio}</TableCell>
                                                    </TableRow>

                                                    <TableRow key={21}>
                                                        <TableCell component="th" scope="row">Número de plazas de práctica laboral</TableCell>
                                                        <TableCell align="center">{proceso.numero_plazas}</TableCell>
                                                    </TableRow>

                                                    <TableRow key={22}>
                                                        <TableCell component="th" scope="row">Ocupación relacionada con las actividades formativas</TableCell>
                                                        <TableCell align="center">{proceso.ocupacion_relacionada.valora}</TableCell>
                                                    </TableRow>

                                                    <TableRow key={23}>
                                                        <TableCell component="th" scope="row">Solicitud de excepción de publicación por parte de la entidad pública o privada</TableCell>
                                                        <TableCell align="center">{proceso.solicitud_excepcion}</TableCell>
                                                    </TableRow>

                                                    <TableRow key={24}>
                                                        <TableCell component="th" scope="row">Tipo de Contrato</TableCell>
                                                        <TableCell align="center">{proceso.tipo_contrato}</TableCell>
                                                    </TableRow>

                                                    <TableRow key={25}>
                                                        <TableCell component="th" scope="row">Teletrabajo</TableCell>
                                                        <TableCell align="center">{proceso.teletrabajo}</TableCell>
                                                    </TableRow>

                                                    <TableRow key={26}>
                                                        <TableCell component="th" scope="row">Discapacidad</TableCell>
                                                        <TableCell align="center">{proceso.discapacidad}</TableCell>
                                                    </TableRow>

                                                    <TableRow key={27}>
                                                        <TableCell component="th" scope="row">URL al detalle de la práctica laboral</TableCell>
                                                        <TableCell align="center"><a href={`${proceso.detalle_practica}`} target="_blank" className='sin_decorador'>{proceso.detalle_practica}</a></TableCell>
                                                    </TableRow>
                                                    <TableRow key={28}>
                                                        <TableCell component="th" scope="row">Estado de la solicitud</TableCell>
                                                        <TableCell align="center">{proceso.estado_solicitud && proceso.estado_solicitud.nombre}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={28}>
                                                        <TableCell component="th" scope="row">Nombre del responsable</TableCell>
                                                        <TableCell align="center">{proceso.nombre_responsable && proceso.nombre_responsable}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={28}>
                                                        <TableCell component="th" scope="row">Correo del responsable</TableCell>
                                                        <TableCell align="center">{proceso.correo && proceso.correo}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={28}>
                                                        <TableCell component="th" scope="row">Telefono del responsable</TableCell>
                                                        <TableCell align="center">{proceso.telefono && proceso.telefono}</TableCell>
                                                    </TableRow>
                                                </>
                                            }
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Container>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <BtnForm texto="CERRAR" callback={() => mostrarModalDetalleVacante(false)} />
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default VacanteDetalleLista;