import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogActions, Fab, makeStyles } from '@material-ui/core';
import { mostrarModalAddInstituciones } from '../../../redux/actions/actInternacionalizacion';
import { actualizarMensaje } from '../../../redux/actions/actGlobal';
import { BtnCancelar, BtnForm, BtnSeleccion } from '../../general/BotonesAccion';
import { consulta } from '../../../global/js/funciones';
import { connect } from 'react-redux';
import TareasTerminadas from '../../general/TareasTerminadas';
import AppBarModal from '../../general/AppBarModal';
import emma_w from '../../../global/imagenes/emma_w.png';
import AddIcon from '@material-ui/icons/Add';
import ListarDatos from "../../general/ListarDatos";

const useStyles = makeStyles(theme => ({
  fabButton: {
    position: 'fixed',
    left: "45%",
    zIndex: 1000,
    top: '83%',
    [theme.breakpoints.up('sm')]: {
      top: '89%',
      left: "50%",
    },
  }
}));

function Agregar({ actAgregar }) {
  const classes = useStyles();
  return (
    <Fab color = "secondary" aria-label = "add" className = { classes.fabButton } onClick = { () => actAgregar() }>
      <AddIcon />
    </Fab>
  )
}

class ConvocatoriasBuscarConvenio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dato_buscar: '',
      pais_filtro: '',
      paises: [],
      universidades: [],
      tipos_instituciones: [],
      cargando: false,
      cargando_pais: false,
      modal_filtro_pais: false,
    }
  }

  componentDidUpdate(prevProps,prevState) {
    if (this.state.dato_buscar !== prevState.dato_buscar) {
      this.obtenerConvenios(this.state.dato_buscar)
    }
  }

  obtenerConvenios = (dato) => {
    this.setState({ cargando: true })
    if (dato.length >= 3) {
      consulta("api/v1.0/internacionalizacion/convenios/buscar", {dato}, "post",
        (error, estado, resp) => {
          if (estado === 200) {
            this.setState({ cargando: false, universidades: resp })
          }
        }
      );
    } this.setState({ cargando: false, universidades: [] });
  }

  acciones = (data) => {
    let { seleccionadas, noMulti } = this.props;
    const onClickSeleccionar = (data) => {
      console.log(data)
      this.props.seleccionar(data)
    }
    const onClickQuitar = (data) => {
      this.props.quitar(data)
    }

    return (
      seleccionadas && seleccionadas.length > 0 && seleccionadas.map(u => u.id).includes(data.id)
        ? <BtnCancelar callback={() => onClickQuitar(data)} />
        : noMulti && seleccionadas.length === 1
          ? null
          : <BtnSeleccion callback={() => onClickSeleccionar(data)} />
    );
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({ [name]: value });
  }

  render () {
    let { modalBuscarUniversidad, mostrarModalBuscarUniversidad, mensajeVacio, buscarMovilidad, dataBuscarMovilidad } = this.props;
    let { universidades, cargando, dato_buscar } = this.state;
    return (
      <>
        <Dialog open = { modalBuscarUniversidad } onClose = { mostrarModalBuscarUniversidad.bind(this, false) } aria-labelledby = "form-dialog-title" fullWidth = { true } maxWidth = "sm">
          <AppBarModal titulo = { '¡ Buscar Universidad !' } mostrarModal = { mostrarModalBuscarUniversidad } titulo_accion = "" accion = "" />
          <DialogContent className = 'scroll' style = { { padding: 0 } }>
            { cargando ? <TareasTerminadas mensaje = 'Cargando...' marginTop = '7%' imagen = { emma_w } widthImg = "7%" /> :
                <ListarDatos
                  id='tbl_universidades_seleccion'
                  titulo={'Lista de Universidades'}
                  datos={buscarMovilidad ? dataBuscarMovilidad : universidades}
                  opciones={true}
                  buscar={true}
                  cargando={cargando}
                  mensajeVacio={mensajeVacio ? mensajeVacio : 'Esta bandeja esta vacía, ingrese dato a buscar'}
                  avatarImg={true}
                  css={{
                    list: { padding: '0px 0px 20px 0px' },
                    appBar: { padding: '0px 0px 0px 0px' },
                  }}
                  actBuscar={(dato_buscar) => this.setState({ dato_buscar })}
                  datoBuscarAlt={dato_buscar}
                  acciones={(row) => this.acciones(row)}
                  fila_principal={({ institucion }) => institucion.toUpperCase()}
                  filas={[
                    {'nombre': 'Código del convenio: ', 'id': 'codigo_inter', 'mostrar': ({ codigo_convenio }) => codigo_convenio ? codigo_convenio :"No registra"},
                    {'nombre': 'País: ', 'id': 'pais', 'mostrar': ({ pais_vinculante }) => pais_vinculante ? pais_vinculante.nombre :"No registra"},
                    {
                      'nombre': 'Tipos de convenio: ',
                      'id': 'tipoConvenio',
                      'mostrar': ({ tipoConvenio }) => (
                        tipoConvenio.length > 0
                          ? tipoConvenio.map(a => a.tipo_convenio_convenio.nombre).join(", ")
                          : "No registra"
                      )
                    },
                    {
                      'nombre': 'Tipos de actividades: ',
                      'id': 'actividades',
                      'mostrar': ({ actividades }) => (
                        actividades.length > 0
                          ? actividades.map(a => a.tipo_actividad.nombre).join(", ")
                          : "No registra"
                      )
                    }
                    
                    // {'nombre': 'Ciudad: ', 'id': 'ciudad', 'mostrar': ({ ciudad_vinculante }) => ciudad_vinculante ? ciudad_vinculante :"No registra"},
                  ]}
                />
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto = "CERRAR" callback = { () => mostrarModalBuscarUniversidad(false) } />
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = state => {
  let { modalAddInstituciones } = state.redInternacionalizacion;
  return { modalAddInstituciones }
}

const mapDispatchToProps = { mostrarModalAddInstituciones, actualizarMensaje }

ConvocatoriasBuscarConvenio.propTypes = {
  actualizarMensaje: PropTypes.func.isRequired,
  modalBuscarUniversidad: PropTypes.bool.isRequired,
  modalAddInstituciones: PropTypes.bool.isRequired,
  mostrarModalBuscarUniversidad: PropTypes.func.isRequired,
  mostrarModalAddInstituciones: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConvocatoriasBuscarConvenio);
