// variable local
// export const AREAS_INTERES = 77
// export const ESTADOS_VACANTE_PORTAL = 78
// export const ESTADOS_PROCESO_PORTAL = 79
// export const ESTADOS_CIERRE_VACANTE_PORTAL = 80

// variables emmatest
// export const AREAS_INTERES = 3034
// export const ESTADOS_VACANTE_PORTAL = 3032
// export const ESTADOS_PROCESO_PORTAL = 3033
// export const ESTADOS_CIERRE_VACANTE_PORTAL = 

// variables emma
export const AREAS_INTERES =  95; //204
export const ESTADOS_VACANTE_PORTAL = 96; // 205
export const ESTADOS_PROCESO_PORTAL =  97; // 206
export const ESTADOS_CIERRE_VACANTE_PORTAL = 98; // 207
export const REQUISITOS_PORTAL = 301; // 4232 

export const PROGRAMAS = 5;

// export const TIPOS_VACANTES = 3048;
// export const OCUPACIONES = 4048;
// export const DOCUMENTOS_INFORMATIVOS = 5048;

export const TIPOS_VACANTES = 196; //208;
export const OCUPACIONES = 197; //209;
export const DOCUMENTOS_INFORMATIVOS=198; //210;

export const CODIGO_PRESTADOR = '22162';