import React, { useState, useRef } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { actualizarMensaje } from "../../redux/actions/actGlobal";
import { mostrarModalMensajeValidacionId } from "../../redux/actions/actInscripcionesPosgrado";
import { coloresEmma } from "../../global/js/funciones";
//
import DateFnsUtils from "@date-io/date-fns";
import { es } from "date-fns/locale";

// Material UI
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

// Material UI Icons
import { InfoRounded } from "@material-ui/icons";

// General components
import AppBarModal from "../general/AppBarModal";
import TareasTerminadas from "../general/TareasTerminadas";
import InputFile from "../general/InputFile";
import { BtnForm } from "../general/BotonesAccion";

// Globla functions
import { consulta, generarFiltros } from "../../global/js/funciones";

// Images
import emma_w from "./../../global/imagenes/emma_w.png";

/********************************************************************************
 * * Componente que muestra el modal de mensaje de validación de identificación *
 * @param {Boolean} {modal} - Estado del modal                                  *
 * @param {Function} {mostraModal} - Función para mostrar el modal              *
 * @param {String} {mensaje} - Mensaje a mostrar en el modal                    *
 * @param {Boolean} {cargando} - Estado de carga                                *
 * @returns {JSX} - Modal de mensaje de validación de identificación            *
 * @description - Muestra el modal de mensaje de validación de identificación   *
 *              de identificación                                               *
 * ******************************************************************************
 */
const MensajeValidacionId = ({ modal, mostraModal, mensaje, cargando }) => {
  return (
    <Dialog
      open={modal}
      maxWidth={"sm"}
      fullWidth={true}
      onClose={() => mostraModal(false)}
    >
      <AppBarModal
        titulo="! Mensaje ¡"
        mostrarModal={mostraModal}
        titulo_accion=""
      />
      <DialogContent className="scroll">
        {cargando ? (
          <TareasTerminadas
            mensaje={
              "Espere un momento. Estamos validando su numero de identificación"
            }
            marginTop="7%"
            imagen={emma_w}
            widthImg="7%"
            cargando={true}
          />
        ) : (
          <Typography
            variant="body1"
            color="textSecondary"
            component="p"
            align="justify"
          >
            {mensaje}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <BtnForm texto="Cerrar" callback={() => mostraModal(false)} />
      </DialogActions>
    </Dialog>
  );
};

const InscripcionPosgradoDatos = ({ propsDatos }) => {
  const [cargando, setCargando] = useState(true);
  const [mensaje, setMensaje] = useState("");

  const fileInput = useRef(null);

  const dispatch = useDispatch();

  const { modalValidacionId } = useSelector(
    (state) => state.redInscripcionesPosgrado
  );

  /*******************************************************************************
   * * Función que llena los campos del formulario con los datos del estudiante  *
   * @param {Object} datos - Datos del estudiante                                *
   * @returns {void} - No retorna ningún valor                                   *
   * @description - Llena los campos del formulario con los datos del estudiante *
   *                                                                             *
   *******************************************************************************
   */
  const llenarCampos = (datos = null) => {
    if (datos) {
      propsDatos.setTipoIdentificacion(datos.estudiante.tipo_identificacion?.id ?? "");
      propsDatos.setLibretaMilitar(datos?.estudiante?.libreta_militar ?? "");
      propsDatos.setPrimerNombre(datos?.estudiante?.primer_nombre ?? "");
      propsDatos.setSegundoNombre(datos?.estudiante?.segundo_nombre ?? "");
      propsDatos.setPrimerApellido(datos?.estudiante?.primer_apellido ?? "");
      propsDatos.setSegundoApellido(datos?.estudiante?.segundo_apellido ?? "");
      propsDatos.setTelefono(datos?.telefono ?? "");
      propsDatos.setCelular(datos?.celular ?? "");
      propsDatos.setCorreo(datos?.estudiante?.correo ?? "");
      propsDatos.setDiscapacidad(datos?.discapacidad?.id ?? "");
      propsDatos.setCheckDiscapacidad(datos?.discapacidad?.id ? true : false);
      propsDatos.setGenero(datos?.estudiante?.genero?.id ?? "");
      propsDatos.setPais(datos?.pais_nacimiento?.id ?? "");
      propsDatos.setCiudadNacimiento(datos?.lugar_nacimiento ?? "");
      propsDatos.setFechaNacimiento(datos?.estudiante?.fecha_nacimiento ?? null);
      propsDatos.setCiudadNacimiento(datos?.estudiante?.lugar_nacimiento ?? "");
      propsDatos.setCheckDiscapacidad(datos?.estudiante?.discapacidad === "1" ? true : false);
      propsDatos.setDiscapacidad(datos?.discapacidad_valor?.id ?? "");
      propsDatos.setDepartamento(datos?.estudiante?.departamento?.id ?? "");
      propsDatos.setCiudad(datos?.estudiante?.ciudad.id ?? "");
      propsDatos.setBarrio(datos?.estudiante?.lugar_residencia ?? "");
      propsDatos.setDireccion(datos?.estudiante?.direccion ?? "");
      propsDatos.setEstrato(datos?.estrato?.id ?? "");
      propsDatos.setEstadoCivil(datos?.estudiante?.estado_civil ?? "");
      propsDatos.setEstadoCivilOtro(datos?.estudiante?.estado_civil_otro ?? "");
      propsDatos.setOpcionConocisteInstitucion(datos?.conocio?.id ?? "");
      propsDatos.setCheckEstudioHomologacion(datos?.estudio_homologacion === "1" ? true : false);
      propsDatos.setCheckTrabaja(datos?.check_trabaja === "1" ? true : false);
      propsDatos.setNombreEmpresa(datos?.nombre_empresa ?? "");
      propsDatos.setCargoEmpresa(datos?.cargo_empresa ?? "");
      propsDatos.setActividadEmpresa(datos?.actividad_empresa ?? "");
      propsDatos.setDireccionEmpresa(datos?.direccion_empresa ?? "");
      propsDatos.setTelefonoEmpresa(datos?.telefono_empresa ?? "");
      propsDatos.setTiempoTrabajo(datos?.tiempo_trabajo?.id ?? "");
      propsDatos.setRangoIngresos(datos?.rango_ingresos?.id ?? "");
      propsDatos.setEps(datos?.nombre_eps?.id ?? "");
      propsDatos.setOtroEps(datos?.nombre_otro_eps ?? "");
    } else {
      propsDatos.setPrimerNombre("");
      propsDatos.setSegundoNombre("");
      propsDatos.setPrimerApellido("");
      propsDatos.setSegundoApellido("");
      propsDatos.setTelefono("");
      propsDatos.setCelular("");
      propsDatos.setCorreo("");
      propsDatos.setDiscapacidad("");
      propsDatos.setDepartamento("");
      propsDatos.setCiudad("");
      propsDatos.setBarrio("");
      propsDatos.setDireccion("");
      propsDatos.setEstrato("");
      propsDatos.setEstadoCivilOtro("");
      propsDatos.setGenero("");
      propsDatos.setOpcionConocisteInstitucion("");
      propsDatos.setCarrera("");
      propsDatos.setPais("");
      propsDatos.setCiudadNacimiento("");
      propsDatos.setFechaNacimiento(null);
      propsDatos.setNombreEmpresa("");
      propsDatos.setCargoEmpresa("");
      propsDatos.setActividadEmpresa("");
      propsDatos.setDireccionEmpresa("");
      propsDatos.setTelefonoEmpresa("");
      propsDatos.setTiempoTrabajo("");
      propsDatos.setRangoIngresos("");
      propsDatos.setLibretaMilitar("");
      propsDatos.setEstadoCivil("");
      propsDatos.setCheckEstudioHomologacion(false);
      propsDatos.setCheckDiscapacidad(false);
      propsDatos.setCheckTrabaja(false);
      propsDatos.setEps("");
      propsDatos.setOtroEps("");
    }
  };

  /*******************************************************************************
   * * Función que valida si la identificación del estudiante ya se encuentra registrada en la plataforma *
   * @param {number} {target} - Identificación del estudiante
   * @returns {void} - No retorna ningún valor
   * @description - Valida si la identificación del estudiante ya se encuentra
   *                registrada en la plataforma
   *
   ********************************************************************************
   */
  const validarIdentificacion = async ({ target }) => {
    let identificacion = target.value;
    if (identificacion) {
      setMensaje("");
      setCargando(true);
      dispatch(mostrarModalMensajeValidacionId(true));
      let filtro = await generarFiltros([
        {
          llave: "estudiante__identificacion",
          valor: propsDatos.identificacion,
        },
      ]);
      consulta(
        `api/v1.0/inscripciones_posgrado/validar_identificacion?${filtro}`,
        null,
        null,
        (error, estado, resp) => {
          let titulo = "Ha ocurrdo un error, contacte con el administrador.";
          let tipo = "warning";

          if (!error) {
            if (estado === 200) {
              if (resp.length > 0) {
                setMensaje(
                  `¡Ya estas registrado en nuestra plataforma!. Para continuar: Revisa tus datos y actualizalos.`
                );
                setCargando(false);
                llenarCampos(resp[0]);
              } else {
                llenarCampos();
                setMensaje(
                  `Tu identificación es válida para la inscripción. Puedes continuar.`
                );
                setCargando(false);
              }
            }
          } else {
            dispatch(
              actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
            );
          }
        },
        false
      );
    }
  };

  return (
    <>
      <MensajeValidacionId
        modal={modalValidacionId}
        mostraModal={(value) =>
          dispatch(mostrarModalMensajeValidacionId(value))
        }
        mensaje={mensaje}
        cargando={cargando}
      />
      <Typography variant="h6" gutterBottom>
        Datos personales
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} className="oculto">
          <TextField
            ref={fileInput}
            required
            type="file"
            id="adjunto_identificacion"
            name="adjunto_identificacion"
            fullWidth
            autoComplete="billing adders-line1"
            onChange={(e) =>
              propsDatos.setAdjuntoIdentificacion(e.target.value)
            }
          />
          <TextField
            ref={fileInput}
            required
            type="file"
            id="adjunto_hoja_vida"
            name="adjunto_hoja_vida"
            fullWidth
            autoComplete="billing adders-line1"
            onChange={(e) => propsDatos.setAdjuntoHojaVida(e.target.value)}
          />
          <TextField
            ref={fileInput}
            required
            type="file"
            id="adjunto_diploma_profesional"
            name="adjunto_diploma_profesional"
            fullWidth
            autoComplete="billing adders-line1"
            onChange={(e) =>
              propsDatos.setAdjuntoDiplomaProfesional(e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl
            className="form-control"
            error={Boolean(propsDatos.error.tipoIdentificacion)}
          >
            <InputLabel>Seleccione tipo de identificación *</InputLabel>
            <Select
              value={propsDatos.tipoIdentificacion}
              id="select-tipo-identificacicon"
              name="tiposIdentificacion"
              onChange={(e) => propsDatos.setTipoIdentificacion(e.target.value)}
              required
            >
              {propsDatos.tiposIdentificacion.map((item, index) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {propsDatos.error.tipoIdentificacion}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={propsDatos.identificacion}
            required
            type="number"
            id="identificacion"
            name="identificacion"
            label="Número de identificación"
            InputProps={{ inputProps: { min: 0, maxLength: 10 } }}
            fullWidth
            autoComplete="id"
            onChange={(e) => propsDatos.setIdentificacion(e.target.value)}
            error={Boolean(propsDatos.error.identificacion)}
            helperText={propsDatos.error.identificacion}
            onBlur={validarIdentificacion}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={propsDatos.libretaMilitar}
            type="number"
            id="libreta_militar"
            name="libretaMilitar"
            label="Número de libreta militar"
            InputProps={{ inputProps: { min: 0, maxLength: 10 } }}
            fullWidth
            autoComplete="notebook"
            onChange={(e) => propsDatos.setLibretaMilitar(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={propsDatos.primerNombre}
            required
            type="text"
            id="primer_nombre"
            name="primerNombre"
            label="Primer nombre"
            fullWidth
            autoComplete="fname"
            onChange={(e) => propsDatos.setPrimerNombre(e.target.value)}
            error={Boolean(propsDatos.error.primerNombre)}
            helperText={propsDatos.error.primerNombre}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={propsDatos.segundoNombre}
            type="text"
            id="segundo_nombre"
            name="segundoNombre"
            label="Segundo nombre"
            fullWidth
            autoComplete="sname"
            onChange={(e) => propsDatos.setSegundoNombre(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={propsDatos.primerApellido}
            required
            type="text"
            id="primer_apellido"
            name="primerApellido"
            label="Primer apellido"
            fullWidth
            autoComplete="lname"
            onChange={(e) => propsDatos.setPrimerApellido(e.target.value)}
            error={Boolean(propsDatos.error.primerApellido)}
            helperText={propsDatos.error.primerApellido}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={propsDatos.segundoApellido}
            required
            type="text"
            id="segundo_apellido"
            name="segundoApellido"
            label="Segundo apellido"
            fullWidth
            autoComplete="lname"
            onChange={(e) => propsDatos.setSegundoApellido(e.target.value)}
            error={Boolean(propsDatos.error.segundoApellido)}
            helperText={propsDatos.error.segundoApellido}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={propsDatos.telefono}
            required
            type="number"
            id="telefono"
            name="telefono"
            label="Número de teléfono"
            InputProps={{ inputProps: { min: 0, maxLength: 10 } }}
            fullWidth
            autoComplete="cellphone"
            onChange={(e) => propsDatos.setTelefono(e.target.value)}
            error={Boolean(propsDatos.error.telefono)}
            helperText={propsDatos.error.telefono}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={propsDatos.celular}
            required
            type="number"
            id="celular"
            name="celular"
            label="Número de celular"
            InputProps={{ inputProps: { min: 0, maxLength: 10 } }}
            fullWidth
            autoComplete="phone"
            inputProps={{
              maxLength: 10,
            }}
            onChange={(e) => propsDatos.setCelular(e.target.value)}
            error={Boolean(propsDatos.error.celular)}
            helperText={propsDatos.error.celular}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={propsDatos.correo}
            required
            type="email"
            id="correo"
            name="correo"
            label="Correo electrónico personal"
            fullWidth
            onChange={(e) => propsDatos.setCorreo(e.target.value)}
            error={Boolean(propsDatos.error.correo)}
            helperText={propsDatos.error.correo}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    TransitionComponent={Zoom}
                    title="Por favor verifica este correo, ya que es muy importante durante el proceso de inscripción."
                    arrow
                  >
                    <IconButton style={{ color: coloresEmma.secondarycolor}}>
                      <InfoRounded />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={propsDatos.checkDiscapacidad}
                onChange={(e) =>
                  propsDatos.setCheckDiscapacidad(e.target.checked)
                }
                id="checkDiscapacidad"
                name="checkDiscapacidad"
                color="primary"
              />
            }
            label="¿Tienes alguna discapacidad?"
          />
        </Grid>
        {propsDatos.checkDiscapacidad && (
          <Grid item xs={12}>
            <FormControl
              className="form-control"
              error={Boolean(propsDatos.error.discapacidad)}
            >
              <InputLabel>Seleccione discapacidad *</InputLabel>
              <Select
                value={propsDatos.discapacidad}
                id="select-tipo-discapacidad"
                name="tipoDiscapacidad"
                onChange={(e) => propsDatos.setDiscapacidad(e.target.value)}
                placeholder="Seleccione el tipo de discapacidad"
                required
              >
                {propsDatos.discapacidades.map((item, index) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{propsDatos.error.discapacidad}</FormHelperText>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl
            className="form-control"
            error={Boolean(propsDatos.error.genero)}
          >
            <InputLabel>Genero *</InputLabel>
            <Select
              value={propsDatos.genero}
              id="select-genero"
              name="género"
              onChange={(e) => propsDatos.setGenero(e.target.value)}
              placeholder="Seleccione genero"
              required
            >
              {propsDatos.generos.map((item, index) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{propsDatos.error.genero}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            className="form-control"
            error={Boolean(propsDatos.error.pais)}
          >
            <InputLabel>País de nacimiento *</InputLabel>
            <Select
              value={propsDatos.pais}
              id="select-pais_nacimiento"
              name="paisNacimiento"
              onChange={(e) => propsDatos.setPais(e.target.value)}
              placeholder="Seleccione país de nacimiento"
              required
            >
              {propsDatos.paises.map((item, index) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{propsDatos.error.pais}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={propsDatos.ciudadNacimiento}
            required
            type="text"
            id="ciudadNacimiento"
            name="ciudadNacimiento"
            label="Ciudad de nacimiento"
            fullWidth
            autoComplete="ciudadNacimiento"
            onChange={(e) => propsDatos.setCiudadNacimiento(e.target.value)}
            error={Boolean(propsDatos.error.ciudadNacimiento)}
            helperText={propsDatos.error.ciudadNacimiento}
          />
        </Grid>

        <Grid item xs={12}>
          <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
            <KeyboardDatePicker
              clearable
              required
              style={{ marginTop: 3, width: "100%" }}
              id="fechaNacimiento"
              margin="normal"
              value={propsDatos.fechaNacimiento}
              label="Fecha de nacimiento"
              onChange={(e) => propsDatos.setFechaNacimiento(e)}
              maxDate={new Date()}
              format="yyyy-MM-dd"
              KeyboardButtonProps={{ "aria-label": "change date" }}
              fullWidth
              error={Boolean(propsDatos.error.fechaNacimiento)}
              helperText={propsDatos.error.fechaNacimiento}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <InputFile
            id="adjunto_identificacion"
            value={propsDatos.adjuntoIdentificacion}
            label="Adjuntar documento de identificación"
            error={Boolean(propsDatos.error.adjuntoIdentificacion)}
            // helperText={propsDatos.error.adjuntoIdentificacion}
            required={
              propsDatos.validacionDocumento[4].valora === "1" ? true : false
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputFile
            id="adjunto_diploma_profesional"
            value={propsDatos.adjuntoDiplomaProfesional}
            label="Adjuntar diploma profesional"
            error={Boolean(propsDatos.error.adjuntoDiplomaProfesional)}
            // helperText={propsDatos.error.adjuntoDiplomaProfesional}
            required={
              propsDatos.validacionDocumento[0].valora === "1" ? true : false
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputFile
            id="adjunto_hoja_vida"
            value={propsDatos.adjuntoHojaVida}
            label="Adjuntar hoja de vida"
            error={Boolean(propsDatos.error.adjuntoHojaVida)}
            // helperText={propsDatos.error.adjuntoHojaVida}
            required={
              propsDatos.validacionDocumento[3].valora === "1" ? true : false
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default InscripcionPosgradoDatos;
